import { Box, Grid, TextInput } from "@mantine/core";
import { repositoryMdm } from "../../../_base/_const/_constVar";
import { MessageResponse } from "../../../model/MessageResponse";
import { useEffect, useState } from "react";
import { TblWarrantyServiceRequestForm } from "../../../model/TblWarrantyServiceRequestForm";
import { useForm } from "@mantine/form";

const DetailDataView = ({ id, onClose }: { id: any; onClose: any }) => {
  const entity = {
    id: 0,
    custId: null,
    deliveryId: 0,
    branchId: null,
    inventoryId: null,
    requestStatus: null,
    deliveryDept: null,
    deliveryDate: null,
    custMeeting: null,
    meetingDate: null,
    custPhone: null,
    provinceId: null,
    districtId: null,
    communeId: null,
    streetId: null,
    additionalAddress: null,
    meetingAddress: null,
    processingMethod: null,
    processingMethodName: null,
    employeeIncharge: null,
    requestNum: null,
    note: null,
    vendorId: null,
    message: null,
    address: null,
    priorityId: null,
    warrantyStatus: null,
    locationId: null,
    channel: null,
    channelName: null,
    employeeSupport: null,
    content: null,
    noteAssignRequest: null,
    receiveBranch: null,
    assignDate: null,
    empAssign: null,
    tblWarrantyServiceRequestFormDetailCommands: [],
  };

  const form = useForm<TblWarrantyServiceRequestForm>({
    mode: "uncontrolled",
    validateInputOnChange: true,
    initialValues: {
      ...entity,
    },
  });

  const getDetailRequestForm = async () => {
    const res = await repositoryMdm.get<MessageResponse<any>>(
      `/api/v1/TblWarrantyServiceRequestForm/detail?id=${id}`
    );

    if (res && res?.success) {
      const dataApi = res.data;
      form.setValues(dataApi);
    }
  };

  useEffect(() => {
    if (id) {
      getDetailRequestForm();
    }
  }, [id]);

  return (
    <Box w={"82.5vw"} maw={1350}>
      <Grid mt={10}>
        <Grid.Col span={{ base: 12, md: 6, lg: 3 }}>
          <TextInput
            label="Số phiếu yêu cầu"
            value={form.getValues().requestNum ?? ""}
            variant="filled"
            readOnly
          />
        </Grid.Col>
      </Grid>
    </Box>
  );
};

export default DetailDataView;
