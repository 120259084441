import React from "react";
import { Select, Text, TextInput } from "@mantine/core";
import RenderTable from "./RenderTable";
import { formatDateTransfer } from "../../../../common/FormatDate/FormatDate";
import { tblInventoryTransaction } from "../../../../model/TblOutWard"; // Assuming RenderTable is in the same directory

const RequestVendorReturnOutWard = ({
  dataSearchSourceCodeOption,
  dataOutWard,
  sourceCode,
  setSourceCode,
  handleKeyDownSourceCode,
  dataOrderDetail,
  handleChangeValueInput,
  userInfo,
  isSave,
  searchSourceCode,
  setSearchSourceCode,
  isEdit,
  isLocked,
}: RequestVendorReturnOutWardProps) => {
  const headers = [
    { label: "", style: { width: "25%" } },
    { label: "Bên xuất", style: { width: "25%" } },
    { label: "", style: { width: "25%" } },
    { label: "Bên nhận", style: { width: "25%" } },
  ];

  const rows = [
    [
      {
        content: (
          <Text fw={700} size="sm">
            Thời gian tạo
          </Text>
        ),
      },
      {
        content: (
          <Text size="sm">
            {formatDateTransfer(dataOutWard.createDate ?? "")}
          </Text>
        ),
      },
      {
        content: (
          <Text fw={700} size="sm">
            Tên NCC
          </Text>
        ),
      },
      { content: <Text size="sm">{dataOrderDetail?.cusName}</Text> },
    ],
    [
      {
        content: (
          <Text fw={700} size="sm">
            Thời gian hoàn thành
          </Text>
        ),
      },
      {
        content: (
          <Text size="sm">{formatDateTransfer(dataOutWard?.finishDate)}</Text>
        ),
      },
      {
        content: (
          <Text fw={700} size="sm">
            Mã NCC
          </Text>
        ),
      },
      { content: <Text size="sm"> {dataOrderDetail?.cusCode}</Text> },
    ],
    [
      {
        content: (
          <Text fw={700} size="sm">
            Mã phiếu xuất kho
          </Text>
        ),
      },
      { content: <Text size="sm">{dataOutWard?.transactionCode}</Text> },
      {
        content: (
          <Text fw={700} size="sm">
            Tỉnh/Thành
          </Text>
        ),
      },
      { content: <Text size="sm">{dataOrderDetail?.cusProvinceName}</Text> },
    ],
    [
      {
        content: (
          <Text fw={700} size="sm">
            Mã phiếu yêu cầu xuất kho
          </Text>
        ),
      },
      {
        content: !isEdit ? (
          <Select
            placeholder="Mã phiếu yêu cầu xuất kho"
            data={dataSearchSourceCodeOption}
            size="sm"
            disabled={
              !dataOutWard.sourceType ||
              dataOutWard.status === "LOCK" ||
              sourceCode ||
              isSave
            }
            searchable
            clearable
            onSearchChange={(e) => {
              if (setSearchSourceCode) {
                setSearchSourceCode(e);
              }
            }}
            onKeyDown={handleKeyDownSourceCode}
            value={sourceCode || dataOutWard.sourceCode}
            onChange={(value) => {
              if (handleChangeValueInput) {
                handleChangeValueInput(value || "", "sourceCode");
              }
              if (setSourceCode) {
                setSourceCode(value);
              }
            }}
            nothingFoundMessage={
              searchSourceCode && searchSourceCode?.length > 3
                ? "Không tìm thấy dữ liệu !"
                : "Vui lòng nhập tối thiểu 4 kí tự !"
            }
          />
        ) : (
          <Text size="sm">{dataOutWard.sourceCode} </Text>
        ),
      },
      {
        content: (
          <Text fw={700} size="sm">
            Quận/Huyện
          </Text>
        ),
      },
      { content: <Text size="sm">{dataOrderDetail?.cusDestrictName}</Text> },
    ],

    [
      {
        content: (
          <Text fw={700} size="sm">
            Chi nhánh
          </Text>
        ),
      },
      { content: <Text size="sm">{dataOrderDetail?.fromBranchName}</Text> },
      {
        content: (
          <Text fw={700} size="sm">
            Phường/Xã
          </Text>
        ),
      },
      { content: <Text size="sm">{dataOrderDetail?.cusCommuneName}</Text> },
    ],
    [
      {
        content: (
          <Text fw={700} size="sm">
            Địa điểm làm việc
          </Text>
        ),
      },
      { content: <Text size="sm">{dataOrderDetail?.fromBranchAddress}</Text> },
      {
        content: (
          <Text fw={700} size="sm">
            Địa chỉ cụ thể
          </Text>
        ),
      },
      { content: <Text size="sm">{dataOrderDetail?.cusAddress}</Text> },
    ],
    [
      {
        content: (
          <Text fw={700} size="sm">
            Kho
          </Text>
        ),
      },
      { content: <Text size="sm">{dataOrderDetail?.fromInvName}</Text> },
      {
        content: (
          <Text fw={700} size="sm">
            Người nhận
          </Text>
        ),
      },
      { content: <Text size="sm">{dataOrderDetail?.toEmpName}</Text> },
    ],

    [
      {
        content: (
          <Text fw={700} size="sm">
            Họ & tên
          </Text>
        ),
      },
      { content: <Text size="sm">{userInfo?.fullName || ""}</Text> },
      {
        content: (
          <Text fw={700} size="sm">
            Số điện thoại
          </Text>
        ),
      },
      { content: <Text size="sm">{dataOrderDetail?.cusTelephone}</Text> },
    ],
    [
      {
        content: (
          <Text fw={700} size="sm">
            Chức danh
          </Text>
        ),
      },
      { content: <Text size="sm">{userInfo?.roleName || ""}</Text> },
      { content: null },
      { content: null },
    ],
    [
      {
        content: (
          <Text fw={700} size="sm">
            Giao hàng
          </Text>
        ),
      },
      { content: <Text size="sm">{dataOrderDetail?.delivery || ""}</Text> },
      { content: null },
      { content: null },
    ],
    [
      {
        content: (
          <Text fw={700} size="sm">
            Ghi chú
          </Text>
        ),
      },
      {
        content: (
          <TextInput
            value={dataOutWard?.description ?? ""}
            onChange={(e) => {
              handleChangeValueInput(e.target.value, "description");
            }}
            disabled={isLocked}
            placeholder={isLocked ? "" : "Vui lòng nhập"}
          />
        ),
        colSpan: 3,
      },
    ],
  ];

  return (
    <RenderTable
      headers={headers}
      rows={rows.filter((item) => item.length !== 0)}
    />
  );
};

type RequestVendorReturnOutWardProps = {
  dataSearchSourceCodeOption?: any[];
  dataOutWard: tblInventoryTransaction;
  dataOrderDetail: any;
  userInfo: any;
  handleChangeValueInput: (value: string | boolean | null, key: string) => void;
  setSourceCode?: (value: any) => void;
  handleKeyDownSourceCode?: (e: any) => void;
  sourceCode?: any;
  isSave?: boolean;
  searchSourceCode?: string;
  setSearchSourceCode?: (newValue: React.SetStateAction<string>) => void;
  isEdit?: boolean;
  isLocked?: boolean;
};

export default RequestVendorReturnOutWard;
