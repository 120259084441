import {
  ActionIcon,
  Box,
  Button,
  Checkbox,
  Combobox,
  Flex,
  Group,
  Input,
  Menu,
  NumberFormatter,
  ScrollArea,
  Select,
  Table,
  TextInput,
  Textarea,
  Tooltip,
  rem,
  useCombobox,
} from "@mantine/core";
import { DateTimePicker } from "@mantine/dates";
import { useHotkeys, useTimeout } from "@mantine/hooks";
import { modals } from "@mantine/modals";
import {
  IconArrowLeft,
  IconCaretDown,
  IconDeviceFloppy,
  IconEdit,
  IconFileExport,
  IconFileUpload,
  IconPlus,
  IconSelect,
  IconTrash,
} from "@tabler/icons-react";
import {
  MRT_ColumnDef,
  MRT_ColumnFiltersState,
  MRT_Row,
  MRT_RowSelectionState,
  MRT_SortingState,
  MRT_TableOptions,
  MantineReactTable,
  useMantineReactTable,
} from "mantine-react-table";
import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import * as xlsx from "xlsx";
import {
  isNullOrUndefined,
  isNullOrUndefinedArry,
} from "../../../_base/extension/StringExtension";
import {
  createPriceList,
  editPriceList,
  getDetailPriceList,
} from "../../../api/apiPriceList";
import { _localization } from "../../../config/location";
import { tblItem } from "../../../model/TblItem";
import {
  tblPriceList,
  tblPriceListCommand,
  tblPriceListCommandList,
} from "../../../model/TblPriceList";
import ModelAddProduct from "./ModelAddProduct";
import FormatInputValueNumber from "../../../common/formatInputValueNumber";
import dayjs from "dayjs";
import _breadcrumb from "../../../_base/component/_layout/_breadcrumb";
import { Panel, PanelGroup, PanelResizeHandle } from "react-resizable-panels";
import { useForm } from "@mantine/form";
import { NotificationExtension } from "../../../_base/extension/NotificationExtension";
import { sky_blue } from "../../../const/variables";
import { paginationBase } from "../../../_base/model/_base/BaseTable";
import { repositoryPos } from "../../../_base/_const/_constVar";
import { MessageResponse } from "../../../model/MessageResponse";

const ListDetailsPrice = () => {
  const headerRef = React.useRef<HTMLDivElement>(null);
  const [height, setHeight] = useState(0);

  const columns = React.useMemo<MRT_ColumnDef<any>[]>(
    () => [
      // {
      //   accessorKey: "itemId",
      //   header: "Id sản phẩm",
      //   size: 30,
      // },
      {
        accessorKey: "itemCode",
        header: "Mã sản phẩm",
        size: 30,
        Edit: ({ cell, column, table, row }) => (
          <Group>
            <Button
              leftSection={<IconSelect size={14} />}
              onClick={() => {
                console.log(row);
                handleAddProduct(
                  true,
                  row.original.itemCode,
                  table.options.data
                );
              }}
              variant="default"
            >
              Chọn
            </Button>
          </Group>
        ),
      },
      {
        accessorKey: "itemName",
        header: "Tên sản phẩm",
        size: 30,
        enableEditing: false,
      },
      {
        accessorKey: "listPrice",
        header: "Giá bán lẻ",
        size: 30,
        Edit: ({ row, column, table }) => {
          return (
            <FormatInputValueNumber
              value={row.original.listPrice}
              placeholder="Nhập..."
              onChange={(e) => {
                handleChangeDataItem(
                  row.original.itemCode,
                  e,
                  column.id,
                  row.original.type
                );
                table.setEditingRow(row);
              }}
              vnd
            />
          );
        },

        Cell: ({ renderedCellValue, row }) => (
          <>
            <NumberFormatter
              value={row.original.listPrice || 0}
              thousandSeparator=","
              suffix=" ₫"
            />
          </>
        ),
      },
      {
        accessorKey: "webPrice",
        header: "Giá niêm yết", // Giá niên yết (Giá thị trường trên file import)
        size: 30,
        Edit: ({ row, column, table }) => {
          return (
            <FormatInputValueNumber
              value={row.original.webPrice}
              placeholder="Nhập..."
              onChange={(e) => {
                handleChangeDataItem(
                  row.original.itemCode,
                  e,
                  column.id,
                  row.original.type
                );
                table.setEditingRow(row);
              }}
              vnd
            />
          );
        },
        Cell: ({ renderedCellValue, row }) => (
          <>
            <NumberFormatter
              value={row.original.webPrice || 0}
              thousandSeparator=","
              suffix=" ₫"
            />
          </>
        ),
      },
      // {
      //   accessorKey: "priceNoVat",
      //   header: "Giá Chưa VAT",
      //   Edit: ({ row, column, table }) => {
      //     return (
      //       <FormatInputValueNumber
      //         value={row._valuesCache[column.id]}
      //         placeholder="Nhập..."
      //         onChange={(e) => {
      //            handleChangeDataItem(row.original.itemCode, e, column.id, row.original.type);
      //           table.setEditingRow(row);
      //         }}
      //         vnd
      //       />
      //     );
      //   },

      //   Cell: ({ renderedCellValue, row }) => (
      //     <>
      //       <NumberFormatter
      //         value={row.original.priceNoVat || 0}
      //         thousandSeparator=","
      //         suffix=" ₫"
      //       />
      //     </>
      //   ),
      // },
      // {
      //   accessorKey: "priceIncludeVat",
      //   header: "Giá Bao Gồm VAT",
      //   size: 30,
      //   Edit: ({ row, column, table }) => {
      //     return (
      //       <FormatInputValueNumber
      //         value={row._valuesCache[column.id]}
      //         placeholder="Nhập..."
      //         onChange={(e) => {
      //            handleChangeDataItem(row.original.itemCode, e, column.id, row.original.type);
      //           table.setEditingRow(row);
      //         }}
      //         vnd
      //       />
      //     );
      //   },
      //   Cell: ({ renderedCellValue, row }) => (
      //     <>
      //       <NumberFormatter
      //         value={row.original.priceIncludeVat || 0}
      //         thousandSeparator=","
      //         suffix=" ₫"
      //       />
      //     </>
      //   ),
      // },
      {
        accessorKey: "wholesalePrice1",
        header: "Giá Bán Buôn 1",
        size: 30,
        Edit: ({ row, column, table }) => {
          return (
            <FormatInputValueNumber
              value={row.original.wholesalePrice1}
              placeholder="Nhập..."
              onChange={(e) => {
                handleChangeDataItem(
                  row.original.itemCode,
                  e,
                  column.id,
                  row.original.type
                );
                table.setEditingRow(row);
              }}
              vnd
            />
          );
        },

        Cell: ({ renderedCellValue, row }) => (
          <>
            <NumberFormatter
              value={row.original.wholesalePrice1 || 0}
              thousandSeparator=","
              suffix=" ₫"
            />
          </>
        ),
      },
      {
        accessorKey: "wholesalePrice2",
        header: "Giá Bán Buôn 2",
        size: 30,
        Edit: ({ row, column, table }) => {
          return (
            <FormatInputValueNumber
              value={row.original.wholesalePrice2}
              placeholder="Nhập..."
              onChange={(e) => {
                handleChangeDataItem(
                  row.original.itemCode,
                  e,
                  column.id,
                  row.original.type
                );
                table.setEditingRow(row);
              }}
              onBlur={() => table.setEditingRow(null)}
              vnd
            />
          );
        },

        Cell: ({ renderedCellValue, row }) => (
          <>
            <NumberFormatter
              value={row.original.wholesalePrice2 || 0}
              thousandSeparator=","
              suffix=" ₫"
            />
          </>
        ),
      },
      {
        accessorKey: "wholesalePrice3",
        header: "Giá Bán Buôn 3",
        size: 30,
        Edit: ({ row, column, table }) => {
          return (
            <FormatInputValueNumber
              value={row.original.wholesalePrice3}
              placeholder="Nhập..."
              onChange={(e) => {
                handleChangeDataItem(
                  row.original.itemCode,
                  e,
                  column.id,
                  row.original?.type
                );
                table.setEditingRow(row);
              }}
              vnd
            />
          );
        },

        Cell: ({ renderedCellValue, row }) => (
          <>
            <NumberFormatter
              value={row.original.wholesalePrice3 || 0}
              thousandSeparator=","
              suffix=" ₫"
            />
          </>
        ),
      },
      // {
      //   accessorKey: "discountPercent",
      //   header: "Phần Trăm Giảm Giá",
      //   size: 30,
      //   Cell: ({ renderedCellValue }) => (
      //     <Text style={{ color: sky_blue.light_alt }} fw={"bold"}>
      //       {renderedCellValue?.toString()}%
      //     </Text>
      //   ),
      // },
      // {
      //   accessorKey: "discountAmount",
      //   header: "Số Tiền Giảm Giá",
      //   size: 30,
      //   Edit: ({ row, column, table }) => {
      //     return (
      //       <FormatInputValueNumber
      //         value={row._valuesCache[column.id]}
      //         placeholder="Nhập..."
      //         onChange={(e) => {
      //            handleChangeDataItem(row.original.itemCode, e, column.id, row.original.type);
      //           table.setEditingRow(row);
      //         }}
      //         vnd
      //       />
      //     );
      //   },

      //   Cell: ({ renderedCellValue, row }) => (
      //     <>
      //       <NumberFormatter
      //         value={row.original.discountAmount || 0}
      //         thousandSeparator=","
      //         suffix=" ₫"
      //       />
      //     </>
      //   ),
      // },
      // {
      //   accessorKey: "vatRate",
      //   header: "Tỷ Lệ VAT",
      //   size: 30,
      //   Cell: ({ renderedCellValue }) => (
      //     <Text fw={"bold"} style={{ color: sky_blue.light_alt }}>
      //       {renderedCellValue?.toString()}%
      //     </Text>
      //   ),
      // },
      // {
      //   accessorKey: "vatAmount",
      //   header: "Số Tiền VAT",
      //   size: 30,
      //   Edit: ({ row, column, table }) => {
      //     return (
      //       <FormatInputValueNumber
      //         value={row._valuesCache[column.id]}
      //         placeholder="Nhập..."
      //         onChange={(e) => {
      //            handleChangeDataItem(row.original.itemCode, e, column.id, row.original.type);
      //           table.setEditingRow(row);
      //         }}
      //         vnd
      //       />
      //     );
      //   },

      //   Cell: ({ renderedCellValue, row }) => (
      //     <>
      //       <NumberFormatter
      //         value={row.original.vatAmount || 0}
      //         thousandSeparator=","
      //         suffix=" ₫"
      //       />
      //     </>
      //   ),
      // },

      // {
      //   accessorKey: "bonusRate",
      //   header: "% Chiết khấu",
      //   size: 30,
      //   Cell: ({ renderedCellValue }) => (
      //     <Text fw={"bold"} style={{ color: sky_blue.light_alt }}>
      //       {renderedCellValue?.toString()}%
      //     </Text>
      //   ),
      // },
      // {
      //   accessorKey: "bonus",
      //   header: "Chiết khấu",
      //   size: 30,
      //   Edit: ({ row, column, table }) => {
      //     return (
      //       <FormatInputValueNumber
      //         value={row._valuesCache[column.id]}
      //         placeholder="Nhập..."
      //         onChange={(e) => {
      //            handleChangeDataItem(row.original.itemCode, e, column.id, row.original.type);
      //           table.setEditingRow(row);
      //         }}
      //         vnd
      //       />
      //     );
      //   },
      //   Cell: ({ renderedCellValue, row }) => (
      //     <>
      //       <NumberFormatter
      //         value={row.original.bonus || 0}
      //         thousandSeparator=","
      //         suffix=" ₫"
      //       />
      //     </>
      //   ),
      // },
      // {
      //   accessorKey: "lastPoPrice",
      //   header: "Giá PO Cuối",
      //   size: 30,
      //   Edit: ({ row, column, table }) => {
      //     return (
      //       <FormatInputValueNumber
      //         value={row._valuesCache[column.id]}
      //         placeholder="Nhập..."
      //         onChange={(e) => {
      //            handleChangeDataItem(row.original.itemCode, e, column.id, row.original.type);
      //           table.setEditingRow(row);
      //         }}
      //         vnd
      //       />
      //     );
      //   },

      //   Cell: ({ renderedCellValue, row }) => (
      //     <>
      //       <NumberFormatter
      //         value={row.original.lastPoPrice || 0}
      //         thousandSeparator=","
      //         suffix=" ₫"
      //       />
      //     </>
      //   ),
      // },
      // {
      //   accessorKey: "onHandAvgPrice",
      //   header: "Giá Bình Quân Tồn Kho",
      //   Edit: ({ row, column, table }) => {
      //     return (
      //       <FormatInputValueNumber
      //         value={row._valuesCache[column.id]}
      //         placeholder="Nhập..."
      //         onChange={(e) => {
      //            handleChangeDataItem(row.original.itemCode, e, column.id, row.original.type);
      //           table.setEditingRow(row);
      //         }}
      //         vnd
      //       />
      //     );
      //   },

      //   Cell: ({ renderedCellValue, row }) => (
      //     <>
      //       <NumberFormatter
      //         value={row.original.onHandAvgPrice || 0}
      //         thousandSeparator=","
      //         suffix=" ₫"
      //       />
      //     </>
      //   ),
      // },
      // {
      //   accessorKey: "priceMin",
      //   header: "Giá Thấp Nhất",
      //   size: 30,
      //   Edit: ({ row, column, table }) => {
      //     return (
      //       <FormatInputValueNumber
      //         value={row._valuesCache[column.id]}
      //         placeholder="Nhập..."
      //         onChange={(e) => {
      //            handleChangeDataItem(row.original.itemCode, e, column.id, row.original.type);
      //           table.setEditingRow(row);
      //         }}
      //         vnd
      //       />
      //     );
      //   },

      //   Cell: ({ renderedCellValue, row }) => (
      //     <>
      //       <NumberFormatter
      //         value={row.original.priceMin || 0}
      //         thousandSeparator=","
      //         suffix=" ₫"
      //       />
      //     </>
      //   ),
      // },
      // {
      //   accessorKey: "priceMax",
      //   header: "Giá Cao Nhất",
      //   size: 30,
      //   Edit: ({ row, column, table }) => {
      //     return (
      //       <FormatInputValueNumber
      //         value={row._valuesCache[column.id]}
      //         placeholder="Nhập..."
      //         onChange={(e) => {
      //            handleChangeDataItem(row.original.itemCode, e, column.id, row.original.type);
      //           table.setEditingRow(row);
      //         }}
      //         vnd
      //       />
      //     );
      //   },

      //   Cell: ({ renderedCellValue, row }) => (
      //     <>
      //       <NumberFormatter
      //         value={row.original.priceMax || 0}
      //         thousandSeparator=","
      //         suffix=" ₫"
      //       />
      //     </>
      //   ),
      // },
      // {
      //   accessorKey: "action",
      //   header: "Thao tác",
      //   size: 50,
      //   Cell: ({ renderedCellValue, row, table }) => (
      //     <Flex gap="md">
      //       <Tooltip label="Thao tác">
      //         <ActionIcon
      //           variant="outline"
      //           onClick={() => table.setEditingRow(row)}
      //         >
      //           <IconEdit />
      //         </ActionIcon>
      //       </Tooltip>
      //       <Tooltip label="Delete">
      //         <ActionIcon variant="outline" color="red">
      //           <IconTrash onClick={() => handleRowDelete(row)} />
      //         </ActionIcon>
      //       </Tooltip>
      //     </Flex>
      //   ),
      // },
      // {
      //   accessorKey: "approveStatus",
      //   header: "Trạng Thái Phê Duyệt",
      //   Cell: ({ renderedCellValue, row }) => (
      //     <Badge size="sm" color="cyan">
      //       Approved
      //     </Badge>
      //   ),
      // },
      // {
      //   accessorKey: "approveBy",
      //   header: "Phê Duyệt Bởi",
      //   size: 30,
      // },
      // {
      //   accessorKey: "action",
      //   header: "Thao tác",
      //   size: 50,
      //   Cell: ({ row }) => (
      //     <Box
      //       style={{
      //         display: "flex",
      //         alignItems: "center",
      //         gap: "4px",
      //       }}
      //     >
      //       {processTaskActionMenu(row.original)}
      //     </Box>
      //   ),
      // },
    ],
    []
  );

  const combobox = useCombobox();
  const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>(
    []
  );
  const [globalFilter, setGlobalFilter] = useState("");
  const [sorting, setSorting] = useState<MRT_SortingState>([]);
  const [rowCount, setRowCount] = useState(0);
  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});
  const [pagination, setPagination] = useState(paginationBase);
  const [keySearch, setKeySearch] = useState<string>("");
  const [openedMenu, setOpenedMenu] = useState(true);
  const [validationErrors, setValidationErrors] = useState<
    Record<string, string | undefined>
  >({});
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const id = location.state && location.state.id;
  const isDetailProp = location.state && location.state.isDetailProp;
  const isEditProp = location.state && location.state.isEditProp;
  const [dataBranch, setDataBranch] = useState<any[]>([]);
  const [operationDay, setOperationDay] = useState<Date | null>(null);

  const entity: tblPriceListCommand = {
    id: 0,
    priceListNum: null,
    description: null,
    status: 0,
    isDrap: 0,
    listIdBranch: null,
    activeDate: null,
    inactiveDate: null,
    attribute1: null,
    attribute2: null,
    attribute3: null,
    attribute4: null,
    attribute5: null,
    attribute6: null,
    attribute7: null,
    attribute8: null,
    attribute9: null,
    attribute10: null,
    attribute11: null,
    attribute12: null,
    attribute13: null,
    attribute14: null,
    attribute15: null,
    approveBy: null,
    approveDate: null,
  };

  const [dataPriceList, setDataPriceList] = useState<tblPriceList>({
    tblPriceListCommand: entity,
    tblPriceListCommandList: [],
  });

  const [data, setData] = useState<tblPriceListCommandList[]>([]);
  const [dataAdd, setDataAdd] = useState<tblPriceListCommandList[]>([]);
  const [dataEdit, setDataEdit] = useState<tblPriceListCommandList[]>([]);
  const [dataDelete, setDataDelete] = useState<number[]>([]);

  const validateRequired = (value: string) =>
    value !== undefined && value !== null && value.length > 0;

  function validateUser(user: any) {
    return {
      firstName: !validateRequired(user.itemName)
        ? "First Name is Required"
        : "",
      lastName: !validateRequired(user.itemCode) ? "Last Name is Required" : "",
    };
  }

  const handleSaveUser: MRT_TableOptions<any>["onEditingRowSave"] = async ({
    values,
    table,
  }) => {
    const newValidationErrors = validateUser(values);
    if (Object.values(newValidationErrors).some((error) => error)) {
      setValidationErrors(newValidationErrors);
      return;
    }
    setValidationErrors({});
    const dataEdit = {
      ...dataPriceList,
      tblPriceListCommandList: dataPriceList?.tblPriceListCommandList?.map(
        (item: any) => {
          if (item.itemCode === values.itemCode) {
            return {
              ...item,
              ...values,
            };
          }
          return item;
        }
      ),
    };
    setDataPriceList(dataEdit);
    setData((prevData) =>
      prevData?.map((item) => {
        if (item.itemCode === values.itemCode) {
          setDataEdit((prevData) => {
            const dataPrev = prevData?.filter((item) => item?.type !== "ADD");
            const existingItemIndex = dataPrev.findIndex(
              (data) => data.itemId === values.itemId
            );

            if (existingItemIndex >= 0) {
              dataPrev[existingItemIndex] = {
                ...dataPrev[existingItemIndex],
                ...values,
              };
            } else {
              dataPrev.push({
                ...item,
                ...values,
              });
            }

            return dataPrev;
          });
          return {
            ...item,
            ...values,
          };
        }
        return item;
      })
    );
    table.setEditingRow(null);
  };

  const handleExport = () => {
    try {
      const worksheet = xlsx.utils.json_to_sheet(
        dataPriceList?.tblPriceListModelList || []
      );
      const workbook = xlsx.utils.book_new();
      xlsx.utils.book_append_sheet(workbook, worksheet, "Data");
      xlsx.writeFile(workbook, "danh-sach-san-pham-bang-gia-POS.xlsx");
      toast.success("Export excel thành công", { autoClose: 1500 });
    } catch (error) {
      toast.error("Export excel thất bại", { autoClose: 1500 });
    }
  };

  const handleSavePriceList = async () => {
    let dataNewPriceList = {
      tblPriceListAddCommandList: dataAdd,
      tblPriceListUpdateCommandList: dataEdit,
      idsDelete: dataDelete,
      tblPriceListCommand: {
        ...dataPriceList.tblPriceListCommand,
        activeDate:
          typeof dataPriceList.tblPriceListCommand?.activeDate === "number"
            ? new Date(
                dataPriceList.tblPriceListCommand?.activeDate
              ).toISOString()
            : dataPriceList.tblPriceListCommand?.activeDate,
        inactiveDate:
          typeof dataPriceList.tblPriceListCommand?.inactiveDate === "number"
            ? new Date(
                dataPriceList.tblPriceListCommand?.inactiveDate
              ).toISOString()
            : dataPriceList.tblPriceListCommand?.inactiveDate,
      },
    };

    try {
      const { priceListNum, activeDate, inactiveDate, listIdBranch, isDrap } =
        dataNewPriceList.tblPriceListCommand;

      if (!priceListNum) {
        NotificationExtension.Fails("Vui lòng nhập số giao dịch !");
        return;
      }

      if (!activeDate) {
        NotificationExtension.Fails("Vui lòng nhập ngày hoạt động !");
        return;
      }

      if (!inactiveDate) {
        NotificationExtension.Fails("Vui lòng nhập ngày dừng hoạt !");
        return;
      }

      if (!listIdBranch || listIdBranch.length === 0) {
        NotificationExtension.Fails("Vui lòng nhập trung tâm !");
        return;
      }

      if (isNullOrUndefined(isDrap)) {
        NotificationExtension.Fails("Vui lòng chọn trạng thái bảng giá !");
        return;
      }

      const response = id
        ? await repositoryPos.post(
            "/api/v1/TblPriceList/edit",
            dataNewPriceList
          )
        : await repositoryPos.post(
            "/api/v1/TblPriceList/create",
            dataNewPriceList
          );

      if (response && response.success) {
        NotificationExtension.Success("Lưu bảng giá thành công !");
        NotificationExtension.Success("Lưu bảng giá thành công !");
        setTimeout(() => navigate("/config-system/config-price"), 2000);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleBranchSelect = (val: string) => {
    setDataPriceList((prevData) => {
      let listIdBranch = prevData?.tblPriceListCommand?.listIdBranch;
      let branchArray = listIdBranch ? listIdBranch.split(",") : [];

      if (branchArray.includes(val)) {
        // Remove the value
        branchArray = branchArray.filter((branch) => branch !== val);
      } else {
        // Add the value
        branchArray.push(val);
      }

      const updatedListIdBranch = branchArray?.join(",");

      return {
        ...prevData,
        tblPriceListCommand: {
          ...prevData.tblPriceListCommand,
          listIdBranch: updatedListIdBranch,
        },
      };
    });
  };

  const handleCreateUser: MRT_TableOptions<any>["onCreatingRowSave"] = async ({
    values,
    exitCreatingMode,
  }) => {
    const newValidationErrors = validateUser(values);
    if (Object.values(newValidationErrors).some((error) => error)) {
      setValidationErrors(newValidationErrors);
      return;
    }
    setValidationErrors({});
    //   await createUser(values);
    exitCreatingMode();
  };

  const handleChangeDataPriceListCommand = (
    value: string | number | null,
    key: string
  ) => {
    setDataPriceList((prevData) => {
      return {
        ...prevData,
        tblPriceListCommand: {
          ...prevData?.tblPriceListCommand,
          [key]: value ? value : null,
        },
      };
    });
  };

  const handleChangeDataItem = (
    itemCode: string,
    newValue: number | string | null,
    key: string,
    type?: string
  ) => {
    setData((prevItems) =>
      prevItems.map((item) =>
        item.itemCode === itemCode
          ? { ...item, [key]: newValue ? newValue : null }
          : item
      )
    );
    if (type === "ADD") {
      setDataAdd((prevItems) =>
        prevItems.map((item) =>
          item.itemCode === itemCode
            ? { ...item, [key]: newValue ? newValue : null }
            : item
        )
      );
    }
  };

  const handleAddProductToData = (
    dataSelect: tblItem[],
    productId?: string
  ) => {
    if (productId) {
      if (dataSelect.length > 0) {
        const productToUpdate = dataSelect[0];

        const dataEdit = {
          ...dataPriceList,
          tblPriceListCommandList: dataPriceList?.tblPriceListCommandList?.map(
            (item: any) => {
              if (item.itemCode === productId) {
                return {
                  ...item,
                  itemCode: productToUpdate.itemCode,
                  itemName: productToUpdate.itemName,
                };
              }
              return item;
            }
          ),
        };

        setDataPriceList(dataEdit);
        setData((prevData) =>
          prevData?.map((item) => {
            if (item.itemCode === productId) {
              return {
                ...item,
                itemCode: productToUpdate.itemCode,
                itemName: productToUpdate.itemName,
              };
            }
            return item;
          })
        );
      }
    } else {
      const dataAddProduct = dataSelect?.map((product) => ({
        id: 0,
        idPriceLis: 0,
        itemId: product.itemId, // Set the correct itemId
        itemCode: product.itemCode,
        itemName: product.itemName,
        priceNoVat: 0,
        priceIncludeVat: 0,
        discountPercent: 0,
        discountAmount: 0,
        vatRate: 0,
        vatAmount: 0,
        wholesalePrice1: 0,
        wholesalePrice2: 0,
        wholesalePrice3: 0,
        listPrice: 0,
        webPrice: 0,
        demoPrice: 0,
        bonusRate: 0,
        bunus: 0, // Make sure this matches your interface spelling
        lastPoPrice: 0,
        onhandAvgPrice: 0, // Correct the spelling to match your interface
        priceMin: 0,
        priceMax: 0,
        approveStatus: null, // Ensure this matches the interface type
        approveBy: null, // Ensure this matches the interface type
        approveDate: null, // Ensure this matches the interface type
        attribute1: null,
        attribute2: null,
        attribute3: null,
        attribute4: null,
        attribute5: null,
        attribute6: null,
        attribute7: null,
        attribute8: null,
        attribute9: null,
        attribute10: null,
        attribute11: null,
        attribute12: null,
        attribute13: null,
        attribute14: null,
        attribute15: null,
        createBy: null,
        createDate: null,
        lastUpdateBy: null,
        lastUpdateDate: null,
        type: "ADD",
      }));

      setDataPriceList((prevData) => ({
        ...prevData,
        tblPriceListCommandList: [
          ...(prevData?.tblPriceListCommandList ?? []),
          ...dataAddProduct,
        ],
      }));
      setData((prevData) => [...dataAddProduct, ...prevData]);
      setDataAdd((prev) => [...prev, ...dataAddProduct]);
    }
    modals.closeAll();
  };

  const convertListId = (listId: string) => {
    const arrId = listId.split(",");
    const listBranch = arrId?.map(
      (item) =>
        dataBranch.find(
          (branch) => branch.value?.toString() === item?.toString()
        )?.text
    );
    return listBranch?.join(", ");
  };

  const handleRowDelete = (row: MRT_Row<any>) => {
    setData((prevData) => prevData.filter((_, index) => index !== row.index));
    setDataDelete((prev) => {
      if (row.original.type === "ADD") {
        setDataAdd((prevData) =>
          prevData.filter((item) => item.itemCode !== row.original.itemCode)
        );
        return [...prev];
      }
      return [...prev, row.original?.id];
    });

    setDataPriceList((prevData) => ({
      ...prevData,
      tblPriceListCommandList: (prevData?.tblPriceListCommandList ?? []).filter(
        (_, index) => index !== row.index
      ),
    }));
  };

  const handleAddProduct = (
    editTable: boolean,
    productId?: string,
    dataList?: any[]
  ) => {
    // modals.closeAll();
    modals.openConfirmModal({
      title: "Thêm sản phẩm",
      centered: true,
      size: "100%",
      fullScreen: false,
      children: (
        <ModelAddProduct
          productId={productId}
          editTable={editTable}
          handleAddProduct={handleAddProductToData}
          listIdItem={
            dataList
              ? dataList?.map((item) => item.itemId)
              : data?.map((item) => item.itemId)
          }
        ></ModelAddProduct>
      ),
      labels: { confirm: "Confirm", cancel: "Hủy bỏ" },
      confirmProps: { display: "none" },
      cancelProps: { display: "none" },
    });
  };

  useHotkeys([
    // ['mod+J', () => console.log('Toggle color scheme')],
    // ['ctrl+F5', () => { openNew() }],
    // ['F5', () => { openNew() }],
    // ['alt+mod+shift+X', () => console.log('Rick roll')],
    [
      "F11",
      () => {
        handleAddProduct(false);
      },
    ],
  ]);

  const fetchData = async () => {
    setIsLoading(true);
    setIsRefetching(true);
    setOpenedMenu(false);
    setDataPriceList({
      tblPriceListCommand: entity,
      tblPriceListCommandList: [],
    });
    setRowCount(0);

    let url = `&Skip=${pagination?.pageIndex * pagination?.pageSize}&Take=${
      pagination.pageSize
    }`;

    try {
      let callapi = await repositoryPos.get(
        `/api/v1/TblPriceList/detail?id=${id}${url}`
      );
      if (isNullOrUndefined(callapi) || isNullOrUndefinedArry(callapi?.data)) {
        setRowCount(0);
        setDataPriceList({
          tblPriceListCommand: entity,
          tblPriceListCommandList: [],
        });
      } else {
        setDataPriceList(
          callapi?.data
            ? {
                tblPriceListCommand: {
                  ...callapi?.data?.tblPriceListModel,
                  activeDate: callapi?.data?.tblPriceListModel?.activeDate
                    ? new Date(
                        callapi?.data?.tblPriceListModel?.activeDate
                      ).setHours(
                        new Date(
                          callapi?.data?.tblPriceListModel?.activeDate
                        ).getHours() + 7
                      )
                    : null,
                  inactiveDate: callapi?.data?.tblPriceListModel?.inactiveDate
                    ? new Date(
                        callapi?.data?.tblPriceListModel?.inactiveDate
                      ).setHours(
                        new Date(
                          callapi?.data?.tblPriceListModel?.inactiveDate
                        ).getHours() + 7
                      )
                    : null,
                },
                tblPriceListCommandList: callapi?.data?.tblPriceListModelList,
              }
            : {
                tblPriceListCommand: entity,
                tblPriceListCommandList: [],
              }
        );
        setData(callapi?.data?.tblPriceListModelList);
        // setPagination({
        //   ...pagination,
        //   totalItemCount: callapi?.dataPriceList.count ?? 0,
        // });
        setRowCount(callapi?.data?.tblPriceListModelList?.length ?? 0);
        table.resetRowSelection();
      }
      return callapi?.dataPriceList;
    } catch (error: any) {
      setIsError(true);
      console.error(error);
      return null;
    } finally {
      setIsLoading(false);
      setIsRefetching(false);
      setOpenedMenu(true);
    }
  };

  useEffect(() => {
    if (id) fetchData();
  }, [id, pagination]);

  useEffect(() => {
    const fetchDataBranch = async () => {
      const response = await repositoryPos.get<MessageResponse<any>>(
        "/api/v1/TblDmInventory/get-select-branch"
      );

      setDataBranch(response?.data);
    };

    fetchDataBranch();
  }, []);

  const table = useMantineReactTable({
    columns,
    data,
    positionToolbarAlertBanner: "bottom",
    createDisplayMode: "row", // ('modal', and 'custom' are also available)
    editDisplayMode: "row", // ('modal', 'cell', 'table', and 'custom' are also available)
    enableEditing: !isDetailProp,
    onCreatingRowCancel: () => setValidationErrors({}),
    onCreatingRowSave: () => handleCreateUser,
    onEditingRowCancel: () => setValidationErrors({}),
    onEditingRowSave: (props) => handleSaveUser(props),
    mantineTableBodyRowProps: ({ row, table }) => ({
      onDoubleClick: () => {
        table.setEditingRow(row);
      },
    }),
    renderTopToolbarCustomActions: ({ table }) => (
      <div ref={headerRef} style={{ width: "100%" }}>
        <Flex
          gap="md"
          justify={{ sm: "flex-start", lg: "flex-end" }}
          align="center"
          direction="row"
          wrap="wrap"
        >
          <Button
            leftSection={<IconPlus size={14} />}
            color="blue"
            variant="outline"
            onClick={() => handleAddProduct(false)}
            disabled={isDetailProp}
          >
            Thêm mới (F11)
          </Button>
          <Button
            leftSection={<IconArrowLeft size={14} />}
            color="red"
            variant="outline"
            onClick={() => navigate("/config-system/config-price")}
          >
            Quay lại
          </Button>
          <Button
            leftSection={<IconDeviceFloppy size={14} />}
            color="green"
            variant="outline"
            onClick={handleSavePriceList}
            disabled={isDetailProp}
          >
            Lưu bảng giá
          </Button>
          <Menu shadow="md" width={200}>
            <Menu.Target>
              <Button
                // size="xs"
                rightSection={
                  <IconCaretDown style={{ width: rem(14), height: rem(14) }} />
                }
              >
                Chức năng
              </Button>
            </Menu.Target>

            <Menu.Dropdown>
              <Menu.Label>Application</Menu.Label>
              <Menu.Item
                leftSection={
                  <IconFileExport style={{ width: rem(14), height: rem(14) }} />
                }
                onClick={handleExport}
              >
                Export Excel
              </Menu.Item>
              <Tooltip label={"Chức năng đang update"}>
                <Menu.Item
                  leftSection={
                    <IconFileUpload
                      style={{ width: rem(14), height: rem(14) }}
                    />
                  }
                >
                  Import Excel
                </Menu.Item>
              </Tooltip>
            </Menu.Dropdown>
          </Menu>
        </Flex>
      </div>
    ),
    renderToolbarInternalActions: ({ table }) => <></>,
    renderRowActions: ({ row }) =>
      !isDetailProp && (
        <Flex gap="md">
          <Tooltip label="Thao tác">
            <ActionIcon
              variant="outline"
              onClick={() => table.setEditingRow(row)}
            >
              <IconEdit />
            </ActionIcon>
          </Tooltip>
          <Tooltip label="Delete">
            <ActionIcon variant="outline" color="red">
              <IconTrash onClick={() => handleRowDelete(row)} />
            </ActionIcon>
          </Tooltip>
        </Flex>
      ),
    mantineTopToolbarProps: {
      style: {
        borderBottom: "3px solid rgba(128, 128, 128, 0.5)",
        marginBottom: 5,
      },
    },
    localization: _localization,
    enableRowSelection: true,
    getRowId: (row) => row.itemId?.toString(),
    initialState: {
      showColumnFilters: false,
      columnPinning: {
        left: ["mrt-row-select", "mrt-row-actions", "itemCode"],
        // right: ["action"],
      },
      columnVisibility: { id: false, "mrt-row-actions": !isDetailProp },
      density: "xs",
    },
    mantineTableContainerProps: {
      style: { maxHeight: height, minHeight: height },
    },
    enableStickyHeader: true,
    onRowSelectionChange: setRowSelection,
    manualFiltering: false,
    manualPagination: true,
    manualSorting: false,
    rowCount,
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    mantineTableBodyCellProps: ({ row }) => ({
      style: {
        //  fontWeight: row.getIsSelected() ? "bold" : "normal", //conditional styling
        // fontWeight: "bold", //conditional styling
        fontSize: "11.5px",
        padding: "4px 12px",
      },
    }),
    state: {
      columnFilters,
      globalFilter,
      isLoading,
      pagination,
      showAlertBanner: isError,
      showProgressBars: isRefetching,
      showSkeletons: isLoading, //loading for the first time with no dataPriceList
      sorting,
      rowSelection,
    },
    mantineToolbarAlertBannerProps: false
      ? { color: "red", children: "Lỗi tải dữ liệu !" }
      : undefined,
    mantinePaginationProps: {
      showRowsPerPage: true,
      withEdges: true,
      rowsPerPageOptions: ["20", "50", "100"],
    },
    paginationDisplayMode: "pages",
    enableColumnPinning: true,
    mantineTableProps: {
      striped: false,
    },
  });

  useEffect(() => {
    const headerHeight = headerRef.current?.offsetHeight || 0;
    const handleResize = () => {
      // 160 là chiều cao của phần phân trang
      // headerHeight là chiều cao của phần header
      setHeight(window.innerHeight - (165 + headerHeight));
    };

    handleResize(); // Set initial height
    window.addEventListener("resize", handleResize); // Update height on window resize

    return () => {
      window.removeEventListener("resize", handleResize); // Clean up event listener
    };
  }, []);

  return (
    <>
      <PanelGroup direction="horizontal" id="group">
        <Panel defaultSize={30} minSize={25} maxSize={75}>
          <Table striped highlightOnHover withTableBorder withColumnBorders>
            <Table.Thead>
              <Table.Tr>
                <Table.Th></Table.Th>
                <Table.Th>Thông tin bảng giá</Table.Th>
              </Table.Tr>
            </Table.Thead>
            <Table.Tbody>
              <Table.Tr>
                <Table.Th>Số giao dịch</Table.Th>
                <Table.Td>
                  <Input
                    placeholder="Số bảng giá"
                    value={
                      dataPriceList?.tblPriceListCommand?.priceListNum || ""
                    }
                    onChange={(e) => {
                      handleChangeDataPriceListCommand(
                        e.currentTarget.value,
                        "priceListNum"
                      );
                    }}
                    disabled={isDetailProp || isEditProp}
                  />
                </Table.Td>
              </Table.Tr>
              <Table.Tr>
                <Table.Th>Người lập</Table.Th>
                <Table.Td>
                  <TextInput
                    value={localStorage.getItem("fullName")?.toString() || ""}
                    disabled
                  />
                </Table.Td>
              </Table.Tr>
              <Table.Tr>
                <Table.Th>Ngày hoạt động</Table.Th>
                <Table.Td>
                  <DateTimePicker
                    placeholder="Ngày hoạt động"
                    clearable
                    locale="vi"
                    value={
                      dataPriceList?.tblPriceListCommand?.activeDate
                        ? new Date(
                            dataPriceList?.tblPriceListCommand?.activeDate
                          )
                        : null
                    }
                    minDate={new Date()}
                    onChange={(value) => {
                      setOperationDay(value);
                      handleChangeDataPriceListCommand(
                        // moment(value).format("YYYY-MM-DD[T]HH:mm:ss"),
                        value?.toISOString() || null,
                        "activeDate"
                      );
                      handleChangeDataPriceListCommand(
                        // moment(value).format("YYYY-MM-DD[T]HH:mm:ss"),
                        "",
                        "inactiveDate"
                      );
                    }}
                    disabled={isDetailProp}
                  />
                </Table.Td>
              </Table.Tr>
              <Table.Tr>
                <Table.Th>Ngày dừng hoạt động</Table.Th>
                <Table.Td>
                  <DateTimePicker
                    placeholder="Ngày dừng hoạt động"
                    clearable
                    locale="vi"
                    minDate={
                      new Date(dayjs(operationDay).add(1, "day").toDate())
                    }
                    value={
                      dataPriceList?.tblPriceListCommand?.inactiveDate
                        ? new Date(
                            dataPriceList?.tblPriceListCommand?.inactiveDate
                          )
                        : null
                    }
                    onChange={(value) =>
                      handleChangeDataPriceListCommand(
                        // moment(value).format("YYYY-MM-DD[T]HH:mm:ss"),
                        value?.toISOString() || null,
                        "inactiveDate"
                      )
                    }
                    disabled={
                      isDetailProp ||
                      !dataPriceList?.tblPriceListCommand?.activeDate
                    }
                  />
                </Table.Td>
              </Table.Tr>
              <Table.Tr>
                <Table.Th>Chi nhánh áp dụng</Table.Th>
                <Table.Td>
                  <Combobox
                    store={combobox}
                    onOptionSubmit={handleBranchSelect}
                  >
                    <Combobox.Target>
                      <Textarea
                        placeholder="Chi nhánh áp dụng"
                        onClick={() => combobox.openDropdown()}
                        value={convertListId(
                          dataPriceList.tblPriceListCommand?.listIdBranch ?? ""
                        )?.split(",")}
                        onChange={(event) => {
                          setDataPriceList((prevData) => ({
                            ...prevData,
                            tblPriceListCommand: {
                              ...prevData.tblPriceListCommand,
                              listIdBranch: event.currentTarget.value,
                            },
                          }));
                          combobox.updateSelectedOptionIndex();
                        }}
                        disabled={isDetailProp}
                        rows={4}
                      />
                    </Combobox.Target>

                    <Combobox.Dropdown>
                      <Combobox.Options>
                        <ScrollArea.Autosize type="scroll" mah={300}>
                          {dataBranch?.map((branch: any) => (
                            <Combobox.Option
                              value={branch.value}
                              key={branch.value}
                              active={dataPriceList.tblPriceListCommand?.listIdBranch?.includes(
                                branch.value
                              )}
                              onMouseOver={() => combobox.resetSelectedOption()}
                            >
                              <Group gap="sm">
                                <Checkbox
                                  label={branch.text}
                                  checked={dataPriceList.tblPriceListCommand?.listIdBranch?.includes(
                                    branch.value
                                  )}
                                  onChange={() => {}}
                                  aria-hidden
                                  tabIndex={-1}
                                  style={{ pointerEvents: "none" }}
                                />
                                {/* <span>{}</span> */}
                              </Group>
                            </Combobox.Option>
                          ))}
                        </ScrollArea.Autosize>
                      </Combobox.Options>
                    </Combobox.Dropdown>
                  </Combobox>
                </Table.Td>
              </Table.Tr>
              <Table.Tr>
                <Table.Th>Trạng thái bảng giá</Table.Th>
                <Table.Td>
                  <Select
                    placeholder="Trạng thái bảng giá"
                    clearable
                    data={[
                      {
                        value: "0",
                        label: "Không xác nhận",
                      },
                      {
                        value: "1",
                        label: "Đã xác nhận",
                      },
                    ]}
                    value={dataPriceList?.tblPriceListCommand?.isDrap?.toString()}
                    onChange={(e) =>
                      handleChangeDataPriceListCommand(e, "isDrap")
                    }
                    disabled={isDetailProp}
                  />
                </Table.Td>
              </Table.Tr>
              <Table.Tr>
                <Table.Th>Mô tả</Table.Th>
                <Table.Td>
                  <Textarea
                    placeholder="Nhập mô tả..."
                    value={
                      dataPriceList?.tblPriceListCommand?.description || ""
                    }
                    onChange={(e) => {
                      handleChangeDataPriceListCommand(
                        e.currentTarget.value,
                        "description"
                      );
                    }}
                    rows={16}
                    disabled={isDetailProp}
                  />
                </Table.Td>
              </Table.Tr>
            </Table.Tbody>
          </Table>
        </Panel>
        <PanelResizeHandle id="resize-handle" style={{ width: "5px" }} />
        <Panel defaultSize={70} minSize={25} maxSize={75}>
          <MantineReactTable table={table} />
        </Panel>
      </PanelGroup>
    </>
  );
};

export default ListDetailsPrice;
