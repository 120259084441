import React, { forwardRef, useEffect, useState } from "react";
import styles from "./PrintStyle.module.css";
import { Center, Flex, Grid, Image, Table } from "@mantine/core";
import { IconMail, IconPhone, IconWorldWww } from "@tabler/icons-react";
interface PrintedInvoiceProps {
  dataPrint: any;
  typePrint: string;
  userInfo: any;
  currentDate: any;
}

const PrintWarehouseOutWard = forwardRef<HTMLDivElement, PrintedInvoiceProps>(
  ({ dataPrint, typePrint, userInfo, currentDate }, ref) => {
    const dataTransaction = dataPrint?.tblInventoryTransactionModel;
    const dataSerials = dataPrint?.inventoryTransactionSerialModels;

    return (
      <div ref={ref}>
        <div className={styles.containerPrint}>
          <div className={styles.contentMain}>
            <Grid>
              <Grid.Col span={4}>
                <Flex direction="column">
                  <h6 style={{ margin: 0, fontSize: "8px", fontWeight: 600 }}>
                    Công Ty Cổ Phần Đầu Tư Công Nghệ HACOM
                  </h6>
                  <p style={{ margin: 0, fontSize: "7px" }}>
                    CN: 129 Lê Thanh Nghị, P. Đồng Tâm, Q. Hai Bà Trưng, TP. Hà
                    Nội
                  </p>
                  <Flex direction="row" gap="3px" align="top">
                    <Flex align="flex-start" gap="3px">
                      <IconPhone
                        stroke={2}
                        style={{ width: "8px", paddingBottom: "13px" }}
                      />
                      <p
                        style={{ fontSize: "7px", margin: 0 }}
                        className={styles.text}
                      >
                        19001903{" |"}
                      </p>
                    </Flex>
                    <Flex align="flex-start" className={styles.text} gap="3px">
                      <IconMail
                        stroke={2}
                        style={{ width: "8px", paddingBottom: "13px" }}
                      />
                      <p style={{ margin: 0, fontSize: "7px" }}>
                        dichvukhachhang@hacom.vn{" |"}
                      </p>
                    </Flex>
                    <Flex align="flex-start" className={styles.text} gap="3px">
                      <IconWorldWww
                        stroke={2}
                        style={{ width: "8px", paddingBottom: "13px" }}
                      />
                      <p style={{ margin: 0, fontSize: "7px" }}>Hacom.vn</p>
                    </Flex>
                  </Flex>
                </Flex>
              </Grid.Col>
              <Grid.Col span={4}>
                <h1
                  className={styles.text}
                  style={{ fontWeight: "600", lineHeight: "1.5" }}
                >
                  PHIẾU XUẤT KHO
                </h1>
              </Grid.Col>
              <Grid.Col span={4}>
                <Flex
                  direction="row"
                  justify="flex-end"
                  className={styles.warehouseCode}
                >
                  <p>Mã Xuất Kho:</p>
                  <p>{dataTransaction?.transactionCode}</p>
                </Flex>
                <Flex
                  direction="row"
                  justify="flex-end"
                  className={styles.warehouseCode}
                >
                  <p>Mã Yêu Cầu Xuất Kho:</p>
                  <p>{dataTransaction?.sourceCode}</p>
                </Flex>
              </Grid.Col>
            </Grid>
            {/* bảng thông tin bên xuất, bên nhận */}
            <div>
              <table style={{ borderCollapse: "collapse", width: "100%" }}>
                <thead>
                  <tr>
                    <th
                      style={{
                        border: "1px solid black",
                        width: "50%",
                        fontWeight: 600,
                      }}
                    >
                      BÊN XUẤT
                    </th>
                    <th
                      style={{
                        border: "1px solid black",
                        width: "50%",
                        fontWeight: 600,
                      }}
                    >
                      BÊN NHẬN
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr className={styles.tableRow}>
                    <td className={styles.tableCell}>
                      <Flex direction={"column"}>
                        <Flex
                          direction="row"
                          className={styles.flexRowWithBorder}
                        >
                          {/* left */}
                          <div
                            className={`${styles.cellWithRightBorder} ${styles.contentItemleft}`}
                          >
                            <div className={styles.innerText}>Phòng ban</div>
                            <div className={styles.innerText}>
                              Địa điểm làm việc
                            </div>
                            <div className={styles.innerText}>Bộ phận</div>
                            <div className={styles.innerText}>Chức danh</div>
                            <div className={styles.innerText}>Họ &tên</div>
                            <div className={styles.innerText}>Kho</div>
                          </div>
                          {/* right */}
                          <div className={styles.contentItemRight}>
                            <div className={styles.innerText}>
                              {dataTransaction?.fromBranchName}
                            </div>
                            <div className={styles.innerText}>
                              {dataTransaction?.fromBranchAddress}
                            </div>
                            <div className={styles.innerText}>
                              {userInfo?.depName}
                            </div>
                            <div className={styles.innerText}>
                              {userInfo?.roleName}
                            </div>
                            <div className={styles.innerText}>
                              {userInfo?.fullName}
                            </div>
                            <div className={styles.innerText}>
                              {dataTransaction?.fromInvName}
                            </div>
                          </div>
                        </Flex>
                      </Flex>
                    </td>
                    <td className={styles.tableCell}>
                      <Flex direction={"column"}>
                        <Flex
                          direction="row"
                          className={styles.flexRowWithBorder}
                        >
                          {typePrint === "XUAT_BAN" ? (
                            <>
                              {/* left */}
                              <div
                                className={`${styles.cellWithRightBorder} ${styles.contentItemleft}`}
                              >
                                <div className={styles.innerText}>Họ & Tên</div>
                                <div className={styles.innerText}>
                                  Số điện thoại
                                </div>
                                <div className={styles.innerText}>
                                  Tỉnh / Thành
                                </div>
                                <div className={styles.innerText}>
                                  Quận / Huyện
                                </div>
                                <div className={styles.innerText}>
                                  Phường / Xã
                                </div>
                                <div className={styles.innerText}>
                                  Địa chỉ cụ thể
                                </div>
                              </div>
                              {/* right */}
                              <div className={styles.contentItemRight}>
                                <div className={styles.innerText}>
                                  {dataTransaction?.cusName}
                                </div>
                                <div className={styles.innerText}>
                                  {dataTransaction?.cusTelephone}
                                </div>
                                <div className={styles.innerText}>
                                  {dataTransaction?.cusProvinceName}
                                </div>
                                <div className={styles.innerText}>
                                  {dataTransaction?.cusDestrictName}
                                </div>
                                <div className={styles.innerText}>
                                  {dataTransaction?.cusCommuneName}
                                </div>
                                <div className={styles.innerText}>
                                  {dataTransaction?.cusAddress}
                                </div>
                              </div>
                            </>
                          ) : (
                            <>
                              {/* left */}
                              <div
                                className={`${styles.cellWithRightBorder} ${styles.contentItemleft}`}
                              >
                                <div className={styles.innerText}>
                                  Tên Nhà Cung Cấp
                                </div>
                                <div className={styles.innerText}>
                                  Mã Nhà Cung Cấp
                                </div>
                                <div className={styles.innerText}>
                                  Người nhận
                                </div>
                                <div className={styles.innerText}>
                                  Số điện thoại
                                </div>
                                <div className={styles.innerText}>{""}</div>
                                <div className={styles.innerText}>{""}</div>
                              </div>
                              {/* right */}
                              <div className={styles.contentItemRight}>
                                <div className={styles.innerText}>
                                  {dataTransaction?.cusName}
                                </div>
                                <div className={styles.innerText}>
                                  {dataTransaction?.cusCode}
                                </div>
                                <div className={styles.innerText}>
                                  {dataTransaction?.toEmpName}
                                </div>
                                <div className={styles.innerText}>
                                  {dataTransaction?.cusTelephone}
                                </div>
                                <div className={styles.innerText}></div>
                                <div className={styles.innerText}></div>
                              </div>
                            </>
                          )}
                        </Flex>
                      </Flex>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            {/* lý do */}
            <div style={{ margin: "5px 0" }}>
              <Flex direction="row">
                <p style={{ margin: 0, marginRight: "5px" }}>Lý do:</p>
                <p style={{ margin: 0 }}>
                  {typePrint === "XUAT_BAN"
                    ? "Xuất Bán"
                    : "Xuất trả Nhà Cung Cấp"}
                </p>
              </Flex>
              <div>
                <p style={{ margin: 0 }}>Ghi chú:</p>
              </div>
            </div>
            {/* table sản phẩm */}
            <div className={styles.ProductMain}>
              <table>
                <thead>
                  <tr>
                    <th>STT</th>
                    <th>Mã Hàng</th>
                    <th style={{ width: "10px !important" }}>Serial</th>
                    <th>Tên sản phẩm</th>
                    <th>ĐVT</th>
                    <th>SL</th>
                    {typePrint === "XUAT_TRA_NCC" ? (
                      <>
                        <th>ĐVT2</th>
                        <th>SL2</th>
                      </>
                    ) : (
                      <>
                        <th>Giá bán lẻ</th>
                        <th>Thành tiền</th>
                      </>
                    )}

                    <th>Tình trạng</th>
                    <th>Ghi chú</th>
                  </tr>
                </thead>
                <tbody>
                  {dataSerials?.map((item: any, index: number) => {
                    return (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{item?.itemCode}</td>
                        <td
                          style={{ width: "10px" }}
                          className={styles.tdSerial}
                        >
                          {item?.serial}
                        </td>
                        <td>{item?.itemName}</td>
                        <td>{item?.primaryUomCode}</td>
                        <td>{item?.primaryQuantity}</td>
                        {typePrint === "XUAT_TRA_NCC" ? (
                          <>
                            <td>{item?.subUomCode}</td>
                            <td>{item?.subQuantity}</td>
                          </>
                        ) : (
                          <>
                            <td>{item?.soPrice}</td>
                            <td>{item?.soPrice * item?.primaryQuantity}</td>
                          </>
                        )}

                        <td>{item.serialStatus}</td>
                        <td>{item?.note}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
            {/*  */}
            <div style={{ marginTop: "5px" }}>
              <Flex
                direction="row"
                justify="flex-end"
                style={{ width: "100%" }}
              >
                <div>
                  Hà Nội ngày{" "}
                  <strong style={{ fontStyle: "italic" }}>{currentDate}</strong>{" "}
                </div>
              </Flex>
              <div>
                <Grid>
                  <Grid.Col span={4}>
                    <Center style={{ fontWeight: "600" }}>BÊN XUẤT</Center>
                  </Grid.Col>
                  <Grid.Col span={4}>
                    <Center style={{ fontWeight: "600" }}>GIAO VẬN</Center>
                  </Grid.Col>
                  <Grid.Col span={4}>
                    <Center style={{ fontWeight: "600" }}>BIÊN NHẬN</Center>
                  </Grid.Col>
                </Grid>
              </div>
            </div>
            <div className={styles.backgroudHacom}>
              <Image
                radius="md"
                src="https://hanoicomputercdn.com/media/lib/09-08-2023/logo-hacom-since-2001.png"
                className={styles.logo}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
);

export default PrintWarehouseOutWard;
