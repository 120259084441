import {
  ActionIcon,
  Badge,
  Box,
  Button,
  ComboboxItem,
  Flex,
  Grid,
  GridCol,
  Input,
  Menu,
  rem,
  Select,
  Text,
  Tooltip,
} from "@mantine/core";
import { DatePickerInput } from "@mantine/dates";
import { useHotkeys } from "@mantine/hooks";
import {
  IconBrandBootstrap,
  IconCaretDown,
  IconEdit,
  IconEye,
  IconFileExport,
  IconFileTypography,
  IconFileUpload,
  IconPlus,
  IconSearch,
  IconStatusChange,
  IconTimeDuration15,
  IconUserCode,
} from "@tabler/icons-react";
import {
  MantineReactTable,
  MRT_ColumnDef,
  MRT_RowSelectionState,
  MRT_SortingState,
  useMantineReactTable,
} from "mantine-react-table";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import * as xlsx from "xlsx";
import {
  _timerDelayClick,
  repositoryPos,
} from "../../../_base/_const/_constVar";
import _breadcrumb from "../../../_base/component/_layout/_breadcrumb";
import { paginationBase } from "../../../_base/model/_base/BaseTable";
import { formatDateTransfer } from "../../../common/FormatDate/FormatDate";
import { _localization } from "../../../config/location";
import { cobalt_blue, green, peach, teal } from "../../../const/variables";
import { TblOutwardDetail, TblOutwardGetList } from "../../../model/TblOutWard";
import {
  getBranchSelect,
  getTblDmEmployeeSelect,
  getTblInventoryTransactionTypeOutWard,
} from "../../../service/getSelectApi";
import { IconPrinter } from "@tabler/icons-react";
import { useReactToPrint } from "react-to-print";
import { MessageResponse } from "../../../model/MessageResponse";
import { NotificationExtension } from "../../../_base/extension/NotificationExtension";
import PrintWarehouseOutWard from "./PrintWarehouseOutWard";
function getColorStatus(text: any) {
  switch (text) {
    case "OPEN":
      return cobalt_blue.base;
    case "LOCK":
      return green.light;
    default:
      return cobalt_blue.base;
  }
}

function getStatus(text: any) {
  switch (text) {
    case "OPEN":
      return "Lưu tạm";
    case "LOCK":
      return "Phiếu khoá";
    default:
      return "Tạo mới";
  }
}

const WarehouseOutWard = () => {
  const navigate = useNavigate();
  // Reference data from another table

  //data and fetching state
  const headerRef = React.useRef<HTMLDivElement>(null);
  const [data, setData] = useState<TblOutwardGetList[]>([]);
  const [dataBranch, setDataBranch] = useState<ComboboxItem[]>([]);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);
  const [rowCount, setRowCount] = useState(0);
  const [height, setHeight] = useState(0);
  const [dataInventoryTransactionType, setDataInventoryTransactionType] =
    useState<ComboboxItem[]>([]);
  const [dataEmployee, setDataEmployee] = useState<ComboboxItem[]>([]);
  //table state
  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});

  const [selectIds, setSelectIds] = useState<string[]>([]);
  const [pagination, setPagination] = useState(paginationBase);
  const [sorting, setSorting] = useState<MRT_SortingState>([]);
  // Print
  const [idPrint, setIdPrint] = useState<string | number>("");
  const [typePrint, setTypePrint] = useState<string | null>("");
  const [dataPrint, setDataPrint] = useState(null);
  const componentRef = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current || null,
  });
  const [userInfo, setUserInfo] = useState<any>();
  const [currentDate, setCurrentDate] = useState<string>("");
  //count
  const [timeLeft, setTimeLeft] = useState(60);
  const [isCounting, setIsCounting] = useState(false);
  const dataStatusOption = [
    { value: "OPEN", label: "Đang xuất" },
    { value: "LOCK", label: "Hoàn thành (Khoá)" },
  ];
  const [search, setSearch] = useState({
    keySearch: "",
    fromTransationDate: "",
    toTransationDate: "",
    sourceType: "",
    invId: "",
    status: "",
    empId: "",
  });

  const columns = React.useMemo<MRT_ColumnDef<TblOutwardGetList>[]>(
    () => [
      {
        accessorKey: "transactionCode",
        header: "Mã phiếu",
        size: 30,
        Cell: ({ renderedCellValue, row }) => (
          <Tooltip label="Xem chi tiết">
            <Badge
              radius="sm"
              variant="dot"
              size="lg"
              style={{ cursor: "pointer" }}
              color={renderedCellValue === null ? "red" : green.base}
              onDoubleClick={() =>
                editData(
                  row.original.transactionId,
                  row.original.sourceType,
                  true
                )
              }
            >
              {renderedCellValue === null ? null : renderedCellValue}
            </Badge>
          </Tooltip>
        ),
      },
      {
        accessorKey: "status",
        header: "Trạng thái",
        size: 20,
        Cell: ({ row }) => (
          <>
            <Badge color={getColorStatus(row.original.status)}>
              {getStatus(row.original.status)}
            </Badge>
          </>
        ),
      },
      {
        accessorKey: "transactionDate",
        header: "Ngày giao dịch",
        Cell: ({ renderedCellValue }: any) => (
          <>{renderedCellValue && formatDateTransfer(renderedCellValue)}</>
        ),
        size: 30,
      },
      {
        accessorKey: "createDate",
        header: "Ngày lập",
        Cell: ({ renderedCellValue }: any) => (
          <>{renderedCellValue && formatDateTransfer(renderedCellValue)}</>
        ),
        size: 30,
      },
      {
        accessorKey: "createName",
        header: "Người lập",
        // Cell: ({ renderedCellValue }: any) => <>{renderedCellValue}</>,
        size: 30,
      },

      {
        accessorKey: "sourceCode",
        header: "Mã chứng từ gốc",
        size: 30,
        Cell: ({ renderedCellValue }: any) => (
          <Text fw={700} size="sm">
            {renderedCellValue}
          </Text>
        ),
      },

      {
        accessorKey: "sourceTypeName",
        header: "Loại chứng từ gốc",
        size: 30,
        Cell: ({ renderedCellValue, row }) => (
          <>
            <Badge color={getColorType(row.original.sourceType)}>
              {renderedCellValue}
            </Badge>
          </>
        ),
      },

      {
        accessorKey: "branchName",
        header: "Chi nhánh",
        size: 30,
        Cell: ({ renderedCellValue, row }) => (
          <Text size="sm">{renderedCellValue}</Text>
        ),
      },

      {
        accessorKey: "invName",
        header: "Kho",
        size: 30,
        Cell: ({ renderedCellValue }) => (
          <Text size="11.5px">{renderedCellValue}</Text>
        ),
      },

      {
        accessorKey: "description",
        header: "Ghi chú",
        size: 30,
        Cell: ({ renderedCellValue, row }) => (
          <Tooltip label={renderedCellValue}>
            <Text size="sm" maw={300}>
              {renderedCellValue}
            </Text>
          </Tooltip>
        ),
      },

      {
        accessorKey: "action",
        header: "Thao tác",
        size: 50,
        enableSorting: false,
        enableColumnActions: false,
        enableColumnFilter: false,
        Cell: ({ renderedCellValue, row, table }) => (
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              gap: "10px",
            }}
          >
            <Tooltip label="Chỉnh sửa">
              <ActionIcon
                variant="light"
                aria-label="Settings"
                color="orange"
                disabled={row.original.status === "LOCK"}
                onClick={async () => {
                  editData(
                    row.original.transactionId,
                    row.original.sourceType,
                    false // status !== "LOCK"
                  );
                }}
              >
                <IconEdit size={20} stroke={1.5} />
              </ActionIcon>
            </Tooltip>
            <Tooltip label="Xem chi tiết">
              <ActionIcon
                variant="light"
                aria-label="Settings"
                color="blue"
                onClick={async () => {
                  editData(
                    row.original.transactionId,
                    row.original.sourceType,
                    true // status === "LOCK"
                  );
                }}
              >
                <IconEye size={20} stroke={1.5} />
              </ActionIcon>
            </Tooltip>
            {row.original.sourceType == "XUAT_BAN" ||
            row.original.sourceType == "XUAT_TRA_NCC" ? (
              <>
                <Tooltip label="In Phiếu Xuất Kho">
                  <ActionIcon
                    variant="light"
                    aria-label="Settings"
                    color="cyan"
                    onClick={() =>
                      onhandlePrint(
                        row.original.transactionId,
                        row.original.sourceType
                      )
                    }
                    disabled={row.original.status === "OPEN"}
                  >
                    <IconPrinter size={20} stroke={1.5} />
                  </ActionIcon>
                </Tooltip>
              </>
            ) : (
              <></>
            )}
          </Box>
        ),
      },
    ],
    [dataInventoryTransactionType, dataBranch]
  );
  const onhandlePrint = (id: string | number, type: string | null) => {
    if (id != null && type != null) {
      setIdPrint(id);
      setTypePrint(type);
    }
  };
  const fetchDataToPrint = async (id: number | string, type: string | null) => {
    let apiPath;
    if (type === "XUAT_BAN") {
      apiPath = `/api/v1/Outward/print-sale-order-out-ward?id=${id}`;
    } else if (type === "XUAT_TRA_NCC") {
      apiPath = `/api/v1/Outward/print-request-vendor-return-outward?id=${id}`;
    } else if (type === "XUAT_HUY") {
      apiPath = `/api/v1/Outward/print-request-cancellation-outward?id=${id}`;
    } else if (type === "XUAT_DIEU_CHINH") {
      apiPath = `/api/v1/Outward/print-request-adjustment-outward?id=${id}`;
    } else if (type === "XUAT_DIEU_CHUYEN_CHI_NHANH") {
      apiPath = `/api/v1/Outward/print-request-transfer-outward?id=${id}`;
    } else if (type === "XUAT_DIEU_CHUYEN_NOI_BO") {
      apiPath = `/api/v1/Outward/print-request-transfer-internal-outward?id=${id}`;
    } else if (type === "XUAT_DIEU_CHUYEN_CHI_NHANH_SERIAL") {
      apiPath = `/api/v1/Outward/print-request-transfer-serial-outward?id=${id}`;
    } else if (type === "XUAT_LINH_KIEN_SAN_XUAT") {
      apiPath = `/api/v1/Outward/print-production-order-outward?id=${id}`;
    } else {
      return;
    }

    try {
      const response = await repositoryPos.get<MessageResponse<any>>(apiPath);
      if (response?.success && response?.data) {
        setDataPrint(response?.data);
      } else {
        console.log("error fetch data to print");
        setDataPrint(null);
        NotificationExtension.Fails("error fetch data to print");
      }
    } catch (error) {
      console.log("error fetch data to print", error);
    }
  };

  const startCountdown = () => {
    if (!isCounting) {
      setTimeLeft(_timerDelayClick);
      setIsCounting(true);
    }
  };

  const getColorType = (type: string | null) => {
    switch (type) {
      case "XUAT_DIEU_CHUYEN_CHI_NHANH":
        return "#f06418";
      case "XUAT_DIEU_CHUYEN_CHI_NHANH_SERIAL":
        return "#ffab09";
      case "XUAT_HUY":
        return "#d9d02f";
      case "XUAT_DIEU_CHINH":
        return "#6bd731";
      case "XUAT_DIEU_CHUYEN_NOI_BO":
        return "#09b8ff";
      case "XUAT_DIEU_CHUYEN_NOI_BO_SERIAL":
        return "#1f32c4";
      case "XUAT_TRA_NCC":
        return "#2bdd66";
      case "XUAT_BAN":
        return teal.light;
      case null:
        return "rgba(0, 0, 0, 0)";
      case "NOI_BO":
        return "#656a7e";
    }
  };

  const handleExport = () => {
    try {
      const worksheet = xlsx.utils.json_to_sheet(data);
      const workbook = xlsx.utils.book_new();
      xlsx.utils.book_append_sheet(workbook, worksheet, "Data");
      xlsx.writeFile(workbook, "danh-sach-phieu-xuat-kho.xlsx");
      toast.success("Export excel thành công", { autoClose: 1500 });
    } catch (error) {
      toast.error("Export excel thất bại", { autoClose: 1500 });
    }
  };

  const handleChangeSearchValue = (value: string, key: string) => {
    setSearch((prevData) => ({ ...prevData, [key]: value }));
  };

  const createData = () => {
    navigate("/warehouse-config/create-warehouse-out-ward");
  };

  const editData = (
    id: string | number,
    type: string | number | null,
    isLocked: boolean
  ) => {
    navigate("/warehouse-config/edit-warehouse-out-ward", {
      state: { id: id, type: type, isLocked: isLocked },
    });
  };

  useHotkeys([
    [
      "F11",
      () => {
        createData();
      },
    ],
  ]);

  const fetchData = async () => {
    setIsLoading(true);
    setIsRefetching(true);
    setIsError(false);

    let url = `?Skip=${pagination?.pageIndex * pagination?.pageSize}&Take=${
      pagination.pageSize
    }`;

    if (search.keySearch) {
      url += `&KeySearch=${search.keySearch}`;
    }

    if (search.invId) {
      url += `&InvId=${search.invId}`;
    }

    if (search.fromTransationDate) {
      url += `&FromTransationDate=${search.fromTransationDate}`;
    }

    if (search.toTransationDate) {
      url += `&ToTransationDate=${search.toTransationDate}`;
    }

    if (search.sourceType) {
      url += `&SourceTypeCode=${search.sourceType}`;
    }

    if (search.status) {
      url += `&Status=${search.status}`;
    }

    if (search.empId) {
      url += `&EmpId=${search.empId}`;
    }

    let _softing = "";
    let isOrder = false;
    if (sorting && sorting.length > 0) {
      let _q = sorting[0];
      if (_q) {
        _softing = _q.id;
        isOrder = _q.desc;
      }
      url = url + "&sort=" + _softing + "&isOrder=" + isOrder;
    }

    try {
      const response = await repositoryPos.get(
        `/api/v1/TblItemTransaction/get-list${url}&Type=2`
      );
      if (response && response.success) {
        let result = response.data;
        setIsLoading(false);
        setData(result || []);
        setRowCount(response.totalCount);
        setSelectIds([]);
        table.resetRowSelection();
      } else setData([]);
    } catch (error) {
      setData([]);
      setIsError(true);
      console.error(error);
      return;
    } finally {
      setIsLoading(false);
      setIsRefetching(false);
    }
    startCountdown();
  };

  const fetchdataInventoryTransactionType = async () => {
    const response = await getTblInventoryTransactionTypeOutWard();

    setDataInventoryTransactionType(
      response.map((type) => ({ value: type.value, label: type.text }))
    );
  };

  const fetchDataEmployee = async () => {
    const response = await getTblDmEmployeeSelect();
    setDataEmployee(
      response.map((employee) => ({
        value: employee.value,
        label: employee.text,
      }))
    );
  };

  const fetchDataBranch = async () => {
    const getDataBranch = await getBranchSelect();

    const mappedData = getDataBranch
      .filter(
        (item) => item.value != null && item.text != null && item.value !== "-1"
      )
      .map((item) => ({
        value: item.value,
        label: `${item.text}`,
      }));
    setDataBranch(mappedData);
  };
  useEffect(() => {
    if (idPrint !== "") {
      fetchDataToPrint(idPrint, typePrint);
    }
  }, [idPrint]);
  useEffect(() => {
    if (dataPrint !== null) {
      handlePrint();
      setIdPrint("");
      setDataPrint(null);
    }
  }, [dataPrint]);
  useEffect(() => {
    const headerHeight = headerRef.current?.offsetHeight || 0;
    const handleResize = () => {
      // 190 là chiều cao của phần phân trang
      // headerHeight là chiều cao của phần header
      setHeight(window.innerHeight - (150 + headerHeight));
    };

    handleResize(); // Set initial height
    window.addEventListener("resize", handleResize); // Update height on window resize

    return () => {
      window.removeEventListener("resize", handleResize); // Clean up event listener
    };
  }, []);

  useEffect(() => {
    const valuesList = Object.keys(rowSelection);
    setSelectIds(valuesList);
    if (valuesList.length < 1) setSelectIds([]);
    else {
      const valuesList = Object.keys(rowSelection);
      setSelectIds(valuesList);
    }
  }, [rowSelection]);

  useEffect(() => {
    fetchData();
  }, [sorting, pagination]);
  useEffect(() => {
    const userLogin = localStorage.getItem("userLogin");
    const fullName = localStorage.getItem("fullName");
    if (userLogin) setUserInfo({ ...JSON.parse(userLogin), fullName });
    const getCurrentDateFormatted = (): string => {
      const today = new Date();
      const day = today.getDate().toString().padStart(2, "0");
      const month = (today.getMonth() + 1).toString().padStart(2, "0");
      const year = today.getFullYear();

      return `${day}.${month}.${year}`;
    };
    setCurrentDate(getCurrentDateFormatted());
  }, []);
  const table = useMantineReactTable({
    columns,
    data,
    positionToolbarAlertBanner: "bottom",
    enableTopToolbar: false,
    mantineTopToolbarProps: {
      style: {
        borderBottom: "3px solid rgba(128, 128, 128, 0.5)",
        marginBottom: 5,
      },
    },
    localization: _localization,
    enableRowSelection: true,
    getRowId: (row) => row.transactionId?.toString(),
    initialState: {
      showColumnFilters: false,
      columnPinning: {
        left: ["mrt-row-select", "transactionCode", "status"],
        right: ["action"],
      },
      columnVisibility: { id: false },
      density: "xs",
    },
    mantineTableContainerProps: {
      style: { maxHeight: height, minHeight: height },
    },
    enableStickyHeader: true,
    onRowSelectionChange: setRowSelection,
    manualFiltering: false,
    manualPagination: true,
    manualSorting: true,
    rowCount,
    onSortingChange: setSorting,
    onPaginationChange: setPagination,
    mantineTableBodyCellProps: () => ({
      style: {
        fontSize: "11.5px",
        padding: "4px 12px",
      },
    }),
    state: {
      isLoading,
      pagination,
      showAlertBanner: isError,
      showProgressBars: isRefetching,
      showSkeletons: isLoading,
      rowSelection,
      sorting,
    },
    mantineToolbarAlertBannerProps: false
      ? { color: "red", children: "Lỗi tải dữ liệu !" }
      : undefined,
    mantinePaginationProps: {
      showRowsPerPage: true,
      withEdges: true,
      rowsPerPageOptions: ["20", "50", "100"],
    },
    paginationDisplayMode: "pages",
    enableColumnPinning: true,
    mantineTableProps: {
      striped: false,
    },
  });

  return (
    <>
      <div
        style={{ border: "1px solid #dee2e6", padding: "5px" }}
        ref={headerRef}
      >
        <Flex
          gap="md"
          justify={"space-between"}
          align="center"
          direction="row"
          wrap="wrap"
          w={"100%"}
        >
          <_breadcrumb></_breadcrumb>
          <Flex gap="md" align="center" direction="row" wrap="wrap">
            <Button
              leftSection={<IconPlus size={14} />}
              color="blue"
              variant="outline"
              onClick={() => {
                createData();
              }}
            >
              Tạo mới (F11)
            </Button>

            <Menu shadow="md" width={200}>
              <Menu.Target>
                <Button
                  // size="xs"
                  rightSection={
                    <IconCaretDown
                      style={{ width: rem(14), height: rem(14) }}
                    />
                  }
                >
                  Chức năng
                </Button>
              </Menu.Target>

              <Menu.Dropdown>
                <Menu.Label>Application</Menu.Label>
                <Menu.Item
                  leftSection={
                    <IconFileExport
                      style={{ width: rem(14), height: rem(14) }}
                    />
                  }
                  onClick={handleExport}
                >
                  Export Excel
                </Menu.Item>
                <Tooltip label={"Chức năng đang update"}>
                  <Menu.Item
                    leftSection={
                      <IconFileUpload
                        style={{ width: rem(14), height: rem(14) }}
                      />
                    }
                  >
                    Import Excel
                  </Menu.Item>
                </Tooltip>
              </Menu.Dropdown>
            </Menu>
          </Flex>
        </Flex>

        <Grid mt={"10px"}>
          <GridCol span={{ base: 6, sm: 4, md: 2, lg: 1.25 }}>
            <Input
              w={"100%"}
              placeholder="Từ khoá"
              type="text"
              leftSection={<IconSearch color="#15aabf" />}
              onChange={(e) => {
                handleChangeSearchValue(e.target.value ?? "", "keySearch");
              }}
            />
          </GridCol>

          <GridCol span={{ base: 6, sm: 4, md: 2, lg: 1.25 }}>
            <Select
              placeholder={"Trạng thái"}
              searchable
              clearable
              data={dataStatusOption}
              leftSection={<IconStatusChange color="#15aabf" />}
              comboboxProps={{
                transitionProps: { transition: "pop", duration: 200 },
              }}
              nothingFoundMessage={"Loading..."}
              onChange={(value) =>
                handleChangeSearchValue(value || "", "status")
              }
            />
          </GridCol>

          <GridCol span={{ base: 6, sm: 4, md: 2, lg: 1.25 }}>
            <Select
              placeholder={"Loại chứng từ gốc"}
              searchable
              clearable
              leftSection={<IconFileTypography color="#15aabf" />}
              comboboxProps={{
                transitionProps: { transition: "pop", duration: 200 },
              }}
              data={dataInventoryTransactionType}
              nothingFoundMessage={"Loading..."}
              onChange={(value) =>
                handleChangeSearchValue(value || "", "sourceType")
              }
              onClick={() => {
                if (dataInventoryTransactionType.length === 0) {
                  fetchdataInventoryTransactionType();
                }
              }}
            />
          </GridCol>

          <GridCol span={{ base: 6, sm: 4, md: 2, lg: 1.25 }}>
            <Select
              searchable
              clearable
              placeholder="Chọn chi nhánh"
              comboboxProps={{
                transitionProps: { transition: "pop", duration: 200 },
              }}
              leftSection={<IconBrandBootstrap color="#15aabf" />}
              nothingFoundMessage={"Loading..."}
              data={dataBranch}
              onChange={(value) => {
                handleChangeSearchValue(value || "", "invId");
              }}
              onClick={() => {
                if (dataBranch.length === 0) {
                  fetchDataBranch();
                }
              }}
            />
          </GridCol>

          <GridCol span={{ base: 6, sm: 4, md: 2, lg: 1.25 }}>
            <Select
              placeholder={"Chọn nhân viên"}
              searchable
              clearable
              data={dataEmployee}
              leftSection={<IconUserCode color="#15aabf" />}
              comboboxProps={{
                transitionProps: { transition: "pop", duration: 200 },
              }}
              nothingFoundMessage={"Loading..."}
              onChange={(value) =>
                handleChangeSearchValue(value || "", "empId")
              }
              onClick={() => {
                if (dataEmployee.length === 0) {
                  fetchDataEmployee();
                }
              }}
            />
          </GridCol>

          <GridCol span={{ base: 8, sm: 6, md: 3.35, lg: 2.5 }}>
            <DatePickerInput
              // type="multiple"
              type="range"
              size="sm"
              allowSingleDateInRange
              placeholder="Chọn khoảng ngày"
              leftSection={<IconTimeDuration15 color="#15aabf" />}
              locale="vi"
              // value={value}
              valueFormat="DD/MM/YYYY"
              onChange={(e) => {
                handleChangeSearchValue(
                  e[0] ? e[0].toISOString() : "",
                  "fromTransationDate"
                );
                handleChangeSearchValue(
                  e[1] ? e[1].toISOString() : "",
                  "toTransationDate"
                );
              }}
              clearable
            />
          </GridCol>

          <GridCol span={{ base: 4, sm: 4, md: 1.5, lg: 1.25 }}>
            <Button
              leftSection={<IconSearch size={14} />}
              color="blue"
              variant="outline"
              onClick={async () => {
                await fetchData();
              }}
            >
              Tìm kiếm
            </Button>
          </GridCol>
        </Grid>
      </div>

      <div className="mt-5" style={{ marginTop: "10px" }}>
        <MantineReactTable table={table} />
      </div>
      {dataPrint && typePrint && (
        <div style={{ display: "none" }}>
          <PrintWarehouseOutWard
            dataPrint={dataPrint}
            typePrint={typePrint}
            userInfo={userInfo}
            currentDate={currentDate}
            ref={componentRef}
          />
        </div>
      )}
    </>
  );
};

export default WarehouseOutWard;
