import {
  ActionIcon,
  Badge,
  Box,
  Button,
  Flex,
  Grid,
  Input,
  Menu,
  rem,
  Select,
  Text,
  Title,
  Tooltip,
} from "@mantine/core";
import { DatePickerInput, DateTimePicker } from "@mantine/dates";
import { useHotkeys } from "@mantine/hooks";
import { isNullOrUndefined } from "@syncfusion/ej2-base";
import {
  IconBuildingCommunity,
  IconCalendarSearch,
  IconCaretDown,
  IconCheck,
  IconChecklist,
  IconEdit,
  IconEye,
  IconFileExport,
  IconFileUpload,
  IconIdOff,
  IconNotes,
  IconNumber,
  IconPlus,
  IconReportAnalytics,
  IconSearch,
  IconStatusChange,
  IconTimeDuration15,
  IconTrash,
  IconX,
} from "@tabler/icons-react";
import {
  MantineReactTable,
  MRT_ColumnDef,
  MRT_Row,
  MRT_RowSelectionState,
  MRT_SortingState,
  useMantineReactTable,
} from "mantine-react-table";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import * as xlsx from "xlsx";
import { isNullOrUndefinedArry } from "../../../_base/extension/StringExtension";
import { paginationBase } from "../../../_base/model/_base/BaseTable";
import {
  getListPriceList,
  updateIsDrapPriceList,
  updateStatusPriceList,
} from "../../../api/apiPriceList";
import { _localization } from "../../../config/location";
import "../../../Styles/tab.component.css";
import { tblPriceList } from "../../../model/TblPriceList";
import DeleteView from "./DeleteView";
import { modals } from "@mantine/modals";
import { getListSelectBranch } from "../../../api/apiDMBranch";
import moment from "moment";
import {
  repositoryDelivery,
  repositoryPos,
} from "../../../_base/_const/_constVar";
import { TblDMInventory } from "../../../model/TblDMInventory";
import {
  formatDates,
  formatDateTransfer,
} from "../../../common/FormatDate/FormatDate";
import _breadcrumb from "../../../_base/component/_layout/_breadcrumb";
import { green } from "../../../const/variables";
import { MessageResponse } from "../../../model/MessageResponse";

const ListPrice = () => {
  const headerRef = React.useRef<HTMLDivElement>(null);

  const navigate = useNavigate();
  const [keySearch, setKeySearch] = useState<string>("");
  const [openedMenu, setOpenedMenu] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);
  const [rowCount, setRowCount] = useState(0);
  const [isError, setIsError] = useState(false);
  const [pagination, setPagination] = useState(paginationBase);
  const [sorting, setSorting] = useState<MRT_SortingState>([]);
  const [height, setHeight] = useState(0);
  const [dataBranch, setDataBranch] = useState<any[]>([]);
  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});
  const [selectIds, setSelectIds] = useState<string[]>([]);
  const [dataWarehouse, setDataWarehouse] = useState<TblDMInventory[]>([]);
  const [dataWarehouseLoaded, setDataWarehouseLoaded] = useState(false);
  const [search, setSearch] = useState({
    priceListNum: "",
    listIdBranch: "",
    activeDate: "",
    inactiveDate: "",
    isDrap: "",
    status: "",
    keySearch: "",
  });

  const handleExport = () => {
    try {
      const worksheet = xlsx.utils.json_to_sheet(data);
      const workbook = xlsx.utils.book_new();
      xlsx.utils.book_append_sheet(workbook, worksheet, "Data");
      xlsx.writeFile(workbook, "danh-sach-san-pham-bang-gia-POS.xlsx");
      toast.success("Export excel thành công", { autoClose: 1500 });
    } catch (error) {
      toast.error("Export excel thất bại", { autoClose: 1500 });
    }
  };

  const formatDateString = (isoString: string) => {
    const date = new Date(isoString);
    const adjustedDate = date.setHours(date.getHours() + 7);
    return moment(new Date(adjustedDate)).format("HH:mm:ss DD-MM-YYYY");
  };

  function getColorStatus(text: any) {
    switch (text) {
      case 1:
        return "teal";
      case 2:
        return "red";
      default:
        return "yellow";
    }
  }

  const [data, setData] = useState<tblPriceList[]>([]);

  const columns = React.useMemo<MRT_ColumnDef<any>[]>(
    () => [
      {
        accessorKey: "tblPriceListModel.priceListNum",
        header: "Số bảng giá",
        Cell: ({ renderedCellValue, row }) => (
          <Tooltip label="Xem chi tiết">
            <Badge
              radius="sm"
              variant="dot"
              size="lg"
              style={{ cursor: "pointer" }}
              color={renderedCellValue === null ? "red" : green.base}
              onDoubleClick={() => handleAddPriceList(row?.id, true)}
            >
              {renderedCellValue === null ? null : renderedCellValue}
            </Badge>
          </Tooltip>
        ),
        size: 30,
      },
      {
        accessorKey: "tblPriceListModel.description",
        header: "Mô tả",
        size: 250,
      },
      {
        accessorKey: "tblPriceListModel.listIdBranch",
        header: "Tên trung tâm",
        size: 30,
        Cell: ({ renderedCellValue }: any) => (
          <Box
            w={200}
            style={{
              display: "flex",
              alignItems: "center",
              gap: "16px",
            }}
          >
            <Tooltip
              label={
                <div
                  dangerouslySetInnerHTML={{
                    __html: getNamesFromIds(renderedCellValue, "col"),
                  }}
                />
              }
              w={220}
              multiline
              withArrow
              position="bottom"
              color="gray"
            >
              <Text truncate="end" size="11.5px" h={13}>
                {getNamesFromIds(renderedCellValue, "row")}
              </Text>
            </Tooltip>
          </Box>
        ),
      },
      {
        accessorKey: "tblPriceListModel.activeDate",
        header: "Ngày hoạt động",
        size: 30,
        Cell: ({ renderedCellValue }: any) => (
          <>{renderedCellValue && formatDateTransfer(renderedCellValue)}</>
        ),
      },
      {
        accessorKey: "tblPriceListModel.inactiveDate",
        header: "Ngày hết hạn",
        size: 30,
        Cell: ({ renderedCellValue }: any) => (
          <>{renderedCellValue && formatDateTransfer(renderedCellValue)}</>
        ),
      },
      {
        accessorKey: "tblPriceListModel.status",
        header: "Sử dụng",
        size: 30,
        Cell: ({ renderedCellValue, row }) => (
          <>
            <Badge color={getColorStatus(renderedCellValue || 0)}>
              {renderedCellValue === 1
                ? "Đồng ý duyệt"
                : renderedCellValue === 2
                ? "Từ chối duyệt"
                : "Chưa duyệt"}
            </Badge>
          </>
        ),
      },
      {
        accessorKey: "tblPriceListModel.isDrap",
        header: "Nháp",
        size: 30,
        Cell: ({ renderedCellValue, row }) => (
          <>
            <Badge color={getColorStatus(renderedCellValue || 0)}>
              {renderedCellValue === 1 ? "Đã xác nhận" : "Bản nháp"}
            </Badge>
          </>
        ),
      },
      // {
      //   accessorKey: "tblPriceListModel.createBy",
      //   header: "Người tạo",
      //   size: 30,
      // },
      {
        accessorKey: "tblPriceListModel.createDate",
        header: "Ngày tạo",
        size: 30,
        Cell: ({ renderedCellValue }: any) => (
          <>{renderedCellValue && formatDateTransfer(renderedCellValue)}</>
        ),
      },
      // {
      //   accessorKey: "tblPriceListModel.lastUpdateBy",
      //   header: "Người sửa",
      //   size: 30,
      // },

      {
        accessorKey: "tblPriceListModel.lastUpdateDate",
        header: "Ngày sửa",
        size: 30,
        Cell: ({ renderedCellValue }: any) => (
          <>{renderedCellValue && formatDateTransfer(renderedCellValue)}</>
        ),
      },
      // {
      //   accessorKey: "tblPriceListModel.approveBy",
      //   header: "Người duyệt",
      //   size: 30,
      // },
      {
        accessorKey: "tblPriceListModel.approveDate",
        header: "Ngày duyệt",
        size: 30,
        Cell: ({ renderedCellValue }: any) => (
          <>{renderedCellValue && formatDateTransfer(renderedCellValue)}</>
        ),
      },

      {
        accessorKey: "action",
        header: "Thao tác",
        size: 50,
        Cell: ({ renderedCellValue, row }) => (
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              gap: "4px",
            }}
          >
            {processTaskActionMenu(row)}
          </Box>
        ),
      },
    ],
    [data, dataWarehouse]
  );

  function processTaskActionMenu(row: MRT_Row<any>): any {
    return (
      <>
        <Tooltip label="Sửa báo giá">
          <ActionIcon
            variant="light"
            aria-label="Settings"
            color="yellow"
            disabled={row.original.tblPriceListModel.status === 1}
            onClick={() => handleAddPriceList(row?.id, false, true)}
          >
            <IconEdit size={20} />
          </ActionIcon>
        </Tooltip>
        <Tooltip label="Xem chi tiết báo giá">
          <ActionIcon variant="light" aria-label="Settings" color="cyan">
            <IconEye
              size={20}
              onClick={() => handleAddPriceList(row?.id, true)}
            />
          </ActionIcon>
        </Tooltip>
        <Tooltip label="Status">
          <Menu shadow="md" width={200}>
            <Menu.Target>
              <ActionIcon variant="light" aria-label="Settings" color="orange">
                <IconStatusChange size={20} />
              </ActionIcon>
            </Menu.Target>

            <Menu.Dropdown>
              <Menu.Item
                leftSection={<IconX />}
                disabled={row.original.tblPriceListModel.status === 0}
                onClick={() => handleEditStatus(row.id, 0)}
              >
                Chưa duyệt
              </Menu.Item>
              <Menu.Item
                leftSection={<IconCheck />}
                disabled={
                  row.original.tblPriceListModel.status === 1 ||
                  row.original.tblPriceListModel.isDrap !== 1
                }
                onClick={() => handleEditStatus(row.id, 1)}
              >
                Đồng ý duyệt
              </Menu.Item>
              <Menu.Item
                leftSection={<IconIdOff />}
                disabled={
                  row.original.tblPriceListModel.status === 2 ||
                  row.original.tblPriceListModel.isDrap !== 1
                }
                onClick={() => handleEditStatus(row.id, 2)}
              >
                Từ chối duyệt
              </Menu.Item>
            </Menu.Dropdown>
          </Menu>
        </Tooltip>

        <Tooltip
          label={
            row.original.tblPriceListModel.isDrap === 1
              ? "Bản nháp"
              : "Đã xác nhận"
          }
        >
          <ActionIcon
            variant="light"
            aria-label="Settings"
            color={
              row.original.tblPriceListModel.isDrap === 1 ? "violet" : "green"
            }
            onClick={() =>
              handleEditIsDrap(row?.id, row.original.tblPriceListModel.isDrap)
            }
          >
            {row.original.tblPriceListModel.isDrap === 1 ? (
              <IconNotes size={20} />
            ) : (
              <IconChecklist size={20} />
            )}
          </ActionIcon>
        </Tooltip>
        <Tooltip label="Xóa báo giá">
          <ActionIcon variant="light" aria-label="Settings" color="red">
            <IconTrash
              size={20}
              onClick={() => handleDeletePriceList([row?.id])}
            />
          </ActionIcon>
        </Tooltip>
      </>
    );
  }

  const handleChangeSearchValue = (value: string, key: string) => {
    setSearch((prevData) => ({ ...prevData, [key]: value }));
  };

  const handleEditStatus = async (id: number | string, status: number) => {
    const dataEdit = { id: id, status: status };

    await repositoryPos.post("/api/v1/TblPriceList/update-approve", dataEdit);
    fetchData();
  };

  const handleEditIsDrap = async (id: number | string, isDrap: number) => {
    const dataEdit = { id: id, drap: isDrap ? 0 : 1 };

    await repositoryPos.post("/api/v1/TblPriceList/update-draft", dataEdit);
    fetchData();
  };

  const handleAddPriceList = (
    id?: number | string,
    isDetail?: boolean | string | null,
    isEdit?: boolean | string | null
  ) => {
    navigate("/config-system/list-details-price", {
      state: { id: id, isDetailProp: isDetail, isEditProp: isEdit },
    });
  };

  const handleDeletePriceList = (ids: (number | string)[]) => {
    modals.openConfirmModal({
      title: (
        <>
          <Title order={5}>Xóa bảng giá</Title>
        </>
      ),
      children: <DeleteView onSearch={() => fetchData()} idItems={ids} />,
      confirmProps: { display: "none" },
      cancelProps: { display: "none" },
    });
  };

  function getNamesFromIds(idsString: string, form: "row" | "col") {
    // Kiểm tra nếu dataWarehouse chưa được tải
    if (!dataWarehouseLoaded) {
      return "";
    }

    // Tách chuỗi idsString thành mảng các id, loại bỏ khoảng trắng thừa
    const idsArray = idsString?.split(",")?.map((id) => id.trim());
    // Tìm các phần tử trong dataWarehouse có idBranch trùng với các id trong idsArray
    const names = idsArray
      ?.map((id) => {
        const foundItem = dataWarehouse.find(
          (item) => item.idBranch === parseInt(id)
        );
        return foundItem ? foundItem.name : null;
      })
      .filter((name) => name !== null); // Loại bỏ các giá trị null

    // Ghép các tên thành chuỗi và trả về
    if (form === "row") {
      return names?.join(", ");
    } else if (form === "col") {
      return names?.join("<br>");
    } else {
      throw new Error('Invalid form parameter. Use "row" or "col".');
    }
  }

  useHotkeys([
    // ['mod+J', () => console.log('Toggle color scheme')],
    // ['ctrl+F5', () => { openNew() }],
    // ['F5', () => { openNew() }],
    // ['alt+mod+shift+X', () => console.log('Rick roll')],
    [
      "F11",
      () => {
        handleAddPriceList();
      },
    ],
  ]);

  const fetchData = async () => {
    setIsLoading(true);
    setIsRefetching(true);
    setOpenedMenu(false);
    setData([]);
    setRowCount(0);

    let url = `?Skip=${pagination?.pageIndex * pagination?.pageSize}&Take=${
      pagination.pageSize
    }`;

    if (search.keySearch) {
      url += `&KeySearch=${search.keySearch}`;
    }
    if (search.priceListNum) {
      url += `&PriceListNum=${search.priceListNum}`;
    }
    if (search.listIdBranch) {
      url += `&ListIdBranch=${search.listIdBranch}`;
    }
    if (search.status) {
      url += `&Status=${search.status}`;
    }
    if (search.isDrap) {
      url += `&IsDrap=${search.isDrap}`;
    }
    if (search.activeDate && search.activeDate !== "Invalid date") {
      url += `&ActiveDate=${search.activeDate}`;
      console.log(search.activeDate);
    }
    if (search.inactiveDate && search.inactiveDate !== "Invalid date") {
      url += `&InactiveDate=${search.inactiveDate}`;
    }

    if (sorting && sorting.length > 0) {
      let _q = sorting[0];
      if (_q) {
        url = url + "&sort=" + _q.id + "&isOrder=" + _q.desc;
      }
    }

    try {
      let callapi = await repositoryPos.get(
        `/api/v1/TblPriceList/get-list${url}`
      );
      if (isNullOrUndefined(callapi) || isNullOrUndefinedArry(callapi?.data)) {
        setRowCount(0);
        setData([]);
      } else {
        setData(callapi?.data ?? []);

        setRowCount(callapi?.totalCount ?? 0);
        table.resetRowSelection();
      }
      return callapi?.data;
    } catch (error: any) {
      setIsError(true);
      console.error(error);
      return null;
    } finally {
      setIsLoading(false);
      setIsRefetching(false);
      setOpenedMenu(true);
    }
  };

  const fetchDataWarehouse = async () => {
    setIsLoading(true);
    setIsRefetching(true);
    try {
      const response = await repositoryDelivery.get(
        `api/v1/TblDmInventory/get-list?Skip=0&Take=1000`
      );
      if (response && response.success) {
        setDataWarehouse(response.data);
        setDataWarehouseLoaded(true);
      }
    } catch (error) {
      setDataWarehouse([]);
      setIsError(true);
      console.error(error);
      return;
    }
    setIsError(false);
    setIsLoading(false);
    setIsRefetching(false);
  };

  useEffect(() => {
    fetchDataWarehouse();
  }, []);

  useEffect(() => {
    if (dataWarehouseLoaded === true) {
      fetchData();
    }
  }, [dataWarehouseLoaded, pagination, sorting]);

  useEffect(() => {
    const fetchDataBranch = async () => {
      const response = await repositoryPos.get<MessageResponse<any>>(
        "/api/v1/TblDmInventory/get-select-branch"
      );

      setDataBranch(response?.data);
    };
    fetchDataBranch();
  }, []);

  useEffect(() => {
    const valuesList = Object.keys(rowSelection);
    setSelectIds(valuesList);
    if (valuesList.length < 1) setSelectIds([]);
    else {
      const valuesList = Object.keys(rowSelection);
      setSelectIds(valuesList);
    }
  }, [rowSelection]);

  const table = useMantineReactTable({
    columns,
    data,
    positionToolbarAlertBanner: "bottom",
    renderTopToolbarCustomActions: ({ table }) => (
      <div ref={headerRef} style={{ width: "100%" }}>
        <Flex justify={"space-between"} wrap={"wrap"}>
          <_breadcrumb></_breadcrumb>
          <Flex
            gap="md"
            justify={{ sm: "flex-start", lg: "flex-end" }}
            align="center"
            direction="row"
            wrap="wrap"
          >
            <Button
              leftSection={<IconPlus size={14} />}
              color="blue"
              variant="outline"
              onClick={() => handleAddPriceList()}
            >
              Thêm bảng giá (F11)
            </Button>

            <Button
              leftSection={<IconTrash size={14} />}
              color="red"
              variant="outline"
              onClick={() => {
                handleDeletePriceList(selectIds);
              }}
              disabled={selectIds.length < 1}
            >
              Xóa (đã chọn)
            </Button>

            <Menu shadow="md" width={200}>
              <Menu.Target>
                <Button
                  // size="xs"
                  rightSection={
                    <IconCaretDown
                      style={{ width: rem(14), height: rem(14) }}
                    />
                  }
                >
                  Chức năng
                </Button>
              </Menu.Target>

              <Menu.Dropdown>
                <Menu.Label>Application</Menu.Label>
                <Menu.Item
                  leftSection={
                    <IconFileExport
                      style={{ width: rem(14), height: rem(14) }}
                    />
                  }
                  onClick={handleExport}
                >
                  Export Excel
                </Menu.Item>
                <Tooltip label={"Chức năng đang update"}>
                  <Menu.Item
                    leftSection={
                      <IconFileUpload
                        style={{ width: rem(14), height: rem(14) }}
                      />
                    }
                  >
                    Import Excel
                  </Menu.Item>
                </Tooltip>
              </Menu.Dropdown>
            </Menu>
          </Flex>
        </Flex>
        <Grid mt={10}>
          <Grid.Col span={{ base: 12, md: 6, lg: 1.75 }}>
            <Input
              flex={1}
              placeholder="Số bảng giá"
              onChange={(e) => {
                handleChangeSearchValue(e.target.value ?? "", "priceListNum");
              }}
              leftSection={<IconNumber color="#15aabf" />}
            />
          </Grid.Col>
          <Grid.Col span={{ base: 12, md: 6, lg: 3.5 }}>
            <DatePickerInput
              // type="multiple"
              type="range"
              size="sm"
              allowSingleDateInRange
              placeholder="Chọn khoảng thời gian hết hạn"
              leftSection={<IconTimeDuration15 color="#15aabf" />}
              locale="vi"
              // value={value}
              valueFormat="DD/MM/YYYY"
              onChange={(e) => {
                handleChangeSearchValue(
                  e[0] ? e[0].toISOString() : "",
                  "activeDate"
                );
                handleChangeSearchValue(
                  e[1] ? e[1].toISOString() : "",
                  "inactiveDate"
                );
              }}
              clearable
            />
          </Grid.Col>
          <Grid.Col span={{ base: 12, md: 6, lg: 1.75 }}>
            <Select
              placeholder="Trung tâm"
              clearable
              data={dataBranch?.map((branch) => ({
                value: branch.value,
                label: branch.text,
              }))}
              onChange={(e) => {
                handleChangeSearchValue(e ?? "", "listIdBranch");
              }}
              leftSection={<IconBuildingCommunity color="#15aabf" />}
            />
          </Grid.Col>
          <Grid.Col span={{ base: 12, md: 6, lg: 1.75 }}>
            <Select
              placeholder="Trạng thái duyệt"
              clearable
              data={[
                {
                  value: "0",
                  label: "Chưa duyệt",
                },
                {
                  value: "1",
                  label: "Đồng ý duyệt",
                },
                {
                  value: "2",
                  label: "Từ chối duyệt",
                },
              ]}
              onChange={(e) => {
                handleChangeSearchValue(e ?? "", "status");
              }}
              leftSection={<IconStatusChange color="#15aabf" />}
            />
          </Grid.Col>
          <Grid.Col span={{ base: 12, md: 6, lg: 1.75 }}>
            <Select
              placeholder="Trạng thái bảng giá"
              clearable
              data={[
                {
                  value: "0",
                  label: "Không xác nhận",
                },
                {
                  value: "1",
                  label: "Đã xác nhận",
                },
              ]}
              onChange={(e) => {
                handleChangeSearchValue(e ?? "", "isDrap");
              }}
              leftSection={<IconReportAnalytics color="#15aabf" />}
            />
          </Grid.Col>
          <Grid.Col span={{ base: 12, md: 6, lg: 1.5 }}>
            <Button
              leftSection={<IconSearch size={14} />}
              color="blue"
              variant="outline"
              onClick={async () => {
                await fetchData();
              }}
              w={"100%"}
            >
              Lọc bảng giá
            </Button>
          </Grid.Col>
        </Grid>
      </div>
    ),
    renderToolbarInternalActions: ({ table }) => <></>,
    mantineTopToolbarProps: {
      style: {
        borderBottom: "3px solid rgba(128, 128, 128, 0.5)",
        marginBottom: 5,
      },
    },
    localization: _localization,
    enableRowSelection: true,
    getRowId: (row) => row?.tblPriceListModel?.id?.toString(),
    initialState: {
      showColumnFilters: false,
      columnPinning: {
        left: ["mrt-row-select", "code"],
        right: ["action"],
      },
      columnVisibility: { id: false },
      density: "xs",
    },
    mantineTableContainerProps: {
      style: { maxHeight: height, minHeight: height },
    },
    enableStickyHeader: true,
    onRowSelectionChange: setRowSelection,
    manualFiltering: true,
    manualPagination: true,
    manualSorting: true,
    rowCount,
    // onColumnFiltersChange: setColumnFilters,
    // onGlobalFilterChange: setGlobalFilter,
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    mantineTableBodyCellProps: ({ row }) => ({
      style: {
        //  fontWeight: row.getIsSelected() ? "bold" : "normal", //conditional styling
        // fontWeight: "bold",
        fontSize: "11.5px",
        padding: "4px 12px",
      },
    }),
    state: {
      // columnFilters,
      // globalFilter,
      isLoading,
      pagination,
      showAlertBanner: isError,
      // showProgressBars: isRefetching,
      // showSkeletons: isLoading, //loading for the first time with no data
      sorting,
      rowSelection,
    },
    mantineToolbarAlertBannerProps: false
      ? { color: "red", children: "Lỗi tải dữ liệu !" }
      : undefined,
    mantinePaginationProps: {
      showRowsPerPage: true,
      withEdges: true,
      rowsPerPageOptions: ["10", "50", "100"],
    },
    paginationDisplayMode: "pages",
    enableColumnPinning: true,
    mantineTableProps: {
      striped: false,
    },
    mantineTableBodyRowProps: ({ row }) => ({
      onClick: row.getToggleSelectedHandler(),
      sx: { cursor: "pointer" },
    }),
  });

  useEffect(() => {
    const headerHeight = headerRef.current?.offsetHeight || 0;
    const handleResize = () => {
      // 160 là chiều cao của phần phân trang
      // headerHeight là chiều cao của phần header
      setHeight(window.innerHeight - (165 + headerHeight));
    };

    handleResize(); // Set initial height
    window.addEventListener("resize", handleResize); // Update height on window resize

    return () => {
      window.removeEventListener("resize", handleResize); // Clean up event listener
    };
  }, []);

  return (
    <>
      <div className="mt-5">
        <MantineReactTable table={table} />
      </div>
    </>
  );
};

export default ListPrice;
