import { Box, Button, Input, Select } from "@mantine/core";
import {
  IconBrandAdobe,
  IconBuildingWarehouse,
  IconPackage,
  IconSearch,
} from "@tabler/icons-react";
import {
  MRT_ColumnDef,
  MRT_RowSelectionState,
  MantineReactTable,
  useMantineReactTable,
} from "mantine-react-table";
import React, { useEffect, useState } from "react";
import { repositoryDelivery } from "../../../_base/_const/_constVar";
import _breadcrumb from "../../../_base/component/_layout/_breadcrumb";
import { paginationBase } from "../../../_base/model/_base/BaseTable";
import { _localization } from "../../../config/location";
const InventoryReportList = () => {
  const headerRef = React.useRef<HTMLDivElement>(null);
  const [isRefetching, setIsRefetching] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [pagination, setPagination] = useState(paginationBase);
  const [height, setHeight] = useState(0);
  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});
  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  const [data, setData] = useState<Department[]>([]);
  const [deleteViewStatus, setDeleteViewStatus] = useState(false);
  const [dataBrandCode, setDataBranchCode] = useState([]);
  const [dataINvCode, setDataInvCode] = useState([]);
  const [dataItemCode, setDataItemCode] = useState([]);
  const [rowCount, setRowCount] = useState(0);
  const [search, setSearch] = useState({
    keySearch: "",
    branchCode: "",
    invCode: "",
    itemCode: "",
  });
  const handleChangeSearchValue = (value: string | null, key: string) => {
    setSearch((prevData) => ({
      ...prevData,
      [key]: value ? value : 0,
    }));
  };

  const columns = React.useMemo<MRT_ColumnDef<any>[]>(
    () => [
      // {
      //   accessorKey: "idBranch",
      //   header: "Id chi nhánh",
      //   size: 50,
      // },
      {
        accessorKey: "branchCode",
        header: "Mã chi nhánh",
        size: 30,
      },
      {
        accessorKey: "branchName",
        header: "Tên chi nhánh",
        size: 30,
      },

      // {
      //   accessorKey: "idKho",
      //   header: "Id kho",
      //   size: 30,
      // },
      {
        accessorKey: "maKho",
        header: "Mã kho",
        size: 30,
      },
      {
        accessorKey: "tenKho",
        header: "Tên kho",
        size: 30,
      },
      {
        accessorKey: "maSp",
        header: "Mã sản phẩm",
        size: 30,
      },
      {
        accessorKey: "tenSp",
        header: "Tên sản phăm",
        size: 30,
      },
      {
        accessorKey: "dvt",
        header: "Đơn vị tính",
        size: 30,
      },
      {
        accessorKey: "countSoLuong",
        header: "Tổng số lượng",
        size: 30,
      },
    ],
    []
  );

  const fetchData = async () => {
    setIsLoading(true);
    setIsRefetching(true);
    setIsError(false);

    let url = `?Skip=${pagination?.pageIndex * pagination?.pageSize}&Take=${
      pagination.pageSize
    }`;
    if (search.branchCode) {
      url += `&BranchCode=${search.branchCode.trim().toLowerCase()}`;
    }
    if (search.invCode) {
      url += `&InvCode=${search.invCode.trim().toLowerCase()}`;
    }
    if (search.itemCode) {
      url += `&ItemCode=${search.itemCode.trim().toLowerCase()}`;
    }
    if (search.keySearch) {
      url += `&KeySearch=${search.keySearch.trim().toLowerCase()}`;
    }
    try {
      const response = await repositoryDelivery.get(
        `/api/v1/TblInventorySerialOnhand/get-list${url}`
      );
      if (response && response.success) {
        let result = response.data;
        setData(result);
        setRowCount(result.length);
        table.resetRowSelection();
      }
    } catch (error) {
      setData([]);
      setIsError(true);
      console.error(error);
      return;
    } finally {
      setIsLoading(false);
      setIsRefetching(false);
    }
  };
  useEffect(() => {
    fetchData();
  }, [deleteViewStatus]);

  const DataBranchCode = async () => {
    try {
      const response = await repositoryDelivery.get(
        `api/v1/TblInventorySerialOnhand/get-all-BranchCode`
      );
      if (response && response.success) {
        setDataBranchCode(response.data);
      }
    } catch (error) {
      console.error(error);
      return;
    }
  };
  const DataInvCode = async () => {
    try {
      const response = await repositoryDelivery.get(
        `api/v1/TblInventorySerialOnhand/get-all-InvCode`
      );
      if (response && response.success) {
        setDataInvCode(response.data);
      }
    } catch (error) {
      console.error(error);
      return;
    }
  };
  const DataItemCode = async () => {
    try {
      const response = await repositoryDelivery.get(
        `api/v1/TblInventorySerialOnhand/get-all-ItemCode`
      );
      if (response && response.success) {
        setDataItemCode(response.data);
      }
    } catch (error) {
      console.error(error);
      return;
    }
  };

  useEffect(() => {
    const fetchDataAll = async () => {
      try {
        await Promise.all([DataBranchCode(), DataInvCode(), DataItemCode()]);
      } catch (error) {
        console.error("Error fetching data: ", error);
      }
    };

    fetchDataAll();
  }, []);

  const table = useMantineReactTable({
    columns,
    data,
    enableColumnFilters: true,
    enableSorting: true,
    enableColumnActions: true,
    state: {
      // columnFilters,
      // globalFilter,
      isLoading,
      pagination,
      showAlertBanner: isError,
      showProgressBars: isRefetching,
      showSkeletons: isLoading,
      //sorting,
      rowSelection,
    },
    manualFiltering: false,
    // manualPagination: true,
    manualSorting: false,
    onPaginationChange: setPagination,
    positionToolbarAlertBanner: "bottom",
    renderTopToolbarCustomActions: ({ table }) => (
      <div ref={headerRef}>
        <_breadcrumb></_breadcrumb>
        <Box style={{ display: "flex", alignItems: "center", gap: "10px" }}>
          <Input
            style={{ flex: 1, maxWidth: "180px" }}
            placeholder="Nhập tìm kiếm"
            type="text"
            leftSection={<IconSearch size={"20"} />}
            onChange={(e) => {
              handleChangeSearchValue(e.target.value ?? "", "keySearch");
            }}
          />
          <Select
            size="sm"
            placeholder="Mã chi nhánh"
            searchable
            clearable
            data={dataBrandCode}
            style={{ flex: 1, maxWidth: "180px" }}
            leftSection={<IconBrandAdobe size={20} />}
            onChange={(value) => handleChangeSearchValue(value, "branchCode")}
          />
          <Select
            size="sm"
            placeholder="Mã kho"
            searchable
            clearable
            data={dataINvCode}
            style={{ flex: 1, maxWidth: "180px" }}
            leftSection={<IconBuildingWarehouse size={20} />}
            onChange={(value) => handleChangeSearchValue(value, "invCode")}
          />
          <Select
            size="sm"
            placeholder="Mã sản phẩm"
            searchable
            clearable
            data={dataItemCode}
            style={{ flex: 1, maxWidth: "180px" }}
            leftSection={<IconPackage size={20} />}
            onChange={(value) => handleChangeSearchValue(value, "itemCode")}
          />
          <Button
            color="blue"
            variant="outline"
            onClick={async () => {
              await fetchData();
            }}
          >
            Tìm kiếm
          </Button>
        </Box>
      </div>
    ),
    renderToolbarInternalActions: ({ table }) => <></>,

    mantineTopToolbarProps: {
      style: {
        borderBottom: "3px solid rgba(128, 128, 128, 0.5)",
        marginBottom: 5,
      },
    },
    localization: _localization,
    getRowId: (row) => row.departmentId,
    initialState: {
      showColumnFilters: false,
      columnPinning: {
        left: ["mrt-row-select", "code"],
        right: ["action"],
      },
      density: "xs",
    },
    mantineTableContainerProps: {
      style: { maxHeight: height, minHeight: height },
    },
    enableStickyHeader: true,
    onRowSelectionChange: setRowSelection,
    rowCount,
    mantineTableBodyCellProps: ({ row }) => ({
      style: {
        fontSize: "11.5px",
        padding: "4px 12px",
      },
    }),
    mantineToolbarAlertBannerProps: false
      ? { color: "red", children: "Lỗi tải dữ liệu !" }
      : undefined,
    mantinePaginationProps: {
      showRowsPerPage: true,
      withEdges: true,
      rowsPerPageOptions: ["10", "50", "100"],
    },
    paginationDisplayMode: "pages",
    enableColumnPinning: true,
    mantineTableProps: {
      striped: false,
    },
    mantineTableBodyRowProps: ({ row }) => ({
      onClick: row.getToggleSelectedHandler(),
      sx: { cursor: "pointer" },
    }),
  });

  useEffect(() => {
    const valuesList = Object.keys(rowSelection);
    if (valuesList.length < 1) setSelectedIds([]);
    else setSelectedIds(valuesList);
  }, [rowSelection]);

  useEffect(() => {
    const headerHeight = headerRef.current?.offsetHeight || 0;
    const handleResize = () => {
      setHeight(window.innerHeight - (200 + headerHeight));
    };

    handleResize(); // Set initial height
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      <div className="mt-5">
        <MantineReactTable table={table} />
      </div>
    </>
  );
};

export default InventoryReportList;
type Department = {
  departmentId: number;
  name: string;
  description: string | null;
  active: boolean;
  deparmentCode: string;
  creationDate: string | null;
  createdBy: string | null;
  lastUpdateDate: string | null;
  lastUpdatedBy: string | null;
  address: string;
  branchId: number;
};
