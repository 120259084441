import React, { useEffect, useState } from "react";
import { TblDMUserLogin } from "../../../../model/TblDMUserLogin";
import { paginationBase } from "../../../../_base/model/_base/BaseTable";
import {
  MantineReactTable,
  MRT_ColumnDef,
  MRT_RowSelectionState,
  useMantineReactTable,
} from "mantine-react-table";
import {
  ActionIcon,
  Badge,
  Box,
  Button,
  Flex,
  Input,
  Menu,
  rem,
  Text,
  Title,
  Tooltip,
} from "@mantine/core";
import {
  IconCaretDown,
  IconFileExport,
  IconHandStop,
  IconLogout,
  IconSearch,
} from "@tabler/icons-react";
import * as xlsx from "xlsx";
import { toast } from "react-toastify";
import { _localization } from "../../../../config/location";
import { NotificationExtension } from "../../../../_base/extension/NotificationExtension";
import { modals } from "@mantine/modals";
import { LogoutUserView } from "./LogoutUserView";
import { BanUserView } from "./BanUserView";
import { repositoryMaster } from "../../../../_base/_const/_constVar";
import { green } from "../../../../const/variables";

export const LoginHistory = () => {
  //column definitions
  const columns = React.useMemo<MRT_ColumnDef<TblDMUserLogin>[]>(
    () => [
      {
        accessorKey: "username",
        header: "Tên đăng nhập",
        Cell: ({ renderedCellValue }: any) => (
          <Text fw={700} size="sm">
            {renderedCellValue}
          </Text>
        ),
      },
      {
        accessorKey: "ip",
        header: "Địa chỉ IP",
      },
      {
        accessorKey: "login",
        header: "Hành động",
        Cell: ({ row }) => (
          <Badge color={row.original.login === true ? "green" : "red"}>
            {row.original.login === true ? "Đăng nhập" : "Đăng xuất"}
          </Badge>
        ),
      },
      {
        accessorKey: "dateaction",
        header: "Thời gian đăng nhập/ đăng xuất",
        Cell: ({ row }: any) => (
          <Text size="sm">{formatDate(row.original.dateaction)}</Text>
        ),
      },
      {
        accessorKey: "lastdate",
        header: "Trạng thái",
        Cell: ({ row }) =>
          calculateUserStatus(row.original.lastdate, !row.original.login),
      },

      {
        accessorKey: "location",
        header: "Vị trí",
      },
      {
        accessorKey: "os",
        header: "Hệ điều hành",
      },
      {
        accessorKey: "browser",
        header: "Trình duyệt",
      },
      {
        accessorKey: "action",
        header: "Thao tác",
        enableSorting: false,
        enableColumnActions: false,
        enableColumnFilter: false,
        size: 30,
        Cell: ({ renderedCellValue, row, table }) => (
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              gap: "10px",
            }}
          >
            <Tooltip label="Đăng xuất">
              <ActionIcon
                variant="light"
                aria-label="Settings"
                disabled={row.original.login !== true}
                color="yellow"
                onClick={async () => {
                  await logoutUsers([row.original.id]);
                }}
              >
                <IconLogout size={20} />
              </ActionIcon>
            </Tooltip>
            <Tooltip label="Cấm">
              <ActionIcon
                variant="light"
                aria-label="Settings"
                color="red"
                onClick={async () => {
                  await banUsers([row.original.id]);
                }}
              >
                <IconHandStop size={20} />
              </ActionIcon>
            </Tooltip>
          </Box>
        ),
      },
    ],
    []
  );
  //data and fetching state
  const headerRef = React.useRef<HTMLDivElement>(null);
  const [data, setData] = useState<TblDMUserLogin[]>([]);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);
  const [rowCount, setRowCount] = useState(0);
  const [height, setHeight] = useState(0);
  //table state
  const [pagination, setPagination] = useState(paginationBase);
  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});
  const [keySearch, setKeySearch] = useState<string>("");
  const [selectIds, setSelectIds] = useState<string[]>([]);
  const [deleteViewStatus, setDeleteViewStatus] = useState(false);

  //function
  function formatDate(isoDateString: string, add?: boolean): string {
    const date: Date = new Date(isoDateString);

    // Cộng thêm 7 tiếng
    if (add) date.setHours(date.getHours() + 7);
    // Lấy các thành phần ngày giờ sau khi cộng thêm số giờ
    const hours: string = date.getHours().toString().padStart(2, "0");
    const minutes: string = date.getMinutes().toString().padStart(2, "0");
    const seconds: string = date.getSeconds().toString().padStart(2, "0");
    const day: string = date.getDate().toString().padStart(2, "0");
    const month: string = (date.getMonth() + 1).toString().padStart(2, "0"); // Tháng bắt đầu từ 0
    const year: number = date.getFullYear();

    // Định dạng thành chuỗi "giờ:phút:giây ngày-tháng-năm"
    return `${hours}:${minutes}:${seconds} ${day}-${month}-${year}`;
  }
  const handleExport = () => {
    try {
      const worksheet = xlsx.utils.json_to_sheet(data);
      const workbook = xlsx.utils.book_new();
      xlsx.utils.book_append_sheet(workbook, worksheet, "Data");
      xlsx.writeFile(workbook, "danh-sach-danh-muc-trung-tam.xlsx");
      toast.success("Export excel thành công", { autoClose: 1500 });
    } catch (error) {
      toast.error("Export excel thất bại", { autoClose: 1500 });
    }
  };

  const convertMsToTime = (time: number) => {
    let remainingMs = time;
    const years = Math.floor(remainingMs / (365 * 24 * 60 * 60 * 1000));
    remainingMs %= 365 * 24 * 60 * 60 * 1000;

    const days = Math.floor(remainingMs / (24 * 60 * 60 * 1000));
    remainingMs %= 24 * 60 * 60 * 1000;

    const hours = Math.floor(remainingMs / (60 * 60 * 1000));
    remainingMs %= 60 * 60 * 1000;

    const minutes = Math.floor(remainingMs / (60 * 1000));
    remainingMs %= 60 * 1000;

    const seconds = Math.floor(remainingMs / 1000);

    let content = "";
    if (years > 0) content = `${years} năm trước`;
    else if (days > 30) content = `${Math.floor(days / 30)} tháng trước`;
    else if (days > 0) content = `${days} ngày trước`;
    else if (hours > 0) content = `${hours} giờ trước`;
    else if (minutes > 0) content = `${minutes} phút trước.`;
    else content = `${seconds} giây trước`;

    return <Text color="gray">{`Cập nhật lần cuối ${content}`}</Text>;
  };

  const calculateUserStatus = (date: string | Date, isLogout: boolean) => {
    const inputDate = new Date(date);
    const now = Date.now();

    if (isNaN(inputDate.getTime())) {
      return <Text color="red">Invalid date</Text>; // Handle invalid dates
    }

    const diffInMs = Math.abs(inputDate.getTime() - now);

    // Kiểm tra chênh lệch có lớn hơn 15 phút hay không
    const lifeTime = 15 * 60 * 1000;

    if (diffInMs > lifeTime || isLogout) {
      return convertMsToTime(diffInMs);
    }

    return (
      <Badge radius="sm" variant="dot" size="lg" color={green.base}>
        Online
      </Badge>
    );
  };

  const fetchData = async () => {
    setIsRefetching(true);
    setIsError(false);
    try {
      let url = `?Skip=${pagination.pageIndex * pagination.pageSize}&Take=${
        pagination.pageSize
      }`;
      if (keySearch) url += `&KeySearch=${keySearch}`;
      const response = await repositoryMaster.get(
        "/api/v1/Auth/get-user-login" + url
      );

      if (response && response.success) {
        let result = response.data;
        setData(result);
        setRowCount(response.totalCount);
        setSelectIds([]);
        table.resetRowSelection();
      }
    } catch (error) {
      setData([]);
      setIsError(true);
      console.error(error);
      return;
    } finally {
      setIsLoading(false);
      setIsRefetching(false);
    }
  };

  async function logoutUsers(ids: string[] | number[]) {
    if (ids.length < 1) NotificationExtension.Warn("Bạn chưa chọn dữ liệu !");
    else
      modals.openConfirmModal({
        title: (
          <>
            <Title order={5}>{"Đăng xuất người dùng!"}</Title>
          </>
        ),
        size: "550px",
        children: <LogoutUserView ids={ids} onClose={setDeleteViewStatus} />,
        confirmProps: { display: "none" },
        cancelProps: { display: "none" },
      });
  }

  async function banUsers(ids: string[] | number[]) {
    if (ids.length < 1) NotificationExtension.Warn("Bạn chưa chọn dữ liệu !");
    else
      modals.openConfirmModal({
        title: (
          <>
            <Title order={5}>{"Cấm người dùng!"}</Title>
          </>
        ),
        size: "550px",
        children: <BanUserView ids={ids} onClose={setDeleteViewStatus} />,
        confirmProps: { display: "none" },
        cancelProps: { display: "none" },
      });
  }

  //useEffect
  useEffect(() => {
    const valuesList = Object.keys(rowSelection);
    setSelectIds(valuesList);
    if (valuesList.length < 1) setSelectIds([]);
    else {
      const valuesList = Object.keys(rowSelection);
      setSelectIds(valuesList);
    }
  }, [rowSelection]);

  useEffect(() => {
    fetchData();
  }, [pagination.pageIndex, pagination.pageSize, deleteViewStatus]);

  useEffect(() => {
    const headerHeight = headerRef.current?.offsetHeight || 0;
    const handleResize = () => {
      // 160 là chiều cao của phần phân trang
      // headerHeight là chiều cao của phần header
      setHeight(window.innerHeight - (272 + headerHeight));
    };

    handleResize(); // Set initial height
    window.addEventListener("resize", handleResize); // Update height on window resize

    return () => {
      window.removeEventListener("resize", handleResize); // Clean up event listener
    };
  }, []);

  const table = useMantineReactTable({
    columns,
    data,
    positionToolbarAlertBanner: "bottom",
    //add custom action buttons to top-left of top toolbar
    renderTopToolbarCustomActions: ({ table }) => (
      <div ref={headerRef}>
        <Flex
          gap="md"
          justify="flex-start"
          align="center"
          direction="row"
          wrap="wrap"
        >
          <Input
            placeholder="Nhập từ khóa ..."
            value={keySearch}
            onChange={(e) => setKeySearch(e.target.value)}
          />
          <Button
            rightSection={<IconSearch size={18}></IconSearch>}
            variant="filled"
            color="blue"
            onClick={async () => {
              await fetchData();
            }}
          >
            Tìm
          </Button>
        </Flex>
      </div>
    ),
    renderToolbarInternalActions: ({ table }) => (
      <>
        <Flex
          gap="md"
          justify={{ sm: "flex-start", lg: "flex-end" }}
          align="center"
          direction="row"
          wrap="wrap"
        >
          <Button
            leftSection={<IconLogout size={14} />}
            color="yellow"
            variant="outline"
            onClick={async () => {
              logoutUsers(selectIds);
            }}
            disabled={selectIds.length < 1}
          >
            Đăng xuất (đã chọn)
          </Button>
          <Button
            leftSection={<IconHandStop size={14} />}
            color="red"
            variant="outline"
            onClick={async () => {
              await banUsers(selectIds);
            }}
            disabled={selectIds.length < 1}
          >
            Cấm (đã chọn)
          </Button>

          <Menu shadow="md" width={200}>
            <Menu.Target>
              <Button
                // size="xs"
                rightSection={
                  <IconCaretDown style={{ width: rem(14), height: rem(14) }} />
                }
              >
                Chức năng
              </Button>
            </Menu.Target>

            <Menu.Dropdown>
              <Menu.Label>Application</Menu.Label>
              <Menu.Item
                leftSection={
                  <IconFileExport style={{ width: rem(14), height: rem(14) }} />
                }
                onClick={handleExport}
              >
                Export Excel
              </Menu.Item>
            </Menu.Dropdown>
          </Menu>
        </Flex>
      </>
    ),

    mantineTopToolbarProps: {
      style: {
        borderBottom: "3px solid rgba(128, 128, 128, 0.5)",
        marginBottom: 5,
      },
    },
    localization: _localization,
    enableRowSelection: true,
    getRowId: (row) => row.id,
    initialState: {
      showColumnFilters: false,
      pagination,
      columnPinning: {
        left: ["mrt-row-select", "username"],
        right: ["action"],
      },
      columnVisibility: { id: false },
      density: "xs",
    },
    mantineTableContainerProps: {
      style: { maxHeight: height, minHeight: height },
    },
    enableStickyHeader: true,
    onRowSelectionChange: setRowSelection,
    // manualFiltering: true,
    manualPagination: true,
    // manualSorting: true,
    rowCount,
    // onColumnFiltersChange: setColumnFilters,
    // onGlobalFilterChange: setGlobalFilter,
    onPaginationChange: setPagination,
    // onSortingChange: setSorting,
    mantineTableBodyCellProps: ({ row }) => ({
      style: {
        //  fontWeight: row.getIsSelected() ? "bold" : "normal", //conditional styling
        fontWeight: "normal", //conditional styling
        fontSize: "12.5px",
        padding: "5px 15px",
      },
    }),
    state: {
      // columnFilters,
      // globalFilter,
      isLoading,
      pagination,
      showAlertBanner: isError,
      showProgressBars: isRefetching,
      showSkeletons: isLoading, //loading for the first time with no data
      //sorting,
      rowSelection,
    },
    mantineToolbarAlertBannerProps: isError
      ? { color: "red", children: "Lỗi tải dữ liệu !" }
      : undefined,
    mantinePaginationProps: {
      showRowsPerPage: true,
      withEdges: true,
      rowsPerPageOptions: ["10", "50", "100"],
    },
    paginationDisplayMode: "pages",
    enableColumnPinning: true,
    mantineTableProps: {
      striped: false,
    },
    columnFilterDisplayMode: "popover",
    mantineTableBodyRowProps: ({ row }) => ({
      onClick: row.getToggleSelectedHandler(),
      sx: { cursor: "pointer" },
    }),
  });

  return (
    <>
      <MantineReactTable table={table} />
    </>
  );
};
