import {
  ActionIcon,
  Badge,
  Box,
  Button,
  ComboboxItem,
  Flex,
  Grid,
  GridCol,
  Group,
  Input,
  Menu,
  Select,
  SelectProps,
  Title,
  Tooltip,
  rem,
} from "@mantine/core";
import { useHotkeys } from "@mantine/hooks";
import { modals } from "@mantine/modals";
import {
  IconCaretDown,
  IconCheck,
  IconEye,
  IconFileExport,
  IconFileUpload,
  IconHandStop,
  IconSearch,
  IconStatusChange,
  IconTimeDuration15,
  IconTrash,
} from "@tabler/icons-react";
import {
  MRT_ColumnDef,
  MRT_RowSelectionState,
  MRT_SortingState,
  MantineReactTable,
  useMantineReactTable,
} from "mantine-react-table";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import * as xlsx from "xlsx";
import {
  _timerDelayClick,
  repositoryDelivery,
  repositoryPos,
} from "../../_base/_const/_constVar";
import _breadcrumb from "../../_base/component/_layout/_breadcrumb";
import { formatDateTransfer } from "../../common/FormatDate/FormatDate";
import { _localization } from "../../config/location";
import { green, orange, red, sky_blue } from "../../const/variables";
import { TblTestingTechniqueHeaderGetList } from "../../model/TblTestingTechniqueHeader";
import ModalDetailTestingTechnique from "./ModalDetailTestingTechnique";
import ModalAssignTestingTechnique from "./ModalAssignTestingTechnique";
import { TblDmEmployeeModel } from "../../model/TblDmEmployeeModel";
import { MessageResponse } from "../../model/MessageResponse";
import { DatePickerInput } from "@mantine/dates";
import { getValueById } from "../../_base/helper/FunctionHelper";
import ListProductDetail from "./ListProductDetail";

function getColorStatus(text: any) {
  switch (text) {
    case "TAO_MOI":
      return sky_blue.base;
    case "PHAN_CONG":
      return orange.base;
    case "THANH_CONG":
      return green.light;
    case "THAT_BAI":
      return red.base;
    default:
      return sky_blue.base;
  }
}

function getStatus(text: any) {
  switch (text) {
    case "N":
      return "Tạo mới";
    case "A":
      return "Đã phân công";
    case "F":
      return "Hoàn thành";
    default:
      return "Tạo mới";
  }
}

const TestingTechnique = () => {
  const navigate = useNavigate();
  // Reference data from another table
  const [dataTblLocation, setDataTblLocation] = useState<ComboboxItem[]>([]);

  //data and fetching state
  const headerRef = React.useRef<HTMLDivElement>(null);
  const [data, setData] = useState<TblTestingTechniqueHeaderGetList[]>([]);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);
  const [rowCount, setRowCount] = useState(0);
  const [height, setHeight] = useState(0);
  const [dataEmployee, setDataEmployee] = useState<TblDmEmployeeModel[]>([]);
  const [deleteViewStatus, setDeleteViewStatus] = useState(false);

  //table state
  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});

  const [selectIds, setSelectIds] = useState<string[]>([]);
  const [pagination, setPagination] = useState({ pageIndex: 0, pageSize: 20 });
  const [sorting, setSorting] = useState<MRT_SortingState>([]);

  //count
  const [timeLeft, setTimeLeft] = useState(60);
  const [isCounting, setIsCounting] = useState(false);
  const dataStatusOption = [
    { value: "TAO_MOI", label: "Tạo mới" },
    { value: "PHAN_CONG", label: "Phân công" },
    { value: "THANH_CONG", label: "Thành công" },
    { value: "THAT_BAI", label: "Thất bại" },
  ];
  const dataSourceStatus = [
    { value: "DON_HANG_TAM", label: "Đơn hàng tạm" },
    { value: "DON_HANG_KHOA", label: "Đơn hàng khóa" },
    {
      value: "THANH_TOAN_CHUA_HOAN_THANH",
      label: "Thanh toán chưa hoàn thành",
    },
    {
      value: "pending",
      label: "Chưa giải quyết",
    },
    {
      value: "DON_HANG_BAN_TAI_SHOP",
      label: "Đơn hàng bán tại shop",
    },
    {
      value: "KHO_XUAT_HANG",
      label: "Kho xuất hàng",
    },
  ];

  const [search, setSearch] = useState({
    keySearch: "",
    fromDate: "",
    endDate: "",
    sourceType: "",
    status: "",
  });

  const columns = React.useMemo<
    MRT_ColumnDef<TblTestingTechniqueHeaderGetList>[]
  >(
    () => [
      {
        accessorKey: "code",
        header: "Mã đơn hàng",
        size: 30,
        Cell: ({ renderedCellValue, row }) => (
          <Tooltip label="Xem chi tiết">
            <Badge
              radius="sm"
              variant="dot"
              size="lg"
              style={{ cursor: "pointer" }}
              color={renderedCellValue === null ? "red" : green.base}
              onDoubleClick={() => detailData(row.original.id)}
            >
              {renderedCellValue === null ? null : renderedCellValue}
            </Badge>
          </Tooltip>
        ),
      },
      {
        accessorKey: "sourceStatus",
        header: "Trạng thái đơn hàng",
        size: 50,
        Cell: ({ row }) => {
          const orderType = row.original.sourceStatus;
          let badgeColor;
          switch (orderType) {
            case "DON_HANG_TAM":
              badgeColor = "cyan";
              break;
            case "DON_HANG_KHOA":
              badgeColor = "green";
              break;
            case "THANH_TOAN_CHUA_HOAN_THANH":
              badgeColor = "red";
              break;
            case "pending":
              badgeColor = "yellow";
              break;
            case "DON_HANG_BAN_TAI_SHOP":
              badgeColor = "green";
              break;
            case "KHO_XUAT_HANG":
              badgeColor = sky_blue.medium;
              break;
            default:
              badgeColor = "gray";
          }
          return (
            <Badge color={badgeColor} fs={"12,5px"} fw={"bold"} radius="sm">
              {row.original.sourceStatus !== null
                ? getValueById(
                    row.original.sourceStatus,
                    dataSourceStatus,
                    "label"
                  )
                : "Không xác định"}
            </Badge>
          );
        },
      },
      {
        accessorKey: "sourceCode",
        header: "Số chứng từ gốc",
        Cell: ({ renderedCellValue }) => (
          <Badge
            radius="sm"
            variant="dot"
            size="lg"
            color={renderedCellValue === null ? "red" : green.base}
          >
            {renderedCellValue === null ? "Không xác định" : renderedCellValue}
          </Badge>
        ),
      },
      {
        accessorKey: "createDate",
        header: "Ngày lập",
        Cell: ({ renderedCellValue }) => (
          <>
            {renderedCellValue &&
              formatDateTransfer(renderedCellValue as string)}
          </>
        ),
        size: 30,
      },

      {
        accessorKey: "orderDate",
        header: "Ngày lập đơn hàng",
        Cell: ({ renderedCellValue }) => (
          <>
            {renderedCellValue &&
              formatDateTransfer(renderedCellValue as string)}
          </>
        ),
        size: 30,
      },

      {
        accessorKey: "branchName",
        header: "Chi nhánh",
        Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
        size: 30,
      },
      //   {
      //     accessorKey: "createName",
      //     header: "Người lập",
      //     // Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
      //     size: 30,
      //   },

      {
        accessorKey: "customerName",
        header: "Tên khách hàng",
        size: 30,
      },
      {
        accessorKey: "customerTelephone",
        header: "SĐT",
        size: 30,
      },

      {
        accessorKey: "description",
        header: "Ghi chú",
      },
      {
        accessorKey: "testingStatus",
        header: "Trạng thái KT",
        size: 40,
        Cell: ({ row }) => (
          <>
            <Badge
              color={getColorStatus(row.original.testingStatus)}
              radius={"sm"}
            >
              {getValueById(
                row.original.testingStatus?.toString() ?? "",
                dataStatusOption,
                "label"
              )}
            </Badge>
          </>
        ),
      },

      {
        accessorKey: "action",
        header: "Thao tác",
        size: 50,
        Cell: ({ renderedCellValue, row, table }) => (
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              gap: "10px",
            }}
          >
            {/* <Tooltip label="Chỉnh sửa">
              <ActionIcon
                variant="light"
                aria-label="Settings"
                color="orange"
                onClick={async () => {
                  editData(row.original.id || 0);
                }}
              >
                <IconEdit size={20} stroke={1.5} />
              </ActionIcon>
            </Tooltip> */}

            <Tooltip label="Phân công">
              <ActionIcon
                variant="light"
                aria-label="Settings"
                color="violet"
                disabled={row.original.testingStatus !== "TAO_MOI"}
                onClick={async () => {
                  assignEmployee(row.original.id);
                }}
              >
                <IconHandStop size={20} stroke={1.5} />
              </ActionIcon>
            </Tooltip>

            <Tooltip label="Chi tiết">
              <ActionIcon
                variant="light"
                aria-label="Settings"
                color="cyan"
                onClick={async () => {
                  detailData(row.original.id);
                }}
              >
                <IconEye size={20} stroke={1.5} />
              </ActionIcon>
            </Tooltip>

            {/* <Tooltip label="Xóa">
                <ActionIcon
                  variant="light"
                  aria-label="Settings"
                  color="red"
                  onClick={async () => {
                    await deleteData([row.original.transactionId]);
                  }}
                >
                  <IconTrash size={20} stroke={1.5} />
                </ActionIcon>
              </Tooltip> */}
          </Box>
        ),
      },
    ],
    [dataTblLocation, dataEmployee, dataStatusOption, dataSourceStatus]
  );

  const startCountdown = () => {
    if (!isCounting) {
      setTimeLeft(_timerDelayClick);
      setIsCounting(true);
    }
  };

  const handleExport = () => {
    try {
      const worksheet = xlsx.utils.json_to_sheet(data);
      const workbook = xlsx.utils.book_new();
      xlsx.utils.book_append_sheet(workbook, worksheet, "Data");
      xlsx.writeFile(workbook, "danh-sach-ky-thuat-kiem-tra.xlsx");
      toast.success("Export excel thành công", { autoClose: 1500 });
    } catch (error) {
      toast.error("Export excel thất bại", { autoClose: 1500 });
    }
  };

  const handleChangeSearchValue = (value: string, key: string) => {
    setSearch((prevData) => ({ ...prevData, [key]: value }));
  };

  const optionTestingStatus: Record<
    "Tạo mới" | "Phân công" | "Thành công" | "Thất bại",
    string
  > = {
    "Tạo mới": "#3B9BDD",
    "Phân công": "#FD8622",
    "Thành công": "#52B155",
    "Thất bại": "#FA5757",
  };

  const renderSelectOptionTestingStatus: SelectProps["renderOption"] = ({
    option,
    checked,
  }) => (
    <Group flex="1" gap="xs">
      <Badge
        color={
          optionTestingStatus[
            option.label as "Tạo mới" | "Phân công" | "Thành công" | "Thất bại"
          ]
        }
        radius="sm"
        variant="filled"
      >
        {option.label}
      </Badge>
      {checked && (
        <IconCheck size={"14px"} style={{ marginInlineStart: "auto" }} />
      )}
    </Group>
  );

  const createData = () => {
    // navigate("/warehouse-config/create-warehouse-out-ward");
  };

  const editData = (id: string | number) => {
    // navigate("/warehouse-config/edit-warehouse-out-ward", {
    //   state: { id: id },
    // });
  };

  const detailData = (id: string | number) => {
    modals.openConfirmModal({
      title: (
        <>
          <Title order={5}>Chi tiết</Title>
        </>
      ),

      size: "auto",
      children: <ModalDetailTestingTechnique id={id} />,
      confirmProps: { display: "none" },
      cancelProps: { display: "none" },
    });
  };

  const assignEmployee = (id: string | number) => {
    setDeleteViewStatus(true);
    modals.openConfirmModal({
      title: (
        <>
          <Title order={5}>Phân công KTKT</Title>
        </>
      ),
      size: "auto",
      children: (
        <ModalAssignTestingTechnique id={id} onClose={setDeleteViewStatus} />
      ),
      confirmProps: { display: "none" },
      cancelProps: { display: "none" },
    });
  };

  useHotkeys([
    [
      "F11",
      () => {
        createData();
      },
    ],
  ]);

  const fetchData = async () => {
    setIsLoading(true);
    setIsRefetching(true);
    setIsError(false);

    let url = `?Skip=${pagination?.pageIndex * pagination?.pageSize}&Take=${
      pagination.pageSize
    }`;

    if (search.keySearch) {
      url += `&KeySearch=${search.keySearch}`;
    }
    if (search.fromDate) {
      url += `&FromDate=${search.fromDate}`;
    }
    if (search.endDate) {
      url += `&EndDate=${search.endDate}`;
    }
    if (search.status) {
      url += `&Status=${search.status}`;
    }
    if (search.sourceType) {
      url += `&SourceType=${search.sourceType}`;
    }

    let _softing = "";
    let isOrder = false;
    if (sorting && sorting.length > 0) {
      let _q = sorting[0];
      if (_q) {
        _softing = _q.id;
        isOrder = _q.desc;
      }
      url = url + "&sort=" + _softing + "&isOrder=" + isOrder;
    }

    try {
      const response = await repositoryDelivery.get(
        `/api/v1/TblTestingTechniqueHeader/get-list${url}`
      );
      if (response && response.success) {
        let result = response.data;
        setIsLoading(false);
        setData(result || []);
        // Promise.all([dataLocation()]);
        setRowCount(response.totalCount);
        setSelectIds([]);
        table.resetRowSelection();
      } else setData([]);
    } catch (error) {
      setData([]);
      setIsError(true);
      console.error(error);
      return;
    } finally {
      setIsLoading(false);
      setIsRefetching(false);
    }
    startCountdown();
  };

  const fetchEmployee = async () => {
    const url = `/api/v1/TblDmEmployee/get-all`;
    try {
      const searchResult = await repositoryPos.get<
        MessageResponse<TblDmEmployeeModel[]>
      >(url);
      if (searchResult?.data) {
        setDataEmployee(searchResult.data);
      } else {
        setDataEmployee([]);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setDataEmployee([]);
    }
  };

  useEffect(() => {
    fetchEmployee();

    const headerHeight = headerRef.current?.offsetHeight || 0;
    const handleResize = () => {
      // 190 là chiều cao của phần phân trang
      // headerHeight là chiều cao của phần header
      setHeight(window.innerHeight - (155 + headerHeight));
    };

    handleResize(); // Set initial height
    window.addEventListener("resize", handleResize); // Update height on window resize

    return () => {
      window.removeEventListener("resize", handleResize); // Clean up event listener
    };
  }, []);

  useEffect(() => {
    const valuesList = Object.keys(rowSelection);
    setSelectIds(valuesList);
    if (valuesList.length < 1) setSelectIds([]);
    else {
      const valuesList = Object.keys(rowSelection);
      setSelectIds(valuesList);
    }
  }, [rowSelection]);

  useEffect(() => {
    let timer: ReturnType<typeof setInterval>;
    if (isCounting && timeLeft > 0) {
      timer = setInterval(() => {
        setTimeLeft((prevTime) => prevTime - 1);
      }, 1000);
    } else if (timeLeft === 0) {
      setIsCounting(false);
    }
    return () => clearInterval(timer);
  }, [isCounting, timeLeft]);

  useEffect(() => {
    fetchData();
    // fetchDeliveryDepartment();
  }, [sorting, pagination.pageIndex, pagination.pageSize, deleteViewStatus]);

  const table = useMantineReactTable({
    columns,
    data,
    positionToolbarAlertBanner: "bottom",
    enableTopToolbar: false,

    renderDetailPanel: ({ row }) => (
      <ListProductDetail
        dataDetail={row.original.tblTestingTechniqueDetailModels}
      />
    ),

    mantineTopToolbarProps: {
      style: {
        borderBottom: "3px solid rgba(128, 128, 128, 0.5)",
        marginBottom: 5,
      },
    },
    localization: _localization,
    enableRowSelection: true,
    getRowId: (row) => row.id?.toString(),
    initialState: {
      showColumnFilters: false,
      columnPinning: {
        left: ["mrt-row-select", "mrt-row-expand", "code"],
        right: ["orderType", "testingStatus", "action"],
      },
      columnVisibility: { id: false },
      density: "xs",
    },
    mantineTableContainerProps: {
      style: { maxHeight: height, minHeight: height },
    },
    enableStickyHeader: true,
    onRowSelectionChange: setRowSelection,
    manualFiltering: false,
    manualPagination: true,
    manualSorting: true,
    rowCount,
    onSortingChange: setSorting,
    onPaginationChange: setPagination,
    mantineTableBodyCellProps: ({ row }) => ({
      style: {
        fontWeight: "500",
        fontSize: "12.5px",
        padding: "5px 12px",
      },
    }),
    state: {
      isLoading,
      pagination,
      showAlertBanner: isError,
      showProgressBars: isRefetching,
      showSkeletons: isLoading,
      rowSelection,
      sorting,
    },
    mantineToolbarAlertBannerProps: false
      ? { color: "red", children: "Lỗi tải dữ liệu !" }
      : undefined,
    mantinePaginationProps: {
      showRowsPerPage: true,
      withEdges: true,
      rowsPerPageOptions: ["20", "50", "100"],
    },
    paginationDisplayMode: "pages",
    enableColumnPinning: true,
    mantineTableProps: {
      striped: true,
    },
  });

  return (
    <>
      <div
        style={{ border: "1px solid #dee2e6", padding: "5px" }}
        ref={headerRef}
      >
        <Flex
          gap="md"
          justify={"space-between"}
          align="center"
          direction="row"
          wrap="wrap"
          w={"100%"}
        >
          <_breadcrumb></_breadcrumb>
          <Flex gap="md" align="center" direction="row" wrap="wrap">
            {/* <Button
              leftSection={<IconPlus size={14} />}
              color="blue"
              variant="outline"
              onClick={() => {
                createData();
              }}
            >
              Tạo mới (F11)
            </Button> */}

            <Button
              leftSection={<IconTrash size={14} />}
              color="red"
              variant="outline"
              onClick={async () => {
                // await deleteData(selectIds);
              }}
              disabled={selectIds.length < 1}
            >
              Xóa (đã chọn)
            </Button>

            <Menu shadow="md" width={200}>
              <Menu.Target>
                <Button
                  // size="xs"
                  rightSection={
                    <IconCaretDown
                      style={{ width: rem(14), height: rem(14) }}
                    />
                  }
                >
                  Chức năng
                </Button>
              </Menu.Target>

              <Menu.Dropdown>
                <Menu.Label>Application</Menu.Label>
                <Menu.Item
                  leftSection={
                    <IconFileExport
                      style={{ width: rem(14), height: rem(14) }}
                    />
                  }
                  onClick={handleExport}
                >
                  Export Excel
                </Menu.Item>
                <Tooltip label={"Chức năng đang update"}>
                  <Menu.Item
                    leftSection={
                      <IconFileUpload
                        style={{ width: rem(14), height: rem(14) }}
                      />
                    }
                  >
                    Import Excel
                  </Menu.Item>
                </Tooltip>
              </Menu.Dropdown>
            </Menu>
          </Flex>
        </Flex>

        <Grid mt={10}>
          <GridCol span={{ base: 6, sm: 4, md: 1.5 }}>
            <Input
              w={"100%"}
              placeholder="Từ khoá"
              type="text"
              leftSection={<IconSearch color="#15aabf" />}
              onChange={(e) => {
                handleChangeSearchValue(e.target.value ?? "", "keySearch");
              }}
            />
          </GridCol>

          <GridCol span={{ base: 6, sm: 4, md: 1.5 }}>
            <Select
              placeholder={"Trạng thái KT"}
              clearable
              leftSection={<IconStatusChange color="#15aabf" />}
              comboboxProps={{
                transitionProps: { transition: "pop", duration: 200 },
              }}
              data={dataStatusOption}
              onChange={(value) =>
                handleChangeSearchValue(value || "", "status")
              }
              renderOption={renderSelectOptionTestingStatus}
            />
          </GridCol>

          <GridCol span={{ base: 8, sm: 6, md: 3 }}>
            <DatePickerInput
              // type="multiple"
              type="range"
              size="sm"
              placeholder="Chọn khoảng ngày"
              leftSection={<IconTimeDuration15 color="#15aabf" />}
              locale="vi"
              // value={value}
              valueFormat="DD/MM/YYYY"
              onChange={(e) => {
                handleChangeSearchValue(
                  e[0] ? e[0].toISOString() : "",
                  "fromDate"
                );
                handleChangeSearchValue(
                  e[1] ? e[1].toISOString() : "",
                  "endDate"
                );
              }}
              clearable
            />
          </GridCol>

          <GridCol span={{ base: 4, sm: 2, md: 1 }}>
            <Button
              leftSection={<IconSearch size={14} />}
              color="blue"
              variant="outline"
              onClick={async () => {
                await fetchData();
              }}
            >
              Tìm kiếm
            </Button>
          </GridCol>
        </Grid>
      </div>

      <div className="mt-5" style={{ marginTop: "10px" }}>
        <MantineReactTable table={table} />
      </div>
    </>
  );
};

export default TestingTechnique;
