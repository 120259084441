import {
  Badge,
  Box,
  Button,
  ComboboxItem,
  Divider,
  Fieldset,
  Flex,
  Grid,
  Group,
  LoadingOverlay,
  Select,
  Text,
  TextInput,
  Title,
} from "@mantine/core";
import { DatePickerInput } from "@mantine/dates";
import { useForm } from "@mantine/form";
import { useDisclosure } from "@mantine/hooks";
import { modals } from "@mantine/modals";
import { IconArrowBack, IconCheck, IconSearch } from "@tabler/icons-react";
import { useEffect, useState } from "react";
import { repositoryMdm, repositoryPos } from "../../../_base/_const/_constVar";
import { NotificationExtension } from "../../../_base/extension/NotificationExtension";
import { sky_blue } from "../../../const/variables";
import { MessageResponse } from "../../../model/MessageResponse";
import { TblWarrantyItem } from "../../../model/TblWarrantyItem";
import { TblWarrantyServiceRequestForm } from "../../../model/TblWarrantyServiceRequestForm";
import {
  getBranchSelectMdm,
  getCommuneSelect,
  getDistrictSelect,
  getProvinceSelect,
  getStreetSelect,
  getTblDmProcessingMethodSelect,
  getTblDmSaleChanelSelect,
} from "../../../service/getSelectApi";
import ItemList from "./ItemList";
import WarrantyItem from "./WarrantyItemList";
import { getValueById } from "../../../_base/helper/FunctionHelper";

interface MeetingAddress {
  province: number | null;
  district: number | null;
  commune: number | null;
  street: number | null;
}

const DetailDataView = ({ id, onClose, actionView }: DetailDataViewProps) => {
  const entity = {
    id: 0,
    custId: null,
    deliveryId: 0,
    branchId: null,
    inventoryId: null,
    requestStatus: null,
    deliveryDept: null,
    deliveryDate: null,
    custMeeting: null,
    meetingDate: null,
    custPhone: null,
    provinceId: null,
    districtId: null,
    communeId: null,
    streetId: null,
    additionalAddress: null,
    meetingAddress: null,
    processingMethod: null,
    processingMethodName: null,
    employeeIncharge: null,
    requestNum: "",
    note: null,
    vendorId: null,
    message: null,
    address: null,
    priorityId: null,
    warrantyStatus: null,
    locationId: null,
    channel: null,
    channelName: null,
    employeeSupport: null,
    content: null,
    noteAssignRequest: null,
    receiveBranch: null,
    assignDate: null,
    empAssign: null,
    tblWarrantyServiceRequestFormDetailCommands: [],
  };

  const [visible, { toggle, close, open }] = useDisclosure(false);

  const [dataWarrantyItem, setDataWarrantyItem] = useState<TblWarrantyItem[]>(
    []
  );
  const [dataWarrantyItemSubmit, setDataWarrantyItemSubmit] = useState<
    TblWarrantyItem[]
  >([]);
  const [dataCustomerInfo, setDataCustomerInfo] = useState<any>();
  const [dataTblProcessingMethod, setDataTblProcessingMethod] = useState<
    ComboboxItem[]
  >([]);
  const [dataTblBranch, setDataTblBranch] = useState<ComboboxItem[]>([]);
  const [dataTblSaleChannel, setDataTblSaleChannel] = useState<ComboboxItem[]>(
    []
  );
  const [phoneNumber, setPhoneNumber] = useState("");
  const [createDate, setCreateDate] = useState("");
  const [provinceId, setProvinceId] = useState<string | null>(null);
  const [districtId, setDistrictId] = useState<string | null>(null);
  const [communeId, setCommuneId] = useState<string | null>(null);
  const [dataMeetingAddress, setDataMeetingAddress] = useState<MeetingAddress>({
    province: null,
    district: null,
    commune: null,
    street: null,
  });
  const [dataProvinceSelect, setDataProvinceSelect] = useState<ComboboxItem[]>(
    []
  );
  const [dataDistrictSelect, setDataDistrictSelect] = useState<ComboboxItem[]>(
    []
  );
  const [dataCommuneSelect, setDataCommuneSelect] = useState<ComboboxItem[]>(
    []
  );
  const [dataStreetSelect, setDataStreetSelect] = useState<ComboboxItem[]>([]);
  const [dataApi, setDataApi] = useState<any>();

  const form = useForm<TblWarrantyServiceRequestForm>({
    mode: "uncontrolled",
    validateInputOnChange: true,
    initialValues: {
      ...entity,
    },
    validate: {
      custMeeting(value, values, path) {
        if (!value) return "Vui lòng nhập người hẹn gặp!";
      },
      channel(value, values, path) {
        if (!value) return "Vui lòng chọn kênh tiếp nhận!";
      },
      branchId(value, values, path) {
        if (!value) return "Vui lòng chọn chi nhánh xử lý yêu cầu!";
      },
      provinceId(value, values, path) {
        if (!value) return "Vui lòng chọn tỉnh/thành phố!";
      },
      districtId(value, values, path) {
        if (!value) return "Vui lòng chọn quận/huyện!";
      },
      communeId(value, values, path) {
        if (!value) return "Vui lòng chọn phường/xã!";
      },
      processingMethod(value, values, path) {
        if (!value) return "Vui lòng chọn hình thức xử lý!";
      },
      custPhone: (value: string | null) => {
        if (!value) {
          return "Vui lòng nhập số điện thoại!";
        }
        if (!/^\d{8,10}$/.test(value)) {
          return "Số điện thoại phải có từ 8 đến 10 chữ số!";
        }
      },
    },
  });

  const handleOnChange = (value: any, key: string) => {
    if (value !== "Invalid date")
      setDataMeetingAddress((prevData: any) => ({ ...prevData, [key]: value }));
    else {
      setDataMeetingAddress((prevData: any) => ({ ...prevData, [key]: "" }));
    }
  };

  const callApiGetData = async () => {
    open();
    const callApi = await repositoryMdm.get<MessageResponse<any>>(
      `/api/v1/TblWarrantyServiceRequestForm/update?id=${id}`
    );
    if (callApi?.success) {
      const dataApi = callApi?.data;
      if (dataApi !== null) {
        await Promise.all([
          processingMethodSelect(),
          branchSelect(),
          saleChannelSelect(),
          getDataProvinceSelect(),
        ]);
        form.setValues(dataApi);
        form.resetDirty(dataApi);
        setDataApi(dataApi);
        setCreateDate(dataApi.createDate);
        setProvinceId(dataApi.provinceId?.toString());
        setDistrictId(dataApi.districtId?.toString());
        setCommuneId(dataApi.communeId?.toString());
        setDataMeetingAddress({
          province: form.getValues().provinceId ?? null,
          district: form.getValues().districtId ?? null,
          commune: form.getValues().communeId ?? null,
          street: form.getValues().streetId ?? null,
        });
      }
    }
    close();
  };

  const getCustomerInfo = async () => {
    const req = `/api/v1/TblDmCustomer/get-customer-by-list-id?ids=${
      form.getValues().custId
    }`;

    try {
      const callapi = await repositoryPos.get<MessageResponse<any>>(req);

      if (callapi?.success) {
        setDataCustomerInfo(callapi.data[0]);

        const dataItem =
          dataApi.getDetailForUpdateWarrantyServiceRequestFormModels.map(
            (e: any) => ({
              serial: e.serial,
              orderId: e.orderId,
            })
          );

        const dataItemPromises = dataItem.map((e: any) =>
          repositoryPos.get<MessageResponse<any>>(
            `/api/v1/TblWarrantyItem/get-detail?Serial=${e.serial}&OrderId=${e.orderId}`
          )
        );

        const dataItemResponses = await Promise.all(dataItemPromises);

        const dataItemSubmit = dataItemResponses
          .filter((response) => response?.success)
          .map((response) => response?.data[0]);

        setDataWarrantyItem(dataItemSubmit);
        setPhoneNumber(callapi.data[0].telephoneNumber);
      } else {
        setDataCustomerInfo({});
      }
    } catch (error) {
      setDataCustomerInfo({});
    }
  };

  const processingMethodSelect = async () => {
    try {
      const getData = await getTblDmProcessingMethodSelect();
      const filteredData = getData?.filter(
        (item) => item.value != null && item.text != null
      );
      const mappedData = filteredData?.map((item) => ({
        value: item.value,
        label: item.text,
      }));
      setDataTblProcessingMethod(mappedData);
    } catch (error) {
      console.error("Error in dataTblLocation: ", error);
    }
  };

  const branchSelect = async () => {
    try {
      const getData = await getBranchSelectMdm();
      const filteredData = getData?.filter(
        (item) => item.value != null && item.text != null
      );
      const mappedData = filteredData?.map((item) => ({
        value: item.value,
        label: item.text,
      }));
      setDataTblBranch(mappedData);
    } catch (error) {
      console.error("Error in dataTblLocation: ", error);
    }
  };

  const saleChannelSelect = async () => {
    try {
      const getData = await getTblDmSaleChanelSelect();
      const filteredData = getData?.filter(
        (item) => item.value != null && item.text != null
      );
      const mappedData = filteredData?.map((item) => ({
        value: item.value,
        label: item.text,
      }));
      setDataTblSaleChannel(mappedData);
    } catch (error) {
      console.error("Error in dataTblLocation: ", error);
    }
  };

  const handleSearchByPhone = () => {
    if (!phoneNumber) {
      NotificationExtension.Warn("Vui lòng nhập số điện thoại!");
      return;
    }
    if (!/^\d{8,10}$/.test(phoneNumber.trim())) {
      NotificationExtension.Warn("Số điện thoại phải có từ 8 đến 10 chữ số!");
      return;
    }

    modals.openConfirmModal({
      title: (
        <>
          <Title order={5}>Danh sách hóa đơn</Title>
        </>
      ),
      children: (
        <Box mt={"18px"}>
          <WarrantyItem
            phoneNumber={dataCustomerInfo?.telephoneNumber}
            custId={form.getValues().custId ?? ""}
            selectWarrantyItem={setDataWarrantyItem}
            setFormValues={form.setValues}
          />
        </Box>
      ),
      size: "auto",
      confirmProps: { display: "none" },
      cancelProps: { display: "none" },
    });
  };

  const getDataProvinceSelect = async () => {
    const getData = await getProvinceSelect();
    setDataProvinceSelect(
      getData
        ?.filter((item: any) => item.value != null && item.text != null)
        ?.map((item: any) => ({
          value: item.value,
          label: item.text,
        }))
    );
  };

  const getDataDistrictSelect = async () => {
    if (provinceId !== null) {
      const getData = await getDistrictSelect(provinceId ?? "");
      setDataDistrictSelect(
        getData
          ?.filter((item: any) => item.value != null && item.text != null)
          ?.map((item: any) => ({
            value: item.value,
            label: item.text,
          }))
      );
    }
  };

  const getDataCommuneSelect = async () => {
    if (districtId !== null) {
      const getData = await getCommuneSelect(districtId ?? "");
      setDataCommuneSelect(
        getData
          ?.filter((item: any) => item.value != null && item.text != null)
          ?.map((item: any) => ({
            value: item.value,
            label: item.text,
          }))
      );
    }
  };

  const getDataStreetSelect = async () => {
    if (communeId !== null) {
      const getData = await getStreetSelect(communeId ?? "");
      setDataStreetSelect(
        getData
          ?.filter((item: any) => item.value != null && item.text != null)
          ?.map((item: any) => ({
            value: item.value,
            label: item.text,
          }))
      );
    }
  };

  useEffect(() => {
    if (form.getValues().custId !== null && dataCustomerInfo !== null) {
      getCustomerInfo();
    } else setDataCustomerInfo("");
  }, [form.getValues().custId]);

  useEffect(() => {
    if (id) {
      callApiGetData();
    } else {
      onClose("");
    }
  }, [id]);

  useEffect(() => {
    if (provinceId && provinceId.length > 0) {
      getDataDistrictSelect();
    }
  }, [provinceId]);

  useEffect(() => {
    if (districtId && districtId.length > 0) {
      getDataCommuneSelect();
    }
  }, [districtId]);

  useEffect(() => {
    if (communeId && communeId.length > 0) {
      getDataStreetSelect();
    }
  }, [communeId]);

  useEffect(() => {
    if (dataMeetingAddress.province !== null) {
      const toAddress = `${getValueById(
        dataMeetingAddress.street ?? "",
        dataStreetSelect,
        "label"
      )} - ${getValueById(
        dataMeetingAddress.commune ?? "",
        dataCommuneSelect,
        "label"
      )} - ${getValueById(
        dataMeetingAddress.district ?? "",
        dataDistrictSelect,
        "label"
      )} - ${getValueById(
        dataMeetingAddress.province,
        dataProvinceSelect,
        "label"
      )}`;
      form.setValues((prev) => ({
        ...prev,
        meetingAddress: toAddress
          .replace(/\s*-\s*-\s*/g, " - ")
          .replace(/^\s*-\s*/, ""),
      }));
    }
    form.setValues((prev) => ({
      ...prev,
      streetId: Number(dataMeetingAddress.street),
      communeId: Number(dataMeetingAddress.commune),
      districtId: Number(dataMeetingAddress.district),
      provinceId: Number(dataMeetingAddress.province),
    }));
  }, [dataMeetingAddress]);

  return (
    <>
      <Box
        component="form"
        mx="auto"
        p={"0px 10px"}
        style={{ position: "relative" }}
      >
        <LoadingOverlay
          visible={visible}
          zIndex={1000}
          overlayProps={{ radius: "sm", blur: 2 }}
        />
        <Flex justify={"space-between"} align={"center"}>
          <Badge
            variant="gradient"
            gradient={{ from: "blue", to: "cyan", deg: 90 }}
          >
            Chi tiết phiếu tiếp nhận yêu cầu
          </Badge>
          <Group
            justify="space-between"
            align="center"
            style={{
              position: "sticky",
              bottom: 0,
              backgroundColor: "white",
            }}
          >
            <Flex w={"100%"} justify={"flex-end"} gap={"md"}>
              <Button
                type="button"
                color="red"
                loading={visible}
                onClick={() => {
                  onClose("");
                }}
                leftSection={!visible ? <IconArrowBack size={18} /> : undefined}
              >
                Thoát
              </Button>
            </Flex>
          </Group>
        </Flex>
        <Grid>
          <Grid.Col span={{ xs: 12, sm: 12, md: 6, lg: 4 }} px={0}>
            <Fieldset h={"100%"} legend="Thông tin khách hàng">
              <Grid>
                <Grid.Col span={{ base: 12, md: 6, lg: 6 }}>
                  <Select
                    label="Kênh tiếp nhận"
                    placeholder="Chọn kênh tiếp nhận"
                    data={dataTblSaleChannel}
                    value={form.getValues().channel?.toString() ?? null}
                    {...form.getInputProps("channel")}
                    comboboxProps={{ width: 200 }}
                    readOnly
                    rightSection={" "}
                  />
                </Grid.Col>
                <Grid.Col span={{ base: 12, md: 6, lg: 6 }}>
                  <TextInput
                    label="SĐT"
                    placeholder="Nhập số điện thoại khách hàng"
                    value={phoneNumber ?? ""}
                    readOnly
                  />
                </Grid.Col>
                <Grid.Col span={{ base: 12, md: 6, lg: 6 }}>
                  <TextInput
                    label="Mã khách hàng"
                    placeholder="Tìm kiếm thông qua SĐT"
                    readOnly
                    value={dataCustomerInfo?.code ?? ""}
                  />
                </Grid.Col>
                <Grid.Col span={{ base: 12, md: 6, lg: 6 }}>
                  <TextInput
                    label="Tên khách hàng"
                    placeholder="Tìm kiếm thông qua SĐT"
                    readOnly
                    value={dataCustomerInfo?.name ?? ""}
                  />
                </Grid.Col>
                <Grid.Col span={{ base: 12, md: 6, lg: 6 }}>
                  <TextInput
                    label="Email"
                    placeholder="Tìm kiếm thông qua SĐT"
                    readOnly
                    value={dataCustomerInfo?.email ?? ""}
                  />
                </Grid.Col>
                <Grid.Col span={{ base: 12, md: 6, lg: 6 }}>
                  <TextInput
                    label="Địa chỉ"
                    placeholder="Tìm kiếm thông qua SĐT"
                    readOnly
                    value={dataCustomerInfo?.address ?? ""}
                  />
                </Grid.Col>
              </Grid>
            </Fieldset>
          </Grid.Col>
          <Grid.Col
            span={{ xs: 12, sm: 12, md: 6, lg: 8 }}
            px={{ base: "0", xs: "0", md: "10px 0px", lg: "10px 0px" }}
          >
            <Fieldset legend="Thông tin yêu cầu xử lý" p={"5px 15px 15px 15px"}>
              <Grid>
                <Grid.Col span={{ base: 12, md: 6, lg: 2.4 }}>
                  <TextInput
                    label="Số yêu cầu"
                    readOnly
                    {...form.getInputProps("requestNum")}
                  />
                </Grid.Col>
                <Grid.Col span={{ base: 12, md: 6, lg: 2.4 }}>
                  <TextInput
                    label="Người tiếp nhận yêu cầu"
                    readOnly
                    {...form.getInputProps("createByName")}
                  />
                </Grid.Col>
                <Grid.Col span={{ base: 12, md: 6, lg: 2.4 }}>
                  <DatePickerInput
                    label="Ngày tiếp nhận"
                    readOnly
                    locale="vi"
                    valueFormat="DD-MM-YYYY"
                    value={createDate ? new Date(createDate ?? "") : null}
                  />
                </Grid.Col>
                <Grid.Col span={{ base: 12, md: 6, lg: 2.4 }}>
                  <Select
                    label="Chi nhánh xử lý yêu cầu"
                    placeholder="Chọn chi nhánh xử lý yêu cầu"
                    data={dataTblBranch}
                    value={form.getValues().branchId?.toString()}
                    {...form.getInputProps("branchId")}
                    readOnly
                    rightSection={" "}
                  />
                </Grid.Col>
                <Grid.Col span={{ base: 12, md: 6, lg: 2.4 }}>
                  <Select
                    label="Hình thức xử lý"
                    placeholder="Chọn hình thức xử lý"
                    data={dataTblProcessingMethod}
                    value={form.getValues().processingMethod?.toString() ?? ""}
                    {...form.getInputProps("processingMethod")}
                    readOnly
                    rightSection={" "}
                  />
                </Grid.Col>
              </Grid>
            </Fieldset>
            <Fieldset
              legend="Thông tin khách hàng hẹn gặp"
              p={"5px 15px 15px 15px"}
            >
              <Grid>
                <Grid.Col span={{ base: 12, md: 6, lg: 2.4 }}>
                  <TextInput
                    label="Người hẹn gặp"
                    placeholder={"Nhập người hẹn gặp"}
                    {...form.getInputProps("custMeeting")}
                    readOnly
                  />
                </Grid.Col>
                <Grid.Col span={{ base: 12, md: 6, lg: 2.4 }}>
                  <TextInput
                    label="Số điện thoại"
                    placeholder={"Nhập số điện thoại"}
                    {...form.getInputProps("custPhone")}
                    readOnly
                  />
                </Grid.Col>
                <Grid.Col span={{ base: 12, md: 6, lg: 2.4 }}>
                  <DatePickerInput
                    label="Ngày hẹn gặp"
                    placeholder="Nhập ngày hẹn gặp"
                    locale="vi"
                    valueFormat="DD-MM-YYYY"
                    value={
                      form.getValues().meetingDate
                        ? new Date(form.getValues().meetingDate ?? "")
                        : null
                    }
                    readOnly
                  />
                </Grid.Col>
                <Grid.Col span={{ base: 12, md: 6, lg: 4.8 }}>
                  <TextInput
                    label="Nội dung"
                    placeholder={"Nhập nội dung"}
                    {...form.getInputProps("content")}
                    readOnly
                  />
                </Grid.Col>
                <Grid.Col span={{ base: 12, md: 6, lg: 2.4 }}>
                  <Select
                    label="Tỉnh/TP"
                    placeholder="Nhập tỉnh/thành phố"
                    data={dataProvinceSelect}
                    value={
                      form.getValues().provinceId
                        ? form.getValues().provinceId?.toString()
                        : null
                    }
                    nothingFoundMessage="Không tìm thấy dữ liệu !"
                    {...form.getInputProps("provinceId")}
                    readOnly
                    rightSection={" "}
                  />
                </Grid.Col>
                <Grid.Col span={{ base: 12, md: 6, lg: 2.4 }}>
                  <Select
                    label="Quận/Huyện"
                    placeholder="Nhập quận/huyện"
                    data={dataDistrictSelect}
                    nothingFoundMessage="Không tìm thấy dữ liệu !"
                    {...form.getInputProps("districtId")}
                    value={
                      form.getValues().districtId
                        ? form.getValues().districtId?.toString()
                        : null
                    }
                    readOnly
                    rightSection={" "}
                  />
                </Grid.Col>
                <Grid.Col span={{ base: 12, md: 6, lg: 2.4 }}>
                  <Select
                    label="Phường/Xã"
                    placeholder="Nhập phường/xã"
                    data={dataCommuneSelect}
                    nothingFoundMessage="Không tìm thấy dữ liệu !"
                    {...form.getInputProps("communeId")}
                    value={
                      form.getValues().communeId
                        ? form.getValues().communeId?.toString()
                        : null
                    }
                    readOnly
                    rightSection={" "}
                  />
                </Grid.Col>
                <Grid.Col span={{ base: 12, md: 6, lg: 2.4 }}>
                  <Select
                    label="Tên đường"
                    placeholder="Nhập tên đường"
                    data={dataStreetSelect}
                    {...form.getInputProps("streetId")}
                    value={
                      form.getValues().streetId
                        ? form.getValues().streetId?.toString()
                        : null
                    }
                    readOnly
                    rightSection={" "}
                  />
                </Grid.Col>
                <Grid.Col span={{ base: 12, md: 6, lg: 2.4 }}>
                  <TextInput
                    label="Địa chỉ cụ thể"
                    placeholder="Nhập địa chỉ cụ thể"
                    {...form.getInputProps("additionalAddress")}
                    readOnly
                    rightSection={" "}
                  />
                </Grid.Col>
              </Grid>
            </Fieldset>
          </Grid.Col>

          <Grid.Col span={{ xs: 12, sm: 12, md: 12, lg: 12 }} px={0}>
            <ItemList
              onSelect={setDataWarrantyItemSubmit}
              dataItem={dataWarrantyItem}
              onDelete={setDataWarrantyItem}
              actionView={actionView}
            />
          </Grid.Col>
        </Grid>
      </Box>
    </>
  );
};

export default DetailDataView;

type DetailDataViewProps = {
  id: string | number;
  onClose: any;
  actionView: any;
};
