import {
  ActionIcon,
  Box,
  Button,
  ComboboxItem,
  Fieldset,
  Flex,
  Grid,
  Group,
  Menu,
  Select,
  Table,
  Text,
  TextInput,
  Tooltip,
} from "@mantine/core";
import { useDebouncedState, useHotkeys } from "@mantine/hooks";
import { modals } from "@mantine/modals";
import {
  IconArrowLeft,
  IconCheck,
  IconPlus,
  IconTrash,
  IconX,
} from "@tabler/icons-react";
import {
  MantineReactTable,
  MRT_ColumnDef,
  MRT_RowSelectionState,
  useMantineReactTable,
} from "mantine-react-table";
import * as React from "react";
import { useEffect, useState } from "react";
import { Panel, PanelGroup, PanelResizeHandle } from "react-resizable-panels";
import { useLocation, useNavigate } from "react-router-dom";
import { repositoryPos } from "../../../_base/_const/_constVar";
import _breadcrumb from "../../../_base/component/_layout/_breadcrumb";
import { NotificationExtension } from "../../../_base/extension/NotificationExtension";
import { anyAsciiCode } from "../../../_base/helper/AnyAscii";
import { _localization } from "../../../config/location";
import { sky_blue } from "../../../const/variables";
import { MessageResponse } from "../../../model/MessageResponse";
import { OrderDetailOutWardNew } from "../../../model/SaleOrderModel";
import {
  TblInventorySerialItemDetailOnHand,
  TblInventorySerialOrderDetailOnHand,
} from "../../../model/TblInventorySerialOnHand";
import {
  detailOrderForOutWardModel,
  TblOutward,
  TblOutwardDetail,
} from "../../../model/TblOutWard";
import "./tab.component.css";
import RequestTransferOutWard from "./GeneralInformation/RequestTransferOutWard";
import { getTblInventoryTransactionTypeOutWard } from "../../../service/getSelectApi";
import SaleOrderOutward from "./GeneralInformation/SaleOrderOutward";
import RequestVendorReturnOutWard from "./GeneralInformation/RequestVendorReturnOutWard";
import useScanDetection from "use-scan-detection";

interface TblInventorySerialOrderDetailOnHandEdit
  extends TblInventorySerialOrderDetailOnHand {
  type?: string;
  subInvId?: number;
}

const EditWarehouseOutWard = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const id = location.state && location.state.id;
  const sourceType = location.state && location.state.type;
  const isLocked = location.state && location.state.isLocked;

  const getOutWardProps = (additionalProps = {}) => ({
    dataOutWard: dataOutWard?.tblItemTransactionHeaderCommand,
    dataOrderDetail: dataOrderDetail?.tblInventoryTransactionModel,
    userInfo,
    isLocked,
    handleChangeValueInput,
    ...additionalProps,
  });

  // Thong tin chung xuat lien chi nhanh
  const requestTransfetOutWard = () => {
    return (
      <RequestTransferOutWard
        {...getOutWardProps({ haveStatus: true, isEdit: true })}
      />
    );
  };

  // Thong tin chung xuat noi bo
  const requestTransferInternalOutWard = () => {
    return (
      <RequestTransferOutWard
        {...getOutWardProps({
          haveStatus: true,
          isInternal: true,
          isEdit: true,
        })}
      />
    );
  };

  // Thong tin chung xuat ban hang
  const orderOutWard = () => {
    return <SaleOrderOutward {...getOutWardProps({ isEdit: true })} />;
  };

  // Thong tin chung xuat huy
  const cancelOutWard = () => {
    return <RequestTransferOutWard {...getOutWardProps({ isEdit: true })} />;
  };

  // Thong tin chung xuat dieu chuyen serial
  const serialOutWard = cancelOutWard;

  // Thong tin chung xuat dieu chuyen noi bo serial
  const serialInternalOutWard = () => {
    return (
      <RequestTransferOutWard
        {...getOutWardProps({ isInternal: true, isEdit: true })}
      />
    );
  };

  // Thong tin chung xuat kho dieu chinh
  const adjustmentOutWard = cancelOutWard;

  // Thong tin chung xuat dieu chuyen lap rap
  const createProductOutWard = cancelOutWard;

  // Thong tin chung xuat kho tra ncc
  const returnVendorOutWard = () => {
    return (
      <RequestVendorReturnOutWard {...getOutWardProps({ isEdit: true })} />
    );
  };

  // Function render ui
  const renderMainOutWard = () => {
    const _sourceType =
      sourceType ?? dataOutWard.tblItemTransactionHeaderCommand.sourceType;
    switch (_sourceType) {
      case "XUAT_BAN":
        return orderOutWard();
      case "XUAT_HUY":
        return cancelOutWard();
      case "XUAT_DIEU_CHINH":
        return adjustmentOutWard();
      case "XUAT_DIEU_CHUYEN_CHI_NHANH":
        return requestTransfetOutWard();
      case "XUAT_DIEU_CHUYEN_NOI_BO":
        return requestTransferInternalOutWard();
      case "XUAT_DIEU_CHUYEN_CHI_NHANH_SERIAL":
        return serialOutWard();
      case "XUAT_DIEU_CHUYEN_NOI_BO_SERIAL":
        return serialInternalOutWard();
      case "XUAT_LINH_KIEN_SAN_XUAT":
        return createProductOutWard();
      case "XUAT_TRA_NCC":
        return returnVendorOutWard();
      case "XUAT_TRA_NCC-DCTT":
        return returnVendorOutWard();
      case "XUAT_MUON_BAO_HANH":
        return cancelOutWard();
      default:
        return requestTransfetOutWard();
    }
  };

  /////////////////////////////////////////////////////////////////////////////////////////

  const [dataOutWard, setDataOutWard] = useState<TblOutward>({
    sourceId: 39,
    tblItemTransactionHeaderCommand: {
      transactionId: 0,
      transactionCode: "",
      transactionDate: "",
      sourceCode: "",
      sourceType: "",
      inventoryId: null,
      subInvId: null,
      departmentId: 0,
      status: "1",
      description: "",
      // atribute1: "",
      // atribute2: "",
      // atribute3: "",
      // atribute4: "",
      // atribute5: "",
      // atribute6: "",
      // atribute7: "",
      // atribute8: "",
      // atribute9: "",
      // atribute10: "",
      createBy: 0,
      createDate: "",
      createName: "",
      lastUpdateDate: "",
      lastUpdateBy: 0,
    },
    serials: [],
  });
  const [height, setHeight] = useState(0);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);
  const [rowCount, setRowCount] = useState(0);

  const [userInfo, setUserInfo] = useState<any>();

  const [transactionTempId, setTransactionTempId] = useState(0);

  //table state

  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});
  const entityOrderOutWard = {
    tblInventoryTransactionModel: {},
    inventoryTransactionRequestSourceModels: [],
    invCode: null,
    invId: null,
    invName: null,
    outWardCode: null,
    receiver: null,
    sourceCode: null,
    sourceId: null,
    subInvId: null,
    toAddess: null,
  };
  const [dataOrderDetail, setDataOrderDetail] =
    useState<OrderDetailOutWardNew>(entityOrderOutWard);
  const [serial, setSerial] = useState<string>("");
  const [dataSerialOnHand, setDataSerialOnHand] = useState<
    TblInventorySerialOrderDetailOnHandEdit[]
  >([]);
  const [checkDuplicateCode, setCheckDuplicateCode] = useState<any[]>([]);
  const [dataInventoryTransactionType, setDataInventoryTransactionType] =
    useState<ComboboxItem[]>([]);
  const [keySearch, setKeySearch] = useDebouncedState("", 500);
  const [dataItem, setDataItem] = useState<
    TblInventorySerialItemDetailOnHand[]
  >([]);
  const [isExceedQuantity, setIsExceedQuantity] = useState(false);
  const [isLockForOrder, setIsLockForOrder] = useState(true);

  //#region  table
  const headerRef = React.useRef<HTMLDivElement>(null);

  // useScanDetection({
  //   onComplete: (code) => {
  //     setSerial(code.toString());
  //     fetchDataSerialOnHand(serial);
  //   },
  //   minLength: 5,
  // });

  const titleSell = () => {
    return (
      <>
        <Group mt={-10} align="center" justify="space-between">
          <Flex
            gap="md"
            justify="flex-start"
            align="center"
            direction="row"
            wrap="wrap"
          >
            <Group gap={20}>
              <Text c={"red"} fw={700}>
                Loại phiếu
              </Text>
              <Select
                searchable
                clearable
                size="xs"
                placeholder="Chọn loại phiếu"
                disabled
                data={dataInventoryTransactionType}
                value={
                  dataOutWard.tblItemTransactionHeaderCommand.sourceType?.toString() ||
                  null
                }
                rightSection={<></>}
              />
            </Group>
          </Flex>
          <Flex
            gap="md"
            justify={{ sm: "flex-start", lg: "flex-end" }}
            wrap="wrap"
            align="center"
            direction="row"
          >
            <Button
              leftSection={<IconArrowLeft size={14} />}
              color="red"
              onClick={() => handleReturnPage()}
            >
              Quay lại
            </Button>
            <Button
              type="submit"
              color={sky_blue.base}
              disabled={
                dataOutWard.tblItemTransactionHeaderCommand.status === "LOCK" ||
                isLocked
              }
              onClick={() => {
                handleCreateOutWard("OPEN");
              }}
            >
              Lưu
            </Button>
            <Button
              type="button"
              color="green"
              disabled={
                dataOutWard.tblItemTransactionHeaderCommand.status === "LOCK" ||
                isLockForOrder ||
                isLocked
              }
              onClick={() => {
                if (dataSerialOnHand.length !== 0) {
                  handleCreateOutWard("LOCK", true);
                } else {
                  NotificationExtension.Fails("Chưa có sản phẩm để xuất kho");
                  return;
                }
              }}
            >
              Hoàn thành
            </Button>
          </Flex>
        </Group>
      </>
    );
  };

  const TableSelect = () => (
    <Table.ScrollContainer minWidth={500} h={300} type="native">
      <Table striped highlightOnHover stickyHeader>
        <Table.Thead>
          <Table.Tr>
            <Table.Th />
            <Table.Th>Mã sản phẩm</Table.Th>
            <Table.Th>Tên sản phẩm</Table.Th>
            <Table.Th>Số serial</Table.Th>
            {/* <Table.Th>Tuổi tồn</Table.Th> */}
            <Table.Th>Kho</Table.Th>
            <Table.Th>Đơn vị tính</Table.Th>
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody>
          {dataItem.map((item, index) => (
            <Table.Tr
              key={item.serial}
              bg={
                checkDuplicateCode.some((select) => select === item.serial)
                  ? "var(--mantine-color-blue-light)"
                  : undefined
              }
            >
              <Table.Td>
                <Button
                  size="xs"
                  variant="filled"
                  onClick={() => {
                    fetchDataSerialOnHand(item.serial);
                  }}
                  disabled={checkDuplicateCode.includes(item.serial)}
                >
                  {checkDuplicateCode.includes(item.serial)
                    ? "Đã chọn"
                    : "Chọn"}
                </Button>
              </Table.Td>
              <Table.Td>{item.itemCode}</Table.Td>
              <Table.Td>
                <Tooltip label={item.itemName}>
                  <Text size="sm" w={250} lineClamp={2} truncate="end">
                    {item.itemName}
                  </Text>
                </Tooltip>
              </Table.Td>
              <Table.Td>{item.serial}</Table.Td>
              {/* <Table.Td>{item.itemAge}</Table.Td> */}
              <Table.Td>
                {" "}
                <Tooltip label={item.invName}>
                  <Text size="sm" w={125} truncate="end">
                    {item.invName}
                  </Text>
                </Tooltip>
              </Table.Td>
              <Table.Td>{item.primaryUomName || item.primaryUomCode}</Table.Td>
            </Table.Tr>
          ))}
        </Table.Tbody>
        {dataItem.length < 1 && (
          <Table.Caption>Không tìm thấy sản phẩm!</Table.Caption>
        )}
      </Table>
    </Table.ScrollContainer>
  );

  const handleReturnPage = () => {
    navigate(-1);
  };

  const handleChangeValueInput = (
    value: null | string | boolean,
    key: string
  ) => {
    setDataOutWard((prevData) => ({
      ...prevData,
      tblItemTransactionHeaderCommand: {
        ...prevData.tblItemTransactionHeaderCommand,
        [key]: value,
      },
    }));
  };

  const checkExceedQuantityFound = (data: any[]) => {
    const _exceedQuantityFound =
      dataOrderDetail?.inventoryTransactionRequestSourceModels?.some(
        (order) => {
          const matchingOrder = data
            .filter((item) => item.itemId === order.itemId && !item.type)
            .map((item) => item.primaryQuantity || 0); // Lấy số lượng và mặc định là 0 nếu undefined

          if (matchingOrder) {
            const totalQuantity = matchingOrder.reduce(
              (acc, curr) => acc + curr,
              0
            ); // Tính tổng số lượng
            const orderQuantity = order.primaryQuantity ?? 0;
            const orderTransferQuantity = order.transactionQuantity ?? 0;
            const remainQuantity =
              orderQuantity - (orderTransferQuantity + totalQuantity) || 0;

            return remainQuantity < 0;
          }

          return false;
        }
      );
    if (_exceedQuantityFound) {
      return true;
    }
    return false;
  };

  const fetchDataSerialOnHand = async (serialCode?: string) => {
    setIsLoading(true);
    setIsRefetching(true);
    let url = `?SourceCode=${
      dataOutWard.tblItemTransactionHeaderCommand.sourceCode
    }&Type=${dataOutWard.tblItemTransactionHeaderCommand.sourceType}&Serial=${
      serialCode || serial
    }`;

    try {
      const response = await repositoryPos.get<
        MessageResponse<TblInventorySerialOrderDetailOnHand>
      >(`api/v1/TblItemTransaction/search-serial-item-transaction${url}`);

      if (response && response.success) {
        const responseData = response.data;

        if (responseData.warning) {
          NotificationExtension.Fails(responseData.warning);
        } else {
          const dataDetail = {
            ...responseData,
            subInvName: responseData.invName,
          };
          const checkData = [...dataSerialOnHand, { ...dataDetail }];
          if (checkExceedQuantityFound(checkData)) {
            NotificationExtension.Warn(
              "Sản phẩm vượt quá số lượng cần xuất, vui lòng kiểm tra lại"
            );
          } else {
            setDataSerialOnHand((prevData) => [...prevData, { ...dataDetail }]);
            setCheckDuplicateCode((prev) => [...prev, serialCode || serial]);
            NotificationExtension.Success("Quét mã serial thành công");
          }
        }
      } else {
        NotificationExtension.Fails(response?.message || "");
      }
    } catch (error) {
      setIsError(true);
      console.error(error);
      return;
    }

    setIsError(false);
    setIsLoading(false);
    setIsRefetching(false);
  };

  const handleAddProduct = () => {
    if (dataOutWard.tblItemTransactionHeaderCommand.sourceCode) {
      if (checkDuplicateCode.length === 0) {
        fetchDataSerialOnHand();
        setSerial("");
      } else if (checkDuplicateCode.length > 0) {
        if (checkDuplicateCode.includes(serial)) {
          NotificationExtension.Fails("Mã Serial đã được quét !");
        } else {
          setCheckDuplicateCode((prev) => [...prev, serial]);
          fetchDataSerialOnHand();
          setSerial("");
        }
      }
    } else {
      NotificationExtension.Fails("Chưa nhập số chứng từ");
    }
  };

  const deleteItemSerial = (serial: string) => {
    setDataSerialOnHand((prevData) =>
      prevData.filter((item) => item.serial !== serial)
    );
    setCheckDuplicateCode((prevData) =>
      prevData.filter((item) => item !== serial)
    );
  };

  const checkVarianceItem = (items: detailOrderForOutWardModel[]) => {
    let variance = false;
    let variaceItems: { itemName: string; varianceQuantity: number }[] = [];
    items.forEach((item) => {
      const _varianceQuantity =
        (item.transactionQuantity ?? 0) - (item.primaryQuantity ?? 0);
      if (_varianceQuantity !== 0) {
        variaceItems = [
          ...variaceItems,
          {
            itemName: item.itemName,
            varianceQuantity: _varianceQuantity,
          },
        ];
        variance = true;
      }
    });
    return {
      isVariance: variance,
      variaceItems: variaceItems,
    };
  };

  const openCofirmModal = (
    variaceItems: any[],
    isLocked: boolean
  ): Promise<boolean> => {
    return new Promise((resolve) => {
      modals.openConfirmModal({
        size: "auto",
        children: (
          <Box component="form" mx="auto" style={{ position: "relative" }}>
            <Text size="20px" fw={"bold"} my={10}>
              Có những sản phẩm sau có lệch? Bạn có chắc chắn{" "}
              {isLocked ? "hoàn thành" : "lưu"} phiếu?
            </Text>
            <Box mt={3}>
              <Table
                highlightOnHover
                striped
                withColumnBorders
                horizontalSpacing="md"
                verticalSpacing="sm"
                bd={"1px solid #E0E0E0"}
              >
                <thead>
                  <tr style={{ backgroundColor: "#F8F9FD" }}>
                    <th
                      style={{
                        textAlign: "left",
                        padding: "12px",
                        fontSize: "16px",
                        fontWeight: 600,
                        border: "1px solid #E0E0E0",
                      }}
                    >
                      Tên sản phẩm
                    </th>
                    <th
                      style={{
                        textAlign: "center",
                        padding: "12px",
                        fontSize: "16px",
                        fontWeight: 600,
                        border: "1px solid #E0E0E0",
                      }}
                    >
                      Số lượng lệch
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {variaceItems?.map((item, index) => {
                    const varianceQuantity = item.varianceQuantity || 0;
                    return (
                      <tr key={index}>
                        <td
                          style={{
                            padding: "12px",
                            fontWeight: "bold",
                            textAlign: "left",
                            border: "1px solid #E0E0E0",
                          }}
                        >
                          {item.itemName}
                        </td>
                        <td
                          style={{
                            padding: "12px",
                            textAlign: "center",
                            fontWeight: "bold",
                            color: "red",
                            border: "1px solid #E0E0E0",
                          }}
                        >
                          {varianceQuantity}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </Box>

            <Group
              justify="end"
              mt="xs"
              style={{
                position: "sticky",
                bottom: 0,
                backgroundColor: "white",
              }}
            >
              <Button
                type="button"
                color="gray"
                onClick={() => {
                  handleChangeValueInput("", "status");
                  modals.closeAll();
                  resolve(false);
                }}
                leftSection={<IconX size={18} />}
              >
                Hủy
              </Button>
              <Button
                onClick={() => {
                  handleChangeValueInput("LOCK", "status");

                  modals.closeAll();
                  resolve(true);
                }}
                color={sky_blue.base}
                leftSection={<IconCheck size={18} />}
              >
                Xác nhận
              </Button>
            </Group>
          </Box>
        ),
        confirmProps: { display: "none" },
        cancelProps: { display: "none" },
        onClose() {
          handleChangeValueInput("", "status");
        },
      });
    });
  };

  const handleCreateOutWard = async (status: string, isLocked?: boolean) => {
    if (dataSerialOnHand.some((item) => item.warning)) {
      NotificationExtension.Fails("Có sản phẩm không hợp lệ");
      return;
    }

    if (isExceedQuantity) {
      NotificationExtension.Fails("Có sản phẩm vượt quá số lượng cần xuất");
      return;
    }

    handleChangeValueInput(status, "status");

    const serials = dataSerialOnHand.map((item) => ({
      primaryQuantity: 1,
      subQuantity: item.subQuantity,
      serial: item.serial,
      invId: item.invId ?? item.subInvId,
    }));

    const dataCreate = {
      ...dataOutWard,
      tblItemTransactionHeaderCommand: {
        transactionId:
          transactionTempId ||
          dataOutWard.tblItemTransactionHeaderCommand.transactionId,
        transactionCode:
          dataOutWard.tblItemTransactionHeaderCommand.transactionCode,
        transactionDate:
          dataOutWard.tblItemTransactionHeaderCommand.transactionDate,
        sourceCode: dataOutWard.tblItemTransactionHeaderCommand.sourceCode,
        sourceType: dataOutWard.tblItemTransactionHeaderCommand.sourceType,

        branchId: dataOrderDetail.tblInventoryTransactionModel?.fromBranchId,
        subInvId: dataOrderDetail.tblInventoryTransactionModel?.fromInvId,
        departmentId: dataOutWard.tblItemTransactionHeaderCommand?.departmentId,
        status,
        description: dataOutWard.tblItemTransactionHeaderCommand.description,
      },
      serials,
      isLock: isLocked,
    };

    const url = getOutwardApiUrl(
      dataOutWard.tblItemTransactionHeaderCommand.sourceType,
      true
    );

    try {
      const variance = checkVarianceItem(
        dataOrderDetail?.inventoryTransactionRequestSourceModels
      );
      if (variance.isVariance) {
        const confirm = await openCofirmModal(
          variance.variaceItems,
          status === "LOCK"
        );
        if (!confirm) {
          handleChangeValueInput("OPEN", "status");
          return;
        }
      }
      if (status === "LOCK") {
        const lockResponse = await postOutwardRequest(url, dataCreate);
        if (lockResponse) {
          NotificationExtension.Success("Khóa phiếu xuất kho thành công");
          setTimeout(
            () => navigate("/warehouse-config/warehouse-out-ward"),
            3000
          );
        }
        return;
      }

      const response = await postOutwardRequest(url, dataCreate);
      if (response && status === "OPEN") {
        setTransactionTempId(response.data);
        NotificationExtension.Success("Lưu phiếu xuất kho thành công");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchdataInventoryTransactionType = async () => {
    const response = await getTblInventoryTransactionTypeOutWard();

    setDataInventoryTransactionType(
      response.map((type) => ({ value: type.value, label: type.text }))
    );
  };

  const getOutwardApiUrl = (
    sourceType: string | null,
    create?: boolean
  ): string => {
    const url = create ? "create" : "edit";
    switch (sourceType) {
      case "XUAT_BAN":
        return `/api/v1/Outward/${url}-sale-order-out-ward`;
      case "XUAT_HUY":
        return `/api/v1/Outward/${url}-request-cancellation-outward`;
      case "XUAT_DIEU_CHINH":
        return `/api/v1/Outward/${url}-request-adjustment-outward`;
      case "XUAT_DIEU_CHUYEN_CHI_NHANH":
        return `/api/v1/Outward/${url}-request-transfer-outward`;
      case "XUAT_DIEU_CHUYEN_NOI_BO":
        return `api/v1/Outward/${url}-request-transfer-internal-outward`;
      case "XUAT_DIEU_CHUYEN_CHI_NHANH_SERIAL":
        return `/api/v1/Outward/${url}-request-transfer-serial-outward`;
      case "XUAT_DIEU_CHUYEN_NOI_BO_SERIAL":
        return `/api/v1/Outward/${url}-request-transfer-internal-serial-outward`;
      case "XUAT_LINH_KIEN_SAN_XUAT":
        return `/api/v1/Outward/${url}-production-order-outward`;
      case "XUAT_TRA_NCC":
        return `/api/v1/Outward/${url}-request-vendor-return-outward`;
      default:
        throw new Error("Unknown source type");
    }
  };

  const getColumnVisibility = () => {
    const _sourceType = dataOutWard.tblItemTransactionHeaderCommand.sourceType;
    if (
      _sourceType === "XUAT_DIEU_CHUYEN_CHI_NHANH" ||
      _sourceType === "XUAT_DIEU_CHUYEN_NOI_BO"
    ) {
      return {
        id: false,
        poPrice: false,
        subPrice: false,
        totalAmount: false,
      };
    } else {
      return {
        id: false,
        poPrice: true,
        subPrice: true,
        totalAmount: true,
      };
    }
  };

  const postOutwardRequest = async (url: string, dataCreate: any) => {
    const response = await repositoryPos.post<MessageResponse<any>>(
      url,
      dataCreate
    );
    if (response?.httpStatusCode === 200 && response?.success) {
      return response;
    } else {
      return null;
    }
  };

  const fetchDataItem = async () => {
    if (keySearch) {
      try {
        const _sourceType =
          dataOutWard.tblItemTransactionHeaderCommand.sourceType;
        const _sourceCode =
          dataOutWard.tblItemTransactionHeaderCommand.sourceCode;
        const response = await repositoryPos.get(
          `api/v1/TblItemTransaction/search-list-serial-item-transaction?KeySearch=${keySearch}&SourceCode=${_sourceCode}&Page=30&Type=${_sourceType}`
        );

        setDataItem(response?.data || []);
      } catch (error) {
        setDataItem([]);
      }
    }
  };

  const fetchDataEdit = async () => {
    const url = getOutwardApiUrl(sourceType);
    try {
      const response = await repositoryPos.get<
        MessageResponse<TblOutwardDetail>
      >(`${url}?id=${id}`);
      if (response?.success && response?.data) {
        setDataOutWard((prevData) => ({
          ...prevData,
          tblItemTransactionHeaderCommand:
            response?.data?.tblInventoryTransactionModel,
        }));
        setDataOrderDetail((prevData) => ({
          ...prevData,
          inventoryTransactionRequestSourceModels:
            response?.data?.inventoryTransactionRequestSourceModels?.map(
              (order) => ({
                ...order,
                scannedQuantity: 0,
                remainQuantity:
                  (order.primaryQuantity || 0) -
                  (order.transactionQuantity || 0),
              })
            ),
          tblInventoryTransactionModel:
            response?.data?.tblInventoryTransactionModel,
        }));
        setDataSerialOnHand(
          response.data.inventoryTransactionSerialModels.map((item) => ({
            ...item,
            type: "scanned",
          })) || []
        );
        setCheckDuplicateCode((prev) => [
          ...prev,
          ...response.data.inventoryTransactionSerialModels
            .map((item) => item.serial)
            .filter((serial): serial is string => serial !== null),
        ]);
      } else {
        setDataOrderDetail(entityOrderOutWard);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setDataOrderDetail(entityOrderOutWard);
    }
  };

  useHotkeys([
    [
      "ctrl+S",
      () => {
        handleCreateOutWard("OPEN");
      },
    ],
    [
      "ctrl+Q",
      () => {
        if (dataSerialOnHand.length !== 0) {
          handleCreateOutWard("LOCK", true);
        } else {
          NotificationExtension.Fails("Chưa có sản phẩm để xuất kho");
          return;
        }
      },
    ],
  ]);

  useEffect(() => {
    const userLogin = localStorage.getItem("userLogin");
    const fullName = localStorage.getItem("fullName");
    if (userLogin) setUserInfo({ ...JSON.parse(userLogin), fullName });
    fetchdataInventoryTransactionType();
  }, []);

  useEffect(() => {
    const _sourceType = dataOutWard.tblItemTransactionHeaderCommand.sourceType;
    const newColumnVisibility = getColumnVisibility();
    if (tableDetail) {
      tableDetail.setColumnVisibility(newColumnVisibility);
    }
    if (_sourceType) {
      setIsLockForOrder(_sourceType === "XUAT_BAN");
    } else {
      setIsLockForOrder(false);
    }
  }, [dataOutWard.tblItemTransactionHeaderCommand.sourceType]);

  useEffect(() => {
    if (keySearch && keySearch.length > 2) {
      fetchDataItem();
    } else {
      setDataItem([]);
    }
  }, [keySearch]);

  useEffect(() => {
    fetchDataEdit();
  }, [id]);

  useEffect(() => {
    const _sourceType = dataOutWard.tblItemTransactionHeaderCommand.sourceType;
    const exceedQuantityFound =
      dataOrderDetail?.inventoryTransactionRequestSourceModels.some((order) => {
        const matchingOrder = dataSerialOnHand
          .filter((item) => item.itemId === order.itemId)
          .map((item) => item.primaryQuantity || 0); // Lấy số lượng và mặc định là 0 nếu undefined

        if (matchingOrder) {
          const totalQuantity = matchingOrder.reduce(
            (acc, curr) => acc + curr,
            0
          ); // Tính tổng số lượng

          const orderQuantity = order.primaryQuantity ?? 0;
          const remainQuantity = orderQuantity - totalQuantity || 0;
          return remainQuantity < 0;
        }

        return false;
      });

    setDataOrderDetail((prevData) => ({
      ...prevData,
      inventoryTransactionRequestSourceModels:
        prevData.inventoryTransactionRequestSourceModels.map((order) => {
          const quantities = dataSerialOnHand
            .filter((item) => item.itemId === order.itemId)
            .map((item) => item.primaryQuantity || 0); // Lấy số lượng và mặc định là 0 nếu undefined

          const totalQuantity = quantities.reduce((acc, curr) => acc + curr, 0); // Tính tổng số lượng

          const orderQuantity = order.primaryQuantity ?? 0;
          const orderTransferQuantity = order.transactionQuantity ?? 0;
          const remainQuantity =
            orderQuantity - (orderTransferQuantity + totalQuantity) || 0;

          const priceItem =
            _sourceType === "XUAT_BAN"
              ? order.soPrice1 ?? 0
              : order.poPrice ?? 0;
          return {
            ...order,
            remainQuantity: remainQuantity,
            transactionQuantity: totalQuantity,
            priceItem: priceItem * totalQuantity,
          };
        }),
    }));

    // Ensure the state is set after the mapping is complete
    if (exceedQuantityFound) {
      setIsExceedQuantity(true);
    } else {
      setIsExceedQuantity(false);
    }
  }, [dataSerialOnHand]);

  useEffect(() => {
    const _sourceType = dataOutWard.tblItemTransactionHeaderCommand.sourceType;
    if (
      _sourceType === "XUAT_BAN" &&
      dataOrderDetail?.inventoryTransactionRequestSourceModels.length > 0
    ) {
      const variance = checkVarianceItem(
        dataOrderDetail?.inventoryTransactionRequestSourceModels
      );
      if (!variance.isVariance) {
        setIsLockForOrder(false);
      } else {
        setIsLockForOrder(true);
      }
    }
  }, [dataOrderDetail?.inventoryTransactionRequestSourceModels]);

  useEffect(() => {
    const headerHeight = headerRef.current?.offsetHeight || 0;
    const handleResize = () => {
      // 160 là chiều cao của phần phân trang
      // headerHeight là chiều cao của phần header
      setHeight(window.innerHeight - (310 + headerHeight));
    };

    handleResize(); // Set initial height
    window.addEventListener("resize", handleResize); // Update height on window resize

    return () => {
      window.removeEventListener("resize", handleResize); // Clean up event listener
    };
  }, []);

  const columns = React.useMemo<MRT_ColumnDef<any>[]>(
    () => [
      {
        accessorKey: "index",
        header: "STT",
        Cell: ({ row }) => row.index + 1,
        size: 30,
      },

      {
        accessorKey: "itemCode",
        header: "Mã hàng",
        size: 100,
      },
      {
        accessorKey: "serial",
        header: "Serial",
        size: 100,
      },
      {
        accessorKey: "itemName",
        header: "Tên SP",
        Cell: ({ row }) => (
          <Tooltip label={row.original.itemName}>
            <Text size="sm" w={300}>
              {row.original.itemName}
            </Text>
          </Tooltip>
        ),
      },

      {
        accessorKey: "primaryUomName",
        header: "ĐVT1",
        size: 30,
      },
      {
        accessorKey: "primaryQuantity",
        header: "SL1",
        size: 30,
        Cell: () => <Text>1</Text>,
      },
      {
        accessorKey: "poPrice",
        header: "Giá nhập",
        size: 30,
        Cell: ({ renderedCellValue }) => (
          <Text size="sm" w={300}>
            {renderedCellValue && renderedCellValue?.toLocaleString()}
          </Text>
        ),
      },
      {
        accessorKey: "subQuantity",
        header: "SL2",
        size: 30,
      },
      {
        accessorKey: "subUomName",
        header: "ĐVT2",
        size: 30,
      },
      {
        header: "Giá theo ĐVT2",
        size: 30,
      },
      {
        header: "Thành tiền",
        size: 30,
      },
      {
        accessorKey: "status",
        header: "Tình trạng",
        size: 100,
        Cell: ({ row }) => (
          <Text size="sm" w={300}>
            {!row.original.serialStatus
              ? row.original.status
              : row.original.serialStatus}
          </Text>
        ),
      },
      {
        header: "Ghi chú",
        size: 150,
      },
      {
        accessorKey: "warning",
        header: "Cảnh báo",
        Cell: ({ row }) =>
          !row.original.isMapping ? (
            <Tooltip label={row.original.warning}>
              <Text size="sm" w={300} c={"red"}>
                {row.original.warning}
              </Text>
            </Tooltip>
          ) : null,
      },

      {
        accessorKey: "action",
        header: "Thao tác",
        enableSorting: false,
        enableColumnActions: false,
        enableColumnFilter: false,
        size: 30,
        Cell: ({ row }) => (
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              gap: "10px",
            }}
          >
            {!isLocked && (
              <Tooltip label="Xóa">
                <ActionIcon
                  variant="light"
                  aria-label="Settings"
                  color="red"
                  onClick={async () => {
                    deleteItemSerial(row.original.serial);
                  }}
                >
                  <IconTrash size={20} stroke={1.5} />
                </ActionIcon>
              </Tooltip>
            )}
          </Box>
        ),
      },
    ],
    []
  );

  const columnsOrderDetail = React.useMemo<MRT_ColumnDef<any>[]>(
    () => [
      {
        header: "STT",
        Cell: ({ row }) => row.index + 1,
        size: 30,
      },

      {
        accessorKey: "itemCode",
        header: "Mã hàng",
        size: 30,
      },
      {
        accessorKey: "itemName",
        header: "Tên sản phẩm",
        Cell: ({ renderedCellValue }) => (
          <Text size="sm">{renderedCellValue}</Text>
        ),
      },
      {
        accessorKey: "primaryUom",
        header: "ĐVT1",
        size: 30,
      },
      {
        accessorKey: "primaryQuantity",
        header: "SL1",
        size: 30,
      },
      {
        accessorKey: "poPrice",
        header:
          dataOutWard.tblItemTransactionHeaderCommand.sourceType === "XUAT_BAN"
            ? "Giá bán lẻ"
            : "Giá nhập",
        size: 30,
        Cell: ({ row }) => (
          <Text size="sm">
            {dataOutWard.tblItemTransactionHeaderCommand.sourceType ===
            "XUAT_BAN"
              ? row.original.soPrice1 && row.original.soPrice1?.toLocaleString()
              : row.original.poPrice && row.original.poPrice?.toLocaleString()}
          </Text>
        ),
      },

      {
        accessorKey: "subQuantity",
        header: "SL2",
        size: 30,
      },
      {
        accessorKey: "subUom",
        header: "ĐVT2",
        size: 30,
      },
      {
        accessorKey: "subPrice",
        header: "Giá theo ĐVT2",
        size: 30,
      },
      {
        accessorKey: "totalAmount",
        header: "Thành tiền",
        size: 30,
        Cell: ({ row }) => (
          <Text size="sm">
            {dataOutWard.tblItemTransactionHeaderCommand.sourceType ===
            "XUAT_BAN"
              ? row.original.soPrice1 &&
                (
                  Number(row.original.soPrice1) *
                  (row.original.primaryQuantity || 0)
                ).toLocaleString()
              : row.original.poPrice &&
                (
                  Number(row.original.poPrice) *
                  Number(row.original.primaryQuantity || 0)
                ).toLocaleString()}
          </Text>
        ),
      },
      {
        accessorKey: "itemStatus",
        header: "Tình trạng",
        size: 30,
      },
      {
        header: "Ghi chú",
      },
      {
        header: "Số lượng đã xuất",
        Cell: ({ row }) => {
          return (
            <Text fw={"bold"} c={"red"} size="sm">
              {row.original.scannedQuantity +
                row.original.transactionQuantity || 0}
            </Text>
          );
        },
        size: 30,
      },
      {
        header: "Lệch",
        Cell: ({ row }) => (
          <Text fw={"bold"} c={"red"} size="sm">
            {row.original.scannedQuantity +
              row.original.transactionQuantity -
              (row.original.primaryQuantity || 0)}
          </Text>
        ),
        size: 30,
      },
    ],
    [
      dataSerialOnHand,
      dataOrderDetail.inventoryTransactionRequestSourceModels,
      dataOutWard.tblItemTransactionHeaderCommand.sourceType,
    ]
  );

  const table = useMantineReactTable({
    columns,
    data: dataSerialOnHand,
    positionToolbarAlertBanner: "bottom",
    enableColumnActions: false,
    //add custom action buttons to top-left of top toolbar
    renderTopToolbarCustomActions: ({ table }) => (
      <div ref={headerRef} style={{ width: "70%" }}>
        <Grid>
          <Grid.Col span={{ base: 6, md: 4, lg: 4 }}>
            <TextInput
              flex={3}
              placeholder="Nhập mã vạch"
              size="xs"
              disabled={
                dataOutWard.tblItemTransactionHeaderCommand.status === "LOCK" ||
                isLocked
              }
              value={anyAsciiCode(serial)}
              onKeyDown={(e) => {
                if (e.key === "Enter" && e.currentTarget.value.trim() !== "") {
                  handleAddProduct();
                }
              }}
              onChange={(e) => setSerial(e.currentTarget.value)}
            />
          </Grid.Col>
          <Grid.Col span={{ base: 6, md: 4, lg: 2.5 }}>
            <Button
              w={"100%"}
              leftSection={<IconPlus size={14} />}
              size="xs"
              onClick={handleAddProduct}
              disabled={
                serial?.length < 10 ||
                dataOutWard.tblItemTransactionHeaderCommand.status === "LOCK"
              }
            >
              Thêm barcode
            </Button>
          </Grid.Col>
          <Grid.Col pl={30} span={{ base: 6, md: 4, lg: 4 }}>
            <Menu
              trapFocus={false}
              trigger="hover"
              shadow="md"
              width={"800"}
              position="bottom-start"
            >
              <Menu.Target>
                <TextInput
                  placeholder="Hoặc tìm kiếm sản phẩm theo mã"
                  size="xs"
                  disabled={
                    dataOutWard.tblItemTransactionHeaderCommand.status ===
                      "LOCK" || isLocked
                  }
                  onChange={async (e) => {
                    const _key = e.target.value ?? "";
                    setKeySearch(_key);
                  }}
                />
              </Menu.Target>
              <Menu.Dropdown>
                <TableSelect />
              </Menu.Dropdown>
            </Menu>
          </Grid.Col>
        </Grid>
      </div>
    ),
    enableToolbarInternalActions: false,
    mantineTopToolbarProps: {
      style: {
        borderBottom: "3px solid rgba(128, 128, 128, 0.5)",
        marginBottom: 5,
      },
    },
    localization: _localization,
    // enableRowSelection: true,
    getRowId: (row) => row.id,
    initialState: {
      showColumnFilters: false,
      columnPinning: {
        left: ["mrt-row-select", "index"],
        right: ["action"],
      },
      columnVisibility: {
        id: false,
        phone: false,
        fax: false,
        action: !isLocked,
      },
      density: "xs",
    },
    mantineTableContainerProps: {
      style: { maxHeight: height * 0.55 - 20, minHeight: height * 0.55 - 20 },
    },
    enableStickyHeader: true,
    enablePagination: false,
    enableBottomToolbar: false,
    onRowSelectionChange: setRowSelection,
    // manualFiltering: true,
    // manualPagination: true,
    // manualSorting: true,
    rowCount,
    // onColumnFiltersChange: setColumnFilters,
    // onGlobalFilterChange: setGlobalFilter,
    // onPaginationChange: setPagination,
    // onSortingChange: setSorting,
    mantineTableBodyCellProps: ({ row }) => ({
      style: {
        //  fontWeight: row.getIsSelected() ? "bold" : "normal", //conditional styling
        fontWeight: "normal", //conditional styling
        fontSize: "12.5px",
        padding: "5px 15px",
      },
    }),
    state: {
      // columnFilters,
      // globalFilter,
      isLoading,
      //  pagination,
      showAlertBanner: isError,
      showProgressBars: isRefetching,
      showSkeletons: isLoading, //loading for the first time with no data
      //sorting,
      rowSelection,
    },
    mantineToolbarAlertBannerProps: isError
      ? { color: "red", children: "Lỗi tải dữ liệu !" }
      : undefined,
    // mantinePaginationProps: {
    //   showRowsPerPage: true,
    //   withEdges: true,
    //   rowsPerPageOptions: ["10", "50", "100"],
    // },
    // paginationDisplayMode: "pages",
    enableColumnPinning: true,
    mantineTableProps: {
      striped: false,
    },
    // columnFilterDisplayMode: "popover",
    mantineTableBodyRowProps: ({ row }) => ({
      onClick: row.getToggleSelectedHandler(),
      sx: { cursor: "pointer" },
    }),
  });

  const tableDetail = useMantineReactTable({
    columns: columnsOrderDetail,
    data: dataOrderDetail?.inventoryTransactionRequestSourceModels || [],
    positionToolbarAlertBanner: "bottom",
    //add custom action buttons to top-left of top toolbar
    enableTopToolbar: false,
    enableToolbarInternalActions: false,
    enableColumnActions: false,
    mantineTopToolbarProps: {
      style: {
        borderBottom: "3px solid rgba(128, 128, 128, 0.5)",
        marginBottom: 5,
      },
    },
    localization: _localization,
    // enableRowSelection: true,
    getRowId: (row) => row.id,
    initialState: {
      showColumnFilters: false,
      columnPinning: {
        left: ["mrt-row-select"],
        right: ["action"],
      },
      columnVisibility: getColumnVisibility(),
      density: "xs",
    },
    mantineTableContainerProps: {
      style: {
        maxHeight: height * 0.35,
        minHeight: height * 0.35,
      },
    },
    enableStickyHeader: true,
    enablePagination: false,
    enableBottomToolbar: false,
    onRowSelectionChange: setRowSelection,
    // manualFiltering: true,
    // manualPagination: true,
    // manualSorting: true,
    rowCount,
    // onColumnFiltersChange: setColumnFilters,
    // onGlobalFilterChange: setGlobalFilter,
    // onPaginationChange: setPagination,
    // onSortingChange: setSorting,
    mantineTableBodyCellProps: ({ row }) => ({
      style: {
        //  fontWeight: row.getIsSelected() ? "bold" : "normal", //conditional styling
        fontWeight: "normal", //conditional styling
        fontSize: "12.5px",
        padding: "5px 15px",
      },
    }),
    state: {
      // columnFilters,
      // globalFilter,
      isLoading,
      //  pagination,
      showAlertBanner: isError,
      showProgressBars: isRefetching,
      showSkeletons: isLoading, //loading for the first time with no data
      //sorting,
      rowSelection,
    },
    mantineToolbarAlertBannerProps: isError
      ? { color: "red", children: "Lỗi tải dữ liệu !" }
      : undefined,
    // mantinePaginationProps: {
    //   showRowsPerPage: true,
    //   withEdges: true,
    //   rowsPerPageOptions: ["10", "50", "100"],
    // },
    // paginationDisplayMode: "pages",
    enableColumnPinning: true,
    mantineTableProps: {
      striped: false,
    },
    // columnFilterDisplayMode: "popover",
    mantineTableBodyRowProps: ({ row }) => ({
      onClick: row.getToggleSelectedHandler(),
      sx: { cursor: "pointer" },
    }),
  });

  //#endregion
  return (
    <Box>
      <div ref={headerRef}>
        <_breadcrumb></_breadcrumb>
        <Box my={"10px"}>{titleSell()}</Box>
      </div>

      <Box
        style={{
          maxHeight: `${height + 220}px`,
          minHeight: `${height + 220}px`,
        }}
      >
        <PanelGroup direction="horizontal" id="group">
          <Panel defaultSize={28} minSize={28} maxSize={40}>
            <Fieldset legend={"Thông tin chung"} p={5}>
              {renderMainOutWard()}
            </Fieldset>
          </Panel>
          <PanelResizeHandle id="resize-handle" />
          <Panel defaultSize={85} minSize={35} maxSize={85}>
            <Box>
              <Fieldset legend={"Bên xuất"} pb={10}>
                <MantineReactTable table={table} />
              </Fieldset>
              <Fieldset
                legend={
                  dataOutWard.tblItemTransactionHeaderCommand.sourceType ===
                  "XUAT_BAN"
                    ? "Thông tin đơn hàng"
                    : "Yêu cầu xuất kho"
                }
                pb={10}
              >
                <MantineReactTable table={tableDetail} />
              </Fieldset>
            </Box>
          </Panel>
        </PanelGroup>
      </Box>
    </Box>
  );
};
export default EditWarehouseOutWard;
