import { Badge, NumberFormatter, Text } from "@mantine/core";
import {
  MantineReactTable,
  MRT_ColumnDef,
  useMantineReactTable,
} from "mantine-react-table";
import React, { useEffect, useState } from "react";
import { _localization } from "../../../config/location";
import { repositoryMdm } from "../../../_base/_const/_constVar";
import { NotificationExtension } from "../../../_base/extension/NotificationExtension";

type Props = {
  id: any;
};

const DetailDataView = ({ id }: Props) => {
  const [dataDetai, setDataDetail] = useState<any>([]);
  const fectDataDetail = async () => {
    try {
      const response = await repositoryMdm.get(
        `/api/v1/Tblfinishedgoodsreceipt/detail?id=${id}`
      );
      if (response && response.success) {
        let result = response?.data?.tblChitietVatTuModels;
        setDataDetail(result);
      } else {
        setDataDetail([]);
        NotificationExtension.Fails("Lỗi tải dữ liệu !");
      }
    } catch (error) {
      console.log("error", error);
      NotificationExtension.Fails("Lỗi tải dữ liệu !");
      setDataDetail([]);
    }
  };
  const columns = React.useMemo<MRT_ColumnDef<any>[]>(
    () => [
      {
        accessorKey: "stt",
        header: "STT",
        Cell: ({ renderedCellValue }) => (
          <>
            <Text fw={600}> {renderedCellValue}</Text>
          </>
        ),
        size: 100,
      },
      {
        accessorKey: "maKho",
        header: "Mã Kho",
        Cell: ({ renderedCellValue }) => (
          <>
            <Text fw={600}> {renderedCellValue}</Text>
          </>
        ),
        size: 120,
      },
      {
        accessorKey: "maVt",
        header: "Mã Vật Tư",
        Cell: ({ renderedCellValue }) => (
          <>
            <Text fw={600}> {renderedCellValue}</Text>
          </>
        ),
        size: 150,
      },
      {
        accessorKey: "maSpct",
        header: "Mã Sản Phẩm chi tiết",
        Cell: ({ renderedCellValue }) => (
          <>
            <Text fw={600}> {renderedCellValue}</Text>
          </>
        ),
        size: 200,
      },
      {
        accessorKey: "tenVt",
        header: "Tên Vật Tư",
        Cell: ({ renderedCellValue }) => (
          <>
            <Text fw={600}> {renderedCellValue}</Text>
          </>
        ),
        size: 150,
      },
      {
        accessorKey: "dvt",
        header: "Đơn Vị Tính",
        Cell: ({ renderedCellValue }) => (
          <>
            <Text> {renderedCellValue}</Text>
          </>
        ),
        size: 100,
      },
      {
        accessorKey: "ngayCt",
        header: "Ngày Chứng Từ",
        Cell: ({ renderedCellValue }) => (
          <>
            <Text> {renderedCellValue}</Text>
          </>
        ),
        size: 150,
      },
      {
        accessorKey: "soLsx",
        header: "Số Lệnh Sản Xuất",
        Cell: ({ renderedCellValue }) => (
          <>
            <Text> {renderedCellValue}</Text>
          </>
        ),
        size: 150,
      },

      {
        accessorKey: "tenKho",
        header: "Tên Kho",
        Cell: ({ renderedCellValue }) => (
          <>
            <Text> {renderedCellValue}</Text>
          </>
        ),
        size: 200,
      },
      {
        accessorKey: "soLuong",
        header: "Số Lượng",
        Cell: ({ renderedCellValue }) => (
          <>
            <Text> {renderedCellValue}</Text>
          </>
        ),
        size: 100,
      },
      {
        accessorKey: "gia",
        header: "Giá",
        Cell: ({ renderedCellValue }) => (
          <>
            <Text fw={600}>
              <NumberFormatter
                thousandSeparator
                value={String(renderedCellValue) ?? ""}
              />
            </Text>
          </>
        ),
        size: 150,
      },
      {
        accessorKey: "tien",
        header: "Tiền",
        Cell: ({ renderedCellValue }) => (
          <>
            <Text fw={600}>
              <NumberFormatter
                thousandSeparator
                value={String(renderedCellValue) ?? ""}
              />
            </Text>
          </>
        ),
        size: 150,
      },
    ],
    []
  );

  const table = useMantineReactTable({
    columns,
    data: dataDetai,
    positionToolbarAlertBanner: "bottom",
    mantineTopToolbarProps: {
      style: {
        borderBottom: "3px solid rgba(128, 128, 128, 0.5)",
        marginBottom: 5,
      },
    },
    localization: _localization,
    enableRowSelection: false,
    enablePagination: false,
    enableBottomToolbar: false,
    enableTopToolbar: false,
    getRowId: (row) => row.id,
    initialState: {
      showColumnFilters: false,
      density: "xs",
    },
    mantineTableContainerProps: {
      style: { maxHeight: 150, minHeight: 50 },
    },
    enableStickyHeader: true,
    // onRowSelectionChange: setRowSelection,
    // rowCount,
    mantineTableBodyCellProps: ({ row }) => ({
      style: {
        //  fontWeight: row.getIsSelected() ? "bold" : "normal", //conditional styling
        fontWeight: "500", //conditional styling
        fontSize: "12.5px",
        padding: "5px 15px",
      },
    }),
    mantineTableProps: () => ({
      striped: true,
    }),
    // state: {
    //   // columnFilters,
    //   // globalFilter,
    //   isLoading,
    //   //  pagination,
    //   showAlertBanner: isError,
    //   // showProgressBars: isRefetching,
    //   showSkeletons: isLoading, //loading for the first time with no data
    //   //sorting,
    //   rowSelection,
    // },
    // mantineToolbarAlertBannerProps: isError
    //   ? { color: "red", children: "Lỗi tải dữ liệu !" }
    //   : undefined,
    mantinePaginationProps: {
      showRowsPerPage: true,
      withEdges: true,
      rowsPerPageOptions: ["10", "50", "100"],
    },
    paginationDisplayMode: "pages",
    enableColumnPinning: true,
    mantineTableBodyRowProps: ({ row }) => ({
      onClick: row.getToggleSelectedHandler(),
      sx: { cursor: "pointer" },
    }),
  });
  useEffect(() => {
    fectDataDetail();
  }, [id]);
  return (
    <>
      <MantineReactTable table={table} />
    </>
  );
};

export default DetailDataView;
