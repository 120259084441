import React, { useEffect, useState } from "react";
import {
  ActionIcon,
  Box,
  Button,
  Card,
  Divider,
  Flex,
  Grid,
  GridCol,
  Group,
  NumberFormatter,
  NumberInput,
  ScrollArea,
  Select,
  Table,
  Text,
  Textarea,
  TextInput,
  Title,
  Tooltip,
} from "@mantine/core";
import {
  IconCheck,
  IconLoader3,
  IconMinus,
  IconNotes,
  IconReceipt,
  IconX,
} from "@tabler/icons-react";

import { notifications } from "@mantine/notifications";
import moment from "moment";
import dayjs from "dayjs";
import { SelectListItemBase } from "../../../_base/model/_base/SelectListItemBase";
import { repositoryMdm, repositoryPos } from "../../../_base/_const/_constVar";
import { MessageResponse } from "../../../model/MessageResponse";
import { TblDMPaymentMethod } from "../../../model/TblDMPaymentMethod";
import BreadcrumbLayout1 from "../../../_base/component/_layout/_breadcrumb";
import {
  formatDateTransfer,
  formatDateTransferLastYear,
} from "../../../common/FormatDate/FormatDate";
import EditableTextWithOptions from "../../../common/selectValue";
import { useNavigate } from "react-router-dom";
import { modals } from "@mantine/modals";
import AddFormDebt from "../AddFormDebt/AddFormDebt";
interface Department {
  id: number;
  name: string;
}

interface FundDetails {
  accountfund: number | null | undefined;
  codefund: number | null;
  createby: number;
  createdDate: string;
  creatorbranch: string | null | undefined;
  creatordepartment: number | null | undefined;
  creatorfullname: string | null;
  creatorsalesbranch: string | null;
  creatorworkplace: string | null;
  fundbranch: string | null;
  funddepartment: string | null;
  fundsalesbranch: string | null;
  fundworkplace: string | null;
  funfullname: string | null;
  id: number;
  payerdescription: string | null;
  payerfullname: string | null;
  payernote: string | null;
  payertype: string | null;
  receiptNumber: string;
  anotherName: string | null;
  phoneNumber: string | null;
  codeCustomer: string | null;
  totalAmount: number;
  type: number;
  typeReceipt: number;
  completiontime?: string;
  status?: string | null;
  attribute2?: string | null;
  attribute3?: string | null;
  bankname?: string | null;
  createrolename?: string | null;
  accountnumber?: string | null;
}
interface ExampleInterface {
  att1: string | null;
  att2: string | null;
  att3: number | null;
  att4: number | null;
  att5: string[] | null;
  disabled: boolean;
  group: string | null;
  selected: boolean;
  text: string | null;
  value: string | null;
}

interface UserData {
  code: string;
  depName: string;
  departmentId: number;
  fullName: string;
  id: number;
  managerId: number | null;
  managerName: string | null;
  roleId: number;
  roleName: string;
}

interface DepartmentUser {
  departmentId: number;
  name: string;
  description: string | null;
  active: boolean;
  departmentCode: string;
  creationDate: string;
  createdBy: string;
  lastUpdateDate: string | null;
  lastUpdatedBy: string | null;
  address: string;
  branchId: number;
}

interface User {
  id: number;
  code: string;
  fullname: string;
  dateOfBirth: string;
  gender: number;
  departmentId: number | null;
  departmentName: string | null;
  roleId: number;
  roleName: string;
  managerName: string | null;
  address: string | null;
  phoneNumber: string;
  active: boolean;
  email: string;
  description: string | null;
  accountingCenterId: number | null;
  managerId: number | null;
  isCrm: boolean | null;
  currentCardId: number;
  partCode: string | null;
  createDate: string;
  listBranch: string | null;
  listInventory: string | null;
  listGroup: string;
  branchId: number;
  branchName: string;
  workAddress: string | null;
}

interface CreateReceiptParentRequest {
  //   receiptCommand: Command[];
  paymentReceiptDetailCommand: FundDetails;
  save: boolean | null | undefined;
}

const departments: Department[] = [
  { id: 424, name: "Phòng Kỹ thuật-Bảo hành" },
  { id: 425, name: "Phòng Marketing và Truyền thông" },
  { id: 450, name: "Phòng Công nghệ thông tin" },
  { id: 451, name: "Phòng Hành chính Nhân sự" },
  { id: 453, name: "CN Hai Bà Trưng" },
  { id: 454, name: "CN Đống Đa" },
  { id: 455, name: "CN Hải Phòng" },
  { id: 456, name: "CN Cầu Giấy" },
  { id: 457, name: "CN Hà Đông 1" },
  { id: 458, name: "CN Q3,HCM" },
  { id: 459, name: "CN Long Biên" },
  { id: 460, name: "CN Từ Sơn" },
  { id: 461, name: "CN Thanh Trì" },
  { id: 462, name: "CN Đông Anh" },
  { id: 463, name: "CN Hà Đông 2" },
  { id: 464, name: "CN Bắc Giang" },
  { id: 465, name: "CN Phủ Lý" },
  { id: 466, name: "CN Vinh" },
  { id: 467, name: "CN Thái Nguyên" },
  { id: 468, name: "CN Thanh Hóa" },
  { id: 469, name: "Phòng Dự Án" },
  { id: 470, name: "Phòng Kinh doanh Camera & Thiết bị An Ninh" },
  { id: 471, name: "Hội đồng Quản trị" },
  { id: 472, name: "Phòng Kế toán" },
  { id: 473, name: "Phòng Dịch vụ Khách hàng" },
  { id: 474, name: "Total" },
  { id: 421, name: "Phòng Kế hoạch Kinh doanh" },
  { id: 422, name: "Phòng giao Vận" },
  { id: 423, name: "Phòng Kinh doanh Online" },
  { id: 481, name: "Phòng Kinh doanh phân phối" },
];

const salesPositions: Department[] = [
  { id: 501, name: "Nhân viên Kinh doanh" },
  { id: 503, name: "Chuyên viên Phát triển Kinh doanh" },
  { id: 504, name: "Quản lý Kinh doanh Khu vực" },
  { id: 505, name: "Nhân viên Hỗ trợ Bán hàng" },
  { id: 507, name: "Nhân viên Tư vấn Bán hàng" },
  { id: 508, name: "Nhân viên Chăm sóc Khách hàng" },
];

interface DepartmentCustomerOrder {
  id: number;
  name: string;
  details: {
    fullName: string;
    phoneNumber: string;
  };
}

const codeCustomerOrder: DepartmentCustomerOrder[] = [
  {
    id: 1,
    name: "OCD-156",
    details: { fullName: "Nguyen Van A", phoneNumber: "0123456789" },
  },
  {
    id: 2,
    name: "OCD-251",
    details: { fullName: "Le Thi B", phoneNumber: "0987654321" },
  },
  {
    id: 3,
    name: "OCD-126",
    details: { fullName: "Tran Van C", phoneNumber: "0321456789" },
  },
  {
    id: 4,
    name: "OCD-900",
    details: { fullName: "Pham Thi D", phoneNumber: "0934567890" },
  },
  {
    id: 5,
    name: "OCD-542",
    details: { fullName: "Hoang Van E", phoneNumber: "0897654321" },
  },
  {
    id: 6,
    name: "OCD-538",
    details: { fullName: "Vu Thi F", phoneNumber: "0765432109" },
  },
];

const dataExchangeRate = [
  {
    calculation: "VND",
    value: 1,
    id: 1,
  },
  {
    calculation: "USD",
    value: 24200,
    id: 2,
  },
  {
    calculation: "Vàng SJC 9999",
    value: 7800000,
    id: 3,
  },
  {
    calculation: "Vàng PNJ 9999",
    value: 7600000,
    id: 4,
  },
];

const banks = [
  { text: "VCB", value: 1 },
  { text: "VietinBank", value: 2 },
  { text: "BIDV", value: 3 },
  { text: "Techcombank", value: 4 },
  { text: "MB", value: 5 },
  { text: "VPBank", value: 6 },
  { text: "Sacombank", value: 7 },
  { text: "ACB", value: 8 },
  { text: "TPBank", value: 9 },
];

const vietnamBanks = [
  { value: "VCB", accountNumber: "1234567890" }, // Ngân hàng TMCP Ngoại thương Việt Nam
  { value: "VietinBank", accountNumber: "100012345678" }, // Ngân hàng TMCP Công thương Việt Nam
  { value: "BIDV", accountNumber: "2134567890123" }, // Ngân hàng TMCP Đầu tư và Phát triển Việt Nam
  { value: "Techcombank", accountNumber: "1900123456789" }, // Ngân hàng TMCP Kỹ thương Việt Nam
  { value: "MB", accountNumber: "068012345678" }, // Ngân hàng TMCP Quân đội
  { value: "VPBank", accountNumber: "15A0123456789" }, // Ngân hàng TMCP Việt Nam Thịnh Vượng
  { value: "Sacombank", accountNumber: "0200123456789" }, // Ngân hàng TMCP Sài Gòn Thương Tín
  { value: "ACB", accountNumber: "123456789012" }, // Ngân hàng TMCP Á Châu
  { value: "TPBank", accountNumber: "02001234567" }, // Ngân hàng TMCP Tiên Phong
  { value: "VIB", accountNumber: "111122223333" }, // Ngân hàng TMCP Quốc tế Việt Nam
  { value: "HDBank", accountNumber: "12345678901234" }, // Ngân hàng TMCP Phát triển TP.HCM
  { value: "SCB", accountNumber: "900012345678" }, // Ngân hàng TMCP Sài Gòn
];

const CreditAdvice = () => {
  const userName = localStorage.getItem("userName") || "";
  const navigate = useNavigate();
  const [dataGetReceiptParent, setDataGetReceiptParent] = useState<any>();
  const [isRunning, setIsRunning] = useState<boolean>(true);
  const [newDate, setNewDate] = useState<Date | null>(new Date());
  const [height, setHeight] = useState(0);
  const [statusReponse, setStatusReponse] = useState<any>();
  const [changeFielselect, setChangeFielselect] = useState<any>();
  const [changeValueTotal, setChangeValueTatal] = useState<any>();
  const [userData, setUserData] = useState<UserData | null>(null);
  const [selectedCalculation, setSelectedCalculation] = useState<string>("VND");
  const [dataGetDepartments, setDataGetDepartments] = useState<
    ExampleInterface[]
  >([]);
  const [customerOrders, setCustomerOrders] = useState<
    DepartmentCustomerOrder[]
  >([]);
  const [stoppedTime, setStoppedTime] = useState<string>();
  const [valueDataLocation, setValueDataLocation] = useState<string[]>([]);
  const [inputValueDept, setInputValueDept] = useState("");
  const headerRef = React.useRef<HTMLDivElement>(null);
  const [listEmployee, setListEmployee] = useState<any[]>([]);
  const [handelAttrible, setHandelAttrible] = useState<boolean>(false);
  const [handlePayertype, setHandlePayertype] = useState<boolean>(false);
  const [handleOther, setHandleOther] = useState<boolean>(false);
  const [currentTimeDelay, setCurrentTimeDelay] = useState<string>(
    moment(newDate).format()
  );
  const [branchDeparment, setBranchDeparment] = useState<DepartmentUser>();
  const [handelDateCurrent, setHandelDateCurrent] = useState<boolean>(false);
  const [currentTime, setCurrentTime] = useState<string>(
    dayjs(newDate, "DD-MM-YYYY HH:mm:ss").add(7, "hour").toISOString()
  );
  const [listTblDmEmployee, setListTblDmEmployee] = useState<User>();

  const [selectedValue, setSelectedValue] = useState<any>(1);
  const [dataCodeFund, setDataCodeFunch] = useState<SelectListItemBase[]>([]);

  const [formErrors, setFormErrors] = useState({
    payerfullname: "",
    accountfund: 1,
    codefund: "",
    bankname: "",
    accountnumber: "",
    createby: 0,
    createdDate: "",
    creatorbranch: "",
    creatordepartment: "",
    creatorfullname: "",
    creatorsalesbranch: "",
    creatorworkplace: "",
    fundbranch: "",
    funddepartment: "",
    fundsalesbranch: "",
    fundworkplace: "",
    funfullname: "",
    id: 0,
    payerdescription: "",
    payernote: "",
    codeCustomer: "",
    anotherName: "",
    phoneNumber: "",
    payertype: "",
    receiptNumber: "",
    totalAmount: "",
    type: 0,
  });

  const [formData, setFormData] = useState({
    accountfund: 1,
    codefund: null,
    createby: 0,
    createdDate: moment(stoppedTime, "DD-MM-YYYY HH:mm:ss")
      .add(7, "hours")
      .toISOString(),
    completiontime: moment(currentTime, "DD-MM-YYYY HH:mm:ss")
      .add(7, "hours")
      .toISOString(),
    creatorbranch: "Kế Toán BH",
    creatordepartment: "",
    creatorfullname: `${userName} - H567`,
    creatorsalesbranch: null,
    creatorworkplace: "129 Lê Thanh Nghị",
    fundbranch: "",
    funddepartment: "",
    fundsalesbranch: "",
    accountNumber: "",
    fundworkplace: "",
    funfullname: "",
    payerdescription: "",
    payerfullname: "",
    payernote: "",
    payertype: "Nội bộ",
    anotherName: "",
    phoneNumber: "",
    codeCustomer: "",
    accountnumber: "",
    bankname: "",
    receiptNumber: dataGetReceiptParent?.receiptNumber,
    totalAmount: null,
    type: 4,
    attribute2: "",
    attribute3: "",
    typeReceipt: dataGetReceiptParent?.typeReceipt,
  });

  const formatReceiptNumber = (receiptNumber: string): string => {
    const prefix = receiptNumber.slice(0, 2);
    const year = receiptNumber.slice(2, 4);
    const month = receiptNumber.slice(5, 7);
    const day = receiptNumber.slice(8, 10);
    const suffix = receiptNumber.slice(11);

    return `${prefix}${day}.${month}.${year}.${suffix}`;
  };

  const getDataFromLocalStorage = (key: string): UserData | null => {
    const data = localStorage.getItem(key);
    if (data) {
      return JSON.parse(data);
    }
    return null;
  };

  const formatFullName = (value: string) => {
    return value
      .toLowerCase()
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  const handleChangeRate = (value: any) => {
    setSelectedCalculation(value);
  };

  const handleChange = (
    field: string,
    value: string | number | Date | null,
    valueDataLocation?: string[] | null
  ) => {
    if (valueDataLocation) {
      const data = valueDataLocation.map((e) => e);
      setValueDataLocation(data);
    }

    if (field === "fundbranch" && formErrors.fundbranch) {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        fundbranch: value ? "" : "Vui lòng chọn chức danh!",
      }));
    }

    if (field === "fundworkplace" && formErrors.fundworkplace) {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        fundworkplace: value ? "" : "Vui lòng chọn địa điểm làm việc!",
      }));
    }

    if (field === "funfullname" && formErrors.funfullname) {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        funfullname: value ? "" : "Vui lòng chọn họ và tên!",
      }));
    }

    if (field === "codefund" && formErrors.codefund) {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        codefund: value ? "" : "Vui lòng chọn mã phí!",
      }));
    }

    if (field === "funddepartment" && formErrors.funddepartment) {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        funddepartment: value ? "" : "Vui lòng chọn phòng ban!",
      }));
    }

    if (field === "bankname" && formErrors.bankname) {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        bankname: value ? "" : "Vui lòng chọn tài khoản!",
      }));
    }
    if (field === "accountnumber" && formErrors.accountnumber) {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        accountnumber: value ? "" : "Vui lòng chọn số tài khoản!",
      }));
    }

    if (field === "payerdescription" && formErrors.payerdescription) {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        payerdescription: value ? "" : "Vui lòng nhập thông tin!",
      }));
    }

    if (field === "codeCustomer" && formErrors.codeCustomer) {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        codeCustomer: value ? "" : "Vui lòng chọn mã KH/NCC!",
      }));
    }

    if (field === "anotherName" && formErrors.anotherName) {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        anotherName: value ? "" : "Vui lòng nhập tên!",
      }));
    }

    if (field === "phoneNumber" && formErrors.phoneNumber) {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        phoneNumber: value ? "" : "Vui lòng nhập số điện thoại!",
      }));
    }

    if (field === "totalAmount" && formErrors.totalAmount) {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        totalAmount: value ? "" : "Vui lòng nhập số tiền!",
      }));
    }

    if (field === "codeCustomer" && formErrors.codeCustomer) {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        codeCustomer: value ? "" : "Vui lòng chọn mã KH/NCC!",
      }));
    }

    if (value) {
      setChangeFielselect(value);
    }

    if (field === "payertype") {
      if (value !== "Nội bộ") {
        setHandlePayertype(true);
      } else {
        setHandlePayertype(false);
      }
    }

    if (field === "payertype") {
      if (value !== "Khác") {
        setHandleOther(false);
      } else {
        setHandleOther(true);
      }
    }

    if (field === "totalAmount") {
      setChangeValueTatal(value);
    }

    setFormData((prevData) => ({
      ...prevData,
      [field]: value instanceof Date ? value.toISOString() : value,
    }));
  };

  // function openFormDebt() {
  //   modals.openConfirmModal({
  //     title: (
  //       <>
  //         <Title order={5}>Danh Sách Đối Tượng Công Nợ</Title>
  //       </>
  //     ),
  //     size: "90vw",
  //     children: (
  //       <AddFormDebt handleChooseDebt={handleChooseDebt} navigate={navigate} />
  //     ),
  //     confirmProps: { display: "none" },
  //     cancelProps: { display: "none" },
  //   });
  // }

  useEffect(() => {
    setInputValueDept(
      dataGetReceiptParent?.groupName
        ? dataGetReceiptParent?.id + " - " + dataGetReceiptParent?.groupName
        : ""
    );
  }, [dataGetReceiptParent]);

  const formInternal = () => (
    <>
      <Grid.Col pt={0} span={{ xs: 12, sm: 6, md: 6, lg: 6, lx: 6 }}>
        <Flex align="center">
          <Text style={{ flex: "0 0 110px", whiteSpace: "nowrap" }}>
            Phòng ban
          </Text>
          <Select
            searchable
            flex={1}
            clearable
            error={
              !handlePayertype && formErrors.funddepartment
                ? formErrors.funddepartment
                : undefined
            }
            disabled={!!handlePayertype || statusReponse}
            value={
              dataGetDepartments?.find(
                (item: any) => item.value === formData.funddepartment
              )?.text || null
            }
            data={
              dataGetDepartments
                .map((e) => e.text)
                .slice(1)
                .filter((text): text is string => !!text) ?? []
            }
            placeholder="Vui lòng chọn phòng ban"
            onChange={(selectedDescription) => {
              if (!selectedDescription) {
                handleChange("funddepartment", null, null);
                handleChange("fundworkplace", null);
              } else {
                const selectedOption = dataGetDepartments?.find(
                  (item) => item.text === selectedDescription
                );
                if (selectedOption) {
                  handleChange(
                    "funddepartment",
                    selectedOption.value,
                    selectedOption.att5
                  );
                  handleChange("fundworkplace", null);
                }
              }
            }}
            onClick={async () => await fetchDataDepartments()}
          />
        </Flex>
      </Grid.Col>
      <Grid.Col pt={0} span={{ xs: 12, sm: 6, md: 6, lg: 6, lx: 6 }}>
        <Flex align="center">
          <Text style={{ flex: "0 0 110px", whiteSpace: "nowrap" }}>
            Họ và tên
          </Text>
          <Select
            searchable
            clearable
            flex={1}
            error={
              !handlePayertype && formErrors.funfullname
                ? formErrors.funfullname
                : undefined
            }
            disabled={!!handlePayertype || statusReponse}
            placeholder="Vui lòng chọn họ và tên"
            comboboxProps={{
              transitionProps: {
                transition: "pop",
                duration: 200,
              },
            }}
            limit={50}
            value={
              listEmployee?.find(
                (item: any) => item.value === formData.funfullname
              )?.value || null
            }
            data={listEmployee.map((item) => {
              const formatUnder = formatFullName(item.text);
              return {
                label: formatUnder, // Label sẽ hiển thị trên UI
                value: String(item.value ?? null), // Value sẽ là giá trị được lưu
              };
            })}
            onClick={async () => {
              if (!listEmployee || listEmployee.length < 1) {
                await fetchDataEmployee();
              }
            }}
            onChange={(selectedValue) => {
              if (!selectedValue) {
                handleChange("funfullname", null);
              } else {
                const selectedOption = listEmployee.find(
                  (item) => item.value === selectedValue
                );
                if (selectedOption) {
                  handleChange("funfullname", selectedOption.value);
                }
              }
            }}
          />
        </Flex>
      </Grid.Col>
      <Grid.Col pt={0} span={{ xs: 12, sm: 6, md: 6, lg: 6, lx: 6 }}>
        <Flex align="center">
          <Text style={{ flex: "0 0 110px", whiteSpace: "nowrap" }}>
            ĐĐ Làm việc
          </Text>
          <Select
            searchable
            clearable
            flex={1}
            error={
              !handlePayertype && formErrors.fundworkplace
                ? formErrors.fundworkplace
                : undefined
            }
            disabled={
              statusReponse
                ? statusReponse
                : !handlePayertype && changeFielselect !== "-1"
                ? false
                : true
            }
            value={
              valueDataLocation?.find(
                (item: any) => item === formData.fundworkplace
              ) || null
            }
            placeholder="Vui lòng chọn ĐĐ làm việc"
            data={valueDataLocation?.map((e: any) => e)}
            onChange={(value) => {
              if (value) {
                handleChange("fundworkplace", value);
              }
            }}
          />
        </Flex>
      </Grid.Col>
      <Grid.Col pt={0} span={{ xs: 12, sm: 6, md: 6, lg: 6, lx: 6 }}>
        <Flex align="center">
          <Text style={{ flex: "0 0 110px", whiteSpace: "nowrap" }}>
            Chức danh
          </Text>
          <Select
            flex={1}
            error={
              !handlePayertype && formErrors.fundbranch
                ? formErrors.fundbranch
                : undefined
            }
            disabled={
              statusReponse
                ? statusReponse
                : !handlePayertype && changeFielselect !== "-1"
                ? false
                : true
            }
            placeholder="Vui lòng chọn chức danh"
            data={[
              {
                value: String(listTblDmEmployee?.roleId) ?? "",
                label: listTblDmEmployee?.roleName ?? "",
              },
            ]}
            onChange={(selectedDescription) => {
              if (!selectedDescription) {
                handleChange("fundbranch", null);
              } else {
                handleChange("fundbranch", selectedDescription);
              }
            }}
          />
        </Flex>
      </Grid.Col>
    </>
  );

  const formOther = () => (
    <>
      <Grid.Col pt={0} span={{ base: 12, xs: 12, sm: 6, md: 6, lg: 6, lx: 6 }}>
        <Flex align="center">
          <Text style={{ flex: "0 0 110px", whiteSpace: "nowrap" }}>
            Mã KH/NCC
          </Text>
          <Select
            searchable
            clearable
            flex={1}
            error={
              handlePayertype && !handleOther && formErrors.codeCustomer
                ? formErrors.codeCustomer
                : undefined
            }
            disabled={
              statusReponse ? statusReponse : !handlePayertype || handleOther
            }
            value={
              customerOrders?.find(
                (item: any) => item.id === formData.codeCustomer
              )?.name || null
            }
            placeholder="Vui lòng chọn Mã KH/NCC"
            data={
              customerOrders
                ?.map((e: any) => e.name)
                .filter((name: string): name is string => !!name) ?? []
            }
            onClick={async () => await fetchData()}
            onChange={(selectedDescription) => {
              if (!selectedDescription) {
                handleChange("codeCustomer", null);
                handleChange("anotherName", null);
                handleChange("phoneNumber", null);
              } else {
                const selectedOption = customerOrders?.find(
                  (item: any) => item.name === selectedDescription
                );
                if (selectedOption) {
                  handleChange("codeCustomer", selectedOption.id);
                  handleChange("anotherName", selectedOption.details.fullName);
                  handleChange(
                    "phoneNumber",
                    selectedOption.details.phoneNumber
                  );
                }
              }
            }}
          />
        </Flex>
      </Grid.Col>
      <Grid.Col pt={0} span={{ base: 12, xs: 12, sm: 6, md: 6, lg: 6, lx: 6 }}>
        <Flex align="center">
          <Text style={{ flex: "0 0 110px", whiteSpace: "nowrap" }}>Tên</Text>
          <TextInput
            flex={1}
            disabled={!handlePayertype || !handleOther}
            error={
              handlePayertype && formErrors.anotherName
                ? formErrors.anotherName
                : undefined
            }
            value={formData.anotherName || ""}
            onChange={(e) => {
              if (!e) {
                handleChange("anotherName", null);
              } else {
                handleChange("anotherName", e.target.value);
              }
            }}
            w={185}
            placeholder="Vui lòng nhập tên"
          />
        </Flex>
      </Grid.Col>
      <Grid.Col pt={0} span={{ base: 12, xs: 12, sm: 6, md: 6, lg: 6, lx: 6 }}>
        <Flex align="center">
          <Text style={{ flex: "0 0 110px", whiteSpace: "nowrap" }}>
            Mã công nợ
          </Text>
          <TextInput
            placeholder="Vui lòng chọn mã nhóm công nợ"
            flex={1}
            disabled={statusReponse}
            value={inputValueDept}
            w={185}
            // onClick={openFormDebt}
            rightSection={
              statusReponse ? null : inputValueDept ? (
                <ActionIcon
                  onClick={() => {
                    setInputValueDept("");
                    setHandelAttrible(true);
                  }}
                >
                  <IconX size={16} />
                </ActionIcon>
              ) : null
            }
            onChange={(e) => e.currentTarget.value}
          />
        </Flex>
      </Grid.Col>
      <Grid.Col
        pt={0}
        offset={{ base: 0, xs: 0, sm: 6, md: 6, lg: 6, lx: 6 }}
        span={{ base: 12, xs: 12, sm: 6, md: 6, lg: 6, lx: 6 }}
      >
        <Flex align="center">
          <Text style={{ flex: "0 0 110px", whiteSpace: "nowrap" }}>
            Số điện thoại
          </Text>
          <NumberInput
            flex={1}
            w={185}
            error={
              handlePayertype && formErrors.phoneNumber
                ? formErrors.phoneNumber
                : undefined
            }
            disabled={!handlePayertype || !handleOther}
            thousandSeparator
            value={formData.phoneNumber || ""}
            placeholder="Nhấp số điện thoại"
            hideControls
            onChange={(e) => {
              if (!e) {
                handleChange("phoneNumber", null);
              } else {
                handleChange("phoneNumber", e);
              }
            }}
          />
        </Flex>
      </Grid.Col>
    </>
  );

  const fetchCreateReceiptParent = async (handleSave?: boolean) => {
    let isValid = true;
    const errors = {
      payerfullname: "",
      accountfund: 0,
      codefund: "",
      createby: 0,
      createdDate: "",
      creatorbranch: "",
      creatordepartment: "",
      creatorfullname: "",
      creatorsalesbranch: "",
      creatorworkplace: "",
      fundbranch: "",
      bankname: "",
      accountnumber: "",
      funddepartment: "",
      fundsalesbranch: "",
      fundworkplace: "",
      funfullname: "",
      id: 0,
      payerdescription: "",
      codeCustomer: "",
      anotherName: "",
      phoneNumber: "",
      payernote: "",
      payertype: "",
      receiptNumber: "",
      totalAmount: "",
      type: 0,
    };

    if (!handlePayertype) {
      if (!formData.fundbranch) {
        errors.fundbranch = "Vui lòng chọn chức danh!";
        isValid = false;
      }

      if (!formData.fundworkplace) {
        errors.fundworkplace = "Vui lòng chọn địa điểm làm việc!";
        isValid = false;
      }

      if (!formData.bankname) {
        errors.bankname = "Vui lòng chọn ngân hàng!";
        isValid = false;
      }

      if (!formData.accountnumber) {
        errors.accountnumber = "Vui lòng chọn tài khoản!";
        isValid = false;
      }

      if (!formData.fundworkplace) {
        errors.fundworkplace = "Vui lòng chọn địa điểm làm việc!";
        isValid = false;
      }

      if (!formData.funfullname) {
        errors.funfullname = "Vui lòng chọn họ và tên!";
        isValid = false;
      }

      if (!formData.funddepartment) {
        errors.funddepartment = "Vui lòng chọn phòng ban!";
        isValid = false;
      }
    } else {
      if (!handleOther) {
        if (!formData.codeCustomer) {
          errors.codeCustomer = "Vui lòng chọn mã KH/NCC!";
          isValid = false;
        }
      }
      if (formData.payertype !== "NCC" && formData.payertype !== "Khách hàng") {
        if (!formData.anotherName) {
          errors.anotherName = "Vui lòng nhập tên!";
          isValid = false;
        }
        if (!formData.phoneNumber) {
          errors.phoneNumber = "Vui lòng nhập số điện thoại!";
          isValid = false;
        }
      }
    }

    if (!formData.codefund) {
      errors.codefund = "Vui lòng chọn mã phí!";
      isValid = false;
    }

    if (!formData.payerdescription) {
      errors.payerdescription = "Vui lòng nhập nội dung!";
      isValid = false;
    }

    if (!formData.totalAmount) {
      errors.totalAmount = "Vui lòng nhập số tiền!";
      isValid = false;
    }

    setFormErrors(errors);

    if (!isValid) {
      notifications.show({
        title: "Thông báo",
        message: "Vui lòng nhập đầy đủ thông tin.",
        autoClose: 3000,
        color: "red",
        icon: <IconX />,
      });
      return;
    }

    setIsRunning(false);

    const requestData: CreateReceiptParentRequest = {
      paymentReceiptDetailCommand: {
        id: 0,
        accountfund: formData.accountfund,
        codefund: formData.codefund,
        createby: formData.createby,
        createdDate: currentTime,
        bankname: formData.bankname,
        accountnumber: formData.accountnumber,
        // completiontime: currentTime,
        createrolename: String(userData?.roleId),
        creatorbranch: formData.creatorbranch,
        creatordepartment: userData?.departmentId,
        creatorworkplace: String(branchDeparment?.address),
        creatorfullname: `${userName}-${userData?.code}`,
        creatorsalesbranch: formData.creatorsalesbranch,
        fundbranch: formData.fundbranch,
        funddepartment: formData.funddepartment,
        fundsalesbranch: formData.fundsalesbranch,
        fundworkplace: formData.fundworkplace,
        funfullname: formData.funfullname,
        payerdescription: formData.payerdescription,
        payerfullname: formData.payerfullname,
        payernote: formData.payernote,
        anotherName: formData.anotherName,
        phoneNumber: formData.phoneNumber,
        codeCustomer: formData.codeCustomer,
        payertype: formData.payertype,
        receiptNumber: dataGetReceiptParent?.receiptNumber,
        totalAmount: changeValueTotal,
        attribute2: handelAttrible ? "" : dataGetReceiptParent?.id, // Mã công nợ
        attribute3: formData.attribute3, //Phòng ban
        type: formData.type,
        typeReceipt: dataGetReceiptParent?.typeReceipt,
      },
      save: handleSave,
    };

    try {
      const response = await repositoryPos.post<
        MessageResponse<CreateReceiptParentRequest>
      >("/api/v1/CreateSellItem/create-receipt-paid-parent", requestData);

      if (response && response.success) {
        notifications.show({
          title: "Thành công",
          message: "Phiếu báo có đã được lưu thành công!",
          autoClose: 3000,
          color: "green",
          icon: <IconCheck />,
        });
        setStatusReponse(response.success);
      } else {
        notifications.show({
          title: "Thất bại",
          message: "Lưu phiếu báo có không thành công. Vui lòng thử lại!",
          autoClose: 3000,
          color: "red",
          icon: <IconX />,
        });
      }
    } catch (error) {
      console.error("Error creating receipt:", error);
    }
  };

  const fetchData = async () => {
    let url = `?Skip=0&Take=50`;

    // if (search.keySearch) {
    //   url += `&KeySearch=${search.keySearch}`;
    // }

    try {
      const repository =
        formData.payertype === "NCC" ? repositoryMdm : repositoryPos;
      const response = await repository.get(
        formData.payertype === "NCC"
          ? "/api/v1/TblVendor/get-all"
          : `/api/v1/TblDmCustomer/get-list${url}`
      );
      if (response && response.success) {
        const result = response.data;

        const mappedData: DepartmentCustomerOrder[] = result.map(
          formData.payertype === "NCC"
            ? (customer: any) => ({
                id: customer.id,
                name: customer.vendorCode,
                details: {
                  fullName: customer.vendorName,
                  phoneNumber: customer.phone ?? "",
                },
              })
            : (customer: any) => ({
                id: customer.id,
                name: customer.code + " - " + customer.name,
                details: {
                  fullName: customer.name,
                  phoneNumber: customer.telephoneNumber,
                },
              })
        );

        console.log(mappedData);
        setCustomerOrders(mappedData);
      }
    } catch (error) {
      console.error("Error fetching customer data:", error);
    }
  };

  const fetchDataDepartments = async () => {
    const response = await repositoryPos.get<
      MessageResponse<ExampleInterface[]>
    >("/api/v1/TblDepartment/get-select");

    if (response && response.success) {
      let result = response.data;

      const newDepartment = {
        att1: null,
        att2: null,
        att3: 0,
        att4: 0,
        att5: null,
        disabled: false,
        group: null,
        selected: false,
        text: "Khác",
        value: "-1",
      };

      const updatedDepartments = [...result, newDepartment];
      setDataGetDepartments(updatedDepartments);
    }
  };

  const fetchDataReceiptParent = async () => {
    const response = await repositoryPos.get<
      MessageResponse<TblDMPaymentMethod[]>
    >("/api/v1/CreateSellItem/create-receipt-paid-parent?typeReceipt=BC");

    if (response && response.success) {
      let result = response.data;
      setDataGetReceiptParent(result);
    }
  };

  const getTblDmExpense = async () => {
    const response = await repositoryMdm.get<
      MessageResponse<SelectListItemBase[]>
    >("/api/v1/TblDmExpense/get-select");

    if (response && response.success) {
      let result = response.data;
      setDataCodeFunch(result);
    }
  };

  const onChangeTimeCurrent = () => {
    setHandelDateCurrent(true);
  };

  const fetchDataEmployee = async () => {
    const response = await repositoryPos.get<MessageResponse<any>>(
      "/api/v1/TblDmEmployee/get-select"
    );

    if (response && response.success) {
      let result = response.data;
      setListEmployee(result);
    }
  };

  useEffect(() => {
    fetchDataReceiptParent();
  }, []);

  useEffect(() => {
    const defaultItem = dataExchangeRate.find(
      (item) => item.calculation === selectedCalculation
    );
    setSelectedValue(defaultItem?.value ?? null);
  }, [selectedCalculation]);

  useEffect(() => {
    if (isRunning) {
      const intervalId = setInterval(() => {
        const currentTime = dayjs().format("YYYY-MM-DDTHH:mm:ss");
        setCurrentTime(currentTime);
      }, 1000);

      return () => clearInterval(intervalId);
    }
  }, [isRunning]);

  const fetchDataDepart = async () => {
    try {
      const response = await repositoryPos.post<
        MessageResponse<DepartmentUser>
      >(`/api/v1/TblDepartment/detail?id=${userData?.departmentId}`);

      if (response && response.success) {
        const result = response.data;
        setBranchDeparment(result);
      } else {
        console.error("Failed to fetch department details", response?.message);
      }
    } catch (error) {
      console.error("Error fetching department details", error);
    }
  };

  const fetchDataTblDmEmployee = async () => {
    const response = await repositoryPos.get<MessageResponse<any>>(
      `/api/v1/TblDmEmployee/detail-seft?empId=${formData.funfullname}`
    );

    if (response && response.success) {
      let result = response.data;
      setListTblDmEmployee(result);
    }
  };

  useEffect(() => {
    if (userData?.departmentId) {
      fetchDataDepart();
    }
  }, [userData?.departmentId]);

  useEffect(() => {
    const headerHeight = headerRef.current?.offsetHeight || 0;
    const handleResize = () => {
      setHeight(window.innerHeight - (185 + headerHeight));
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [height]);

  useEffect(() => {
    if (formData.funfullname) {
      fetchDataTblDmEmployee();
    }
  }, [formData.funfullname]);

  useEffect(() => {
    const data = getDataFromLocalStorage("userLogin");
    if (data) {
      setUserData(data);
    }
  }, []);

  return (
    <>
      <Box>
        <Box pl={20} pr={0} bg="#fff" ref={headerRef}>
          <header>
            <BreadcrumbLayout1 />
            <>
              <Button
                size="sm"
                w={100}
                color="blue"
                leftSection={<IconLoader3 size={14} />}
                variant="filled"
                onClick={() => {
                  navigate("/bill/credit-advice");
                  window.location.reload();
                }}
              >
                Tạo mới
              </Button>
            </>
          </header>
        </Box>
        <Divider my="md" mb={0} />
        <Box p={15} bg={"#f8f8f8"}>
          <Grid gutter="xs">
            <Grid.Col span={{ base: 12, md: 6, lg: 6 }}>
              <Card
                shadow="sm"
                radius="md"
                withBorder
                p="lg"
                pr={0}
                pt={15}
                style={{
                  maxHeight: height + 55,
                  minHeight: height + 55,
                }}
              >
                <ScrollArea w="100%" pr={5} h={height + 600} offsetScrollbars>
                  <Grid>
                    <Grid.Col pb={0} span={{ base: 12, md: 12, lg: 6 }}>
                      <Flex>
                        <Text
                          style={{ flex: "0 0 110px", whiteSpace: "nowrap" }}
                        >
                          Mã báo có
                        </Text>
                        <Text fw={600}>
                          {formatReceiptNumber(
                            dataGetReceiptParent?.receiptNumber || ""
                          )}
                        </Text>
                      </Flex>
                    </Grid.Col>
                    <Grid.Col pb={0} span={{ base: 12, md: 12, lg: 6 }}>
                      <Flex>
                        <Text
                          style={{ flex: "0 0 110px", whiteSpace: "nowrap" }}
                        >
                          Thời gian tạo
                        </Text>
                        <Text fw={500} style={{ whiteSpace: "nowrap" }}>
                          <Text fw={500} style={{ whiteSpace: "nowrap" }}>
                            {formatDateTransferLastYear(currentTimeDelay)}
                          </Text>
                        </Text>
                      </Flex>
                    </Grid.Col>
                    <Grid.Col
                      span={{ base: 12, md: 12, lg: 6 }}
                      offset={{ base: 0, md: 0, lg: 6 }}
                      pb={0}
                    >
                      <Flex>
                        <Text
                          style={{ flex: "0 0 110px", whiteSpace: "nowrap" }}
                        >
                          Thời gian HT
                        </Text>
                        <Text fw={500} style={{ whiteSpace: "nowrap" }}>
                          {handelDateCurrent && statusReponse ? (
                            <>
                              {moment(currentTime).format("DD-MM-YY HH:mm:ss")}
                            </>
                          ) : null}
                        </Text>
                      </Flex>
                    </Grid.Col>
                    <Grid.Col pt={0} pb={0} span={12}>
                      <Divider
                        my="xs"
                        label={
                          <Text size="lg" fw={600} c="#000">
                            Người lập phiếu thu
                          </Text>
                        }
                        labelPosition="left"
                      />
                    </Grid.Col>
                    <Grid.Col pt={0} span={{ base: 12, md: 12, lg: 6 }}>
                      <Flex>
                        <Text
                          style={{ flex: "0 0 110px", whiteSpace: "nowrap" }}
                        >
                          Phòng ban
                        </Text>
                        <Text fw={600} style={{ whiteSpace: "nowrap" }}>
                          {`${userData?.depName}`}
                        </Text>
                      </Flex>
                    </Grid.Col>
                    <Grid.Col pt={0} span={{ base: 12, md: 12, lg: 6 }}>
                      <Flex>
                        <Text
                          style={{ flex: "0 0 110px", whiteSpace: "nowrap" }}
                        >
                          Họ và tên
                        </Text>
                        <Text fw={600} style={{ whiteSpace: "nowrap" }}>
                          {`${userName}-${userData?.code}`}
                        </Text>
                      </Flex>
                    </Grid.Col>
                    <GridCol
                      pt={0}
                      pb={0}
                      span={{ base: 6, xs: 6, sm: 6, md: 6, lg: 6, lx: 6 }}
                    >
                      <Flex>
                        <Text
                          style={{ flex: "0 0 110px", whiteSpace: "nowrap" }}
                        >
                          ĐĐ làm việc
                        </Text>
                        <Text fw="600">{branchDeparment?.address ?? ""}</Text>
                      </Flex>
                    </GridCol>
                    <Grid.Col pt={0} span={{ base: 12, md: 12, lg: 6 }}>
                      <Flex>
                        <Text
                          style={{ flex: "0 0 110px", whiteSpace: "nowrap" }}
                        >
                          Chức danh
                        </Text>
                        <Text fw="600">{userData?.roleName}</Text>
                      </Flex>
                    </Grid.Col>
                    <Grid.Col pt={0} pb={0} span={12}>
                      <Divider
                        my="xs"
                        label={
                          <Text size="lg" fw={600} c="#000">
                            Người nộp tiền
                          </Text>
                        }
                        labelPosition="left"
                      />
                    </Grid.Col>
                    <Grid.Col pt={0} pb={0} span={12}>
                      <EditableTextWithOptions
                        required
                        label="Loại"
                        readOnly={statusReponse ? true : false}
                        value={formData.payertype}
                        options={["Nội bộ", "Khách hàng", "NCC", "Khác"]}
                        onChange={(value) => {
                          handleChange("payertype", value);
                          handleChange("funfullname", null);
                          handleChange("funddepartment", null);
                          handleChange("fundbranch", null);
                          handleChange("fundworkplace", null);
                          handleChange("codeCustomer", null);
                          handleChange("anotherName", "");
                          handleChange("phoneNumber", "");
                          setFormErrors((prevErrors) => ({
                            ...prevErrors,
                            anotherName: "",
                            fundbranch: "",
                            fundworkplace: "",
                            funfullname: "",
                            funddepartment: "",
                            phoneNumber: "",
                            codeCustomer: "",
                          }));
                        }}
                        width="100px"
                      />
                    </Grid.Col>
                    <Grid.Col pb={0} span={12}>
                      <Divider
                        my="xs"
                        label={
                          <Text size="lg" fw={600} c="#000">
                            Nội bộ
                          </Text>
                        }
                        labelPosition="left"
                      />
                    </Grid.Col>
                    {formInternal()}
                    <Grid.Col pt={0} pb={0} span={12}>
                      <Divider
                        my="xs"
                        label={
                          <Text size="lg" fw={600} c="#000">
                            Khác
                          </Text>
                        }
                        labelPosition="left"
                      />
                    </Grid.Col>
                    {formOther()}
                  </Grid>
                </ScrollArea>
              </Card>
            </Grid.Col>
            <Grid.Col span={{ base: 12, md: 6, lg: 6 }}>
              <Card
                shadow="sm"
                radius="md"
                withBorder
                pr={0}
                p="md"
                style={{
                  backgroundColor: "#fff",
                  borderRadius: "10px",
                  padding: "20px",
                  boxShadow: "0 1px 10px rgba(0, 0, 0, 0.1)",
                  maxHeight: height + 55,
                  minHeight: height + 55,
                }}
              >
                <ScrollArea w="100%" h={height + 600} offsetScrollbars>
                  <Group mb={20}>
                    <Flex w="100%" align="center" gap={10}>
                      <Text style={{ flex: "0 0 80px", whiteSpace: "nowrap" }}>
                        Tên ngân hàng
                      </Text>
                      <Select
                        searchable
                        flex={1}
                        clearable
                        error={
                          !handlePayertype && formErrors.bankname
                            ? formErrors.bankname
                            : undefined
                        }
                        disabled={statusReponse}
                        value={
                          banks?.find(
                            (item: any) => item.value === formData.bankname
                          )?.text || null
                        }
                        placeholder="Chọn ngân hàng"
                        data={
                          banks
                            ?.map((e: any) => e.text)
                            .filter((text): text is string => !!text) ?? []
                        }
                        onChange={(selectedDescription) => {
                          if (!selectedDescription) {
                            handleChange("bankname", null);
                          } else {
                            const selectedOption = banks?.find(
                              (item: any) => item.text === selectedDescription
                            );
                            if (selectedOption) {
                              handleChange("bankname", selectedOption.value);
                            }
                          }
                        }}
                      />
                      {/* <TextInput
                        size="sm"
                        w="100%"
                        onChange={(e) =>
                          handleChange("bankname", e.currentTarget.value)
                        }
                        rightSection={<IconMinus size={"12px"} />}
                      /> */}
                    </Flex>
                  </Group>
                  <Group>
                    <Flex w="100%" align="center" gap={20}>
                      <Text style={{ flex: "0 0 80px", whiteSpace: "nowrap" }}>
                        STK
                      </Text>
                      <Select
                        flex={1}
                        clearable
                        error={
                          !handlePayertype && formErrors.accountnumber
                            ? formErrors.accountnumber
                            : undefined
                        }
                        disabled={statusReponse}
                        placeholder="Nhập số tài khoản"
                        data={vietnamBanks.map((e) => e.accountNumber)}
                        onChange={(e) => handleChange("accountnumber", e)}
                      />
                    </Flex>
                  </Group>
                  <Group mt={10}>
                    <Text mb={15} fw={600}>
                      Tài khoản quỹ <span style={{ color: "red" }}>*</span>
                    </Text>
                    <Table
                      mt={-10}
                      layout="fixed"
                      withTableBorder
                      withColumnBorders
                    >
                      <Table.Tbody>
                        <Table.Tr>
                          <Table.Td w={270}>
                            <EditableTextWithOptions
                              readOnly={statusReponse ? true : false}
                              label="Đơn vị tính"
                              value={
                                dataExchangeRate?.find(
                                  (item: any) =>
                                    item.id === formData.accountfund
                                )?.calculation || "VND"
                              }
                              options={
                                dataExchangeRate
                                  ?.map((e: any) => e.calculation)
                                  .filter(
                                    (calculation): calculation is string =>
                                      !!calculation
                                  ) ?? []
                              }
                              onChange={(selectedDescription) => {
                                const selectedOption = dataExchangeRate?.find(
                                  (item: any) =>
                                    item.calculation === selectedDescription
                                );
                                if (selectedOption) {
                                  handleChange(
                                    "accountfund",
                                    selectedOption.id
                                  );
                                  handleChangeRate(selectedOption.calculation);
                                }
                              }}
                            />
                          </Table.Td>
                          <Table.Td>
                            <Flex align="center" wrap="wrap">
                              <Text flex="0 0 90px">Tỉ giá</Text>
                              <NumberFormatter
                                style={{ fontWeight: "bold" }}
                                value={selectedValue}
                                thousandSeparator
                              />
                            </Flex>
                          </Table.Td>
                          <Table.Td>
                            <Flex wrap="wrap">
                              <Text style={{ flex: "0 0 100px" }}>
                                Thành tiền{" "}
                              </Text>
                              <NumberFormatter
                                value={
                                  (changeValueTotal ?? 0) * (selectedValue ?? 0)
                                }
                                style={{
                                  fontSize: "15px",
                                  fontWeight: 600,
                                  color: "red",
                                }}
                                thousandSeparator
                              />
                            </Flex>
                          </Table.Td>
                        </Table.Tr>
                      </Table.Tbody>
                    </Table>
                  </Group>
                  <Group mt={20}>
                    <Flex w="100%" align="center" gap={20}>
                      <Text style={{ flex: "0 0 80px", whiteSpace: "nowrap" }}>
                        Mã phí <span style={{ color: "red" }}>*</span>
                      </Text>
                      <Select
                        w={200}
                        searchable
                        clearable
                        disabled={statusReponse ? true : false}
                        data={dataCodeFund
                          .map((e) => e.text)
                          .filter((text): text is string => !!text)}
                        error={formErrors.codefund}
                        placeholder="Vui lòng chọn mã phí"
                        onChange={(selectedDescription) => {
                          if (selectedDescription === null) {
                            handleChange("codefund", null);
                          } else {
                            const selectedOption = dataCodeFund?.find(
                              (item) => item.text === selectedDescription
                            );
                            if (selectedOption) {
                              handleChange("codefund", selectedOption.value);
                            }
                          }
                        }}
                        onClick={async () => await getTblDmExpense()}
                      />
                    </Flex>
                  </Group>
                  <Group mt={20}>
                    <Flex w="100%" align="center" gap={20}>
                      <Text style={{ flex: "0 0 80px", whiteSpace: "nowrap" }}>
                        Nội dung <span style={{ color: "red" }}>*</span>
                      </Text>
                      <Textarea
                        size="sm"
                        w="100%"
                        disabled={statusReponse ? true : false}
                        error={formErrors.payerdescription}
                        placeholder="Nhập nội dung"
                        onChange={(e) =>
                          handleChange(
                            "payerdescription",
                            e.currentTarget.value
                          )
                        }
                        rightSection={<IconMinus size={"12px"} />}
                      />
                    </Flex>
                  </Group>
                  <Group mt={20}>
                    <Flex w="60%" align="center" gap={20}>
                      <Text
                        style={{ flex: "0 0 80px", whiteSpace: "nowrap" }}
                        fw={600}
                        c="red"
                      >
                        Tổng tiền <span style={{ color: "red" }}>*</span>
                      </Text>
                      <NumberInput
                        size="sm"
                        w={200}
                        disabled={statusReponse ? true : false}
                        error={formErrors.totalAmount}
                        allowNegative={false}
                        thousandSeparator=","
                        placeholder="Nhập số tiền"
                        hideControls
                        value={changeValueTotal}
                        rightSection={<IconMinus size={12} />}
                        onChange={(value) => handleChange("totalAmount", value)}
                      />
                    </Flex>
                  </Group>
                </ScrollArea>
                <Group justify="flex-end">
                  <Flex mr={15} justify="end" direction="column">
                    <Group mt={15} justify="flex-end">
                      <Button
                        variant="filled"
                        disabled={statusReponse}
                        onClick={() => {
                          fetchCreateReceiptParent(true);
                          setStoppedTime(currentTime);
                          onChangeTimeCurrent();
                        }}
                        leftSection={<IconCheck size={14} />}
                        color="#5a47b0"
                      >
                        Hoàn thành
                      </Button>
                      <Button
                        size="sm"
                        variant="outline"
                        w={100}
                        leftSection={<IconNotes size={18} />}
                        disabled={statusReponse}
                        onClick={() => {
                          fetchCreateReceiptParent();
                        }}
                        color="#5a47b0"
                      >
                        Lưu
                      </Button>
                    </Group>
                  </Flex>
                </Group>
              </Card>
            </Grid.Col>
          </Grid>
        </Box>
      </Box>
    </>
  );
};

export default CreditAdvice;
