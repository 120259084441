import React, { useEffect, useState } from "react";
import { repositoryDelivery } from "../../_base/_const/_constVar";
import { MessageResponse } from "../../model/MessageResponse";
import { TblTestingTechniqueHeaderGetList } from "../../model/TblTestingTechniqueHeader";
import {
  Box,
  Button,
  Divider,
  Grid,
  GridCol,
  Group,
  Text,
  TextInput,
} from "@mantine/core";
import { gray } from "../../const/variables";
import { modals } from "@mantine/modals";
import { useDisclosure } from "@mantine/hooks";
import { IconWindow } from "@tabler/icons-react";
import ListProductDetail from "./ListProductDetail";

const ModalDetailTestingTechnique = ({ id }: { id: string | number }) => {
  const [visible, { toggle, close, open }] = useDisclosure(false);
  const [dataTestingTechnique, setDataTestingTechnique] =
    useState<TblTestingTechniqueHeaderGetList | null>(null);
  console.log(dataTestingTechnique);

  const fetchDataDetail = async () => {
    const url = `/api/v1/TblTestingTechniqueHeader/get-detail?id=${id}`;
    open();
    try {
      const response = await repositoryDelivery.get<
        MessageResponse<TblTestingTechniqueHeaderGetList>
      >(url);
      if (response?.success && response?.data) {
        setDataTestingTechnique(response.data);
      } else setDataTestingTechnique(null);
    } catch (error) {
      console.error("Error fetching data:", error);
      setDataTestingTechnique(null);
    }
    close();
  };

  useEffect(() => {
    fetchDataDetail();
  }, [id]);

  return (
    <Box>
      <Grid>
        <GridCol span={{ base: 12, md: 6, lg: 2 }}>
          <TextInput
            label="Mã đơn hàng"
            size="xs"
            // disabled
            readOnly
            value={dataTestingTechnique?.code || ""}
          />
        </GridCol>

        <GridCol span={{ base: 12, md: 6, lg: 2 }}>
          <TextInput
            label="Tên khách hàng"
            size="xs"
            // disabled
            readOnly
            value={dataTestingTechnique?.customerName || ""}
          />
        </GridCol>
        <GridCol span={{ base: 12, md: 6, lg: 2 }}>
          <TextInput
            label="Số điện thoại"
            size="xs"
            // disabled
            readOnly
            value={dataTestingTechnique?.customerTelephone || ""}
          />
        </GridCol>

        {/* <GridCol span={{ base: 12, md: 6, lg: 2 }}>
          <TextInput
            label="Chi nhánh"
            size="xs"
            // disabled
            readOnly
            value={dataTestingTechnique?.branchName || ""}
          />
        </GridCol> */}

        <GridCol span={{ base: 12, md: 6, lg: 2 }}>
          <TextInput
            label="Trạng thái đơn hàng"
            size="xs"
            // disabled
            readOnly
            value={dataTestingTechnique?.sourceStatus || ""}
          />
        </GridCol>
        <GridCol span={{ base: 12, md: 6, lg: 2 }}>
          <TextInput
            label="Trạng thái kiểm tra"
            size="xs"
            // disabled
            readOnly
            value={dataTestingTechnique?.testingStatus || ""}
          />
        </GridCol>
        <GridCol span={{ base: 12, md: 6, lg: 2 }}>
          <TextInput
            label="Ghi chú"
            size="xs"
            // disabled
            readOnly
            value={dataTestingTechnique?.description || ""}
          />
        </GridCol>
      </Grid>

      <Divider
        mb={10}
        label={
          <Text fw={500} size="13px">
            Thông tin sản phẩm
          </Text>
        }
        labelPosition="center"
      />
      <ListProductDetail
        dataDetail={dataTestingTechnique?.tblTestingTechniqueDetailModels}
      />

      <Group
        justify="end"
        mt="xs"
        style={{
          position: "sticky",
          bottom: 0,
          backgroundColor: "white",
        }}
      >
        <Button
          type="button"
          color={gray.base}
          loading={visible}
          onClick={() => {
            modals.closeAll();
          }}
          leftSection={!visible ? <IconWindow size={18} /> : undefined}
        >
          Đóng
        </Button>
      </Group>
    </Box>
  );
};

export default ModalDetailTestingTechnique;
