import {
  Badge,
  Box,
  Button,
  ComboboxItem,
  Divider,
  Fieldset,
  Flex,
  Grid,
  LoadingOverlay,
  Menu,
  Select,
  TextInput,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import {
  useDebouncedCallback,
  useDebouncedState,
  useDisclosure,
} from "@mantine/hooks";
import { IconArrowBack, IconCheck } from "@tabler/icons-react";
import { useEffect, useState } from "react";
import { TblDmWarrantyReceiveTicket } from "../../../model/TblDmWarrantyReceiveTicket";
import { repositoryMdm, repositoryPos } from "../../../_base/_const/_constVar";
import { MessageResponse } from "../../../model/MessageResponse";
import {
  getTblDmEmployeeSelect,
  getTblDMInventory,
} from "../../../service/getSelectApi";
import {
  getValueById,
  handleKeyDownPhone,
} from "../../../_base/helper/FunctionHelper";
import ReceiveWarrantyList from "./ReceiveWarrantyList";
import { TblWarrantyServiceRequestForm } from "../../../model/TblWarrantyServiceRequestForm";
import { DateTimePicker } from "@mantine/dates";
import ProductWarrantyList from "./ProductWarrantyList";
import { NotificationExtension } from "../../../_base/extension/NotificationExtension";

const EditReceiptForm = ({ id, onClose, onSuccess }: EditReceiptFormProps) => {
  const entity = {
    id: "0",
    empId: null,
    requestId: null,
    errorNumber: null,
    dateReceive: new Date().toString(),
    inventoryId: null,
    note: null,
    tblWarrantyReceiveTicketDetailCommands: null,
  };

  const form = useForm<TblDmWarrantyReceiveTicket>({
    mode: "uncontrolled",
    validateInputOnChange: true,
    initialValues: {
      ...entity,
    },

    validate: {
      inventoryId: (value: number | null) => {
        if (!value) {
          return "Vui lòng nhập tên trung tâm !";
        }
      },
      empId: (value: number | null) => {
        if (!value) {
          return "Vui lòng nhập tên người tiếp nhận !";
        }
      },
    },
  });

  const [dataInventorySelect, setDataInventorySelect] = useState<
    ComboboxItem[]
  >([]);
  const [dataEmployeeSelect, setDataEmployeeSelect] = useState<ComboboxItem[]>(
    []
  );
  const [dataEmployeeDetail, setDataEmployeeDetail] = useState<any>();
  const [dataDepartment, setDataDepartment] = useState<ComboboxItem[]>([]);
  const [dataRole, setDataRole] = useState<ComboboxItem[]>([]);
  const [dataRequestForm, setDataRequestForm] = useState<any>();
  const [dataRequestFormSelected, setDataRequestFormSelected] = useState<any>();
  console.log(dataRequestFormSelected);
  const [phoneNumber, setPhoneNumber] = useDebouncedState("", 300);

  const [editAndSave, setEditAndSave] = useState(false);
  const [isEdit, setIsEdit] = useState({
    uomId: false,
    acessory: false,
    testResolution: false,
    serviceType: false,
    scheduleTime: false,
    daysReturn: false,
    note: false,
  });
  const [visible, { toggle, close, open }] = useDisclosure(false);

  const warrantyReceiveTicketDetail =
    form.getValues().tblWarrantyReceiveTicketDetailCommands ?? [];

  const handleChangeValue = useDebouncedCallback(
    (key: string, value: any, index: number) => {
      form.setValues((prevData: any) => {
        const prevDataSubmit = Array.isArray(
          prevData.tblWarrantyReceiveTicketDetailCommands
        )
          ? prevData.tblWarrantyReceiveTicketDetailCommands
          : [];

        if (prevDataSubmit[index]?.[key] === value) {
          return prevData;
        }

        const updatedModels = [...prevDataSubmit];
        updatedModels[index] = {
          ...updatedModels[index],
          [key]: value,
        };

        return {
          ...prevData,
          tblWarrantyReceiveTicketDetailCommands: updatedModels,
        };
      });
    },
    300
  );

  const callApiGetData = async () => {
    open();
    const callApi = await repositoryMdm.get<MessageResponse<any>>(
      `/api/v1/TblWarrantyReceiveTicket/update?id=${id}`
    );
    if (callApi?.success) {
      const dataApi = callApi?.data;
      if (dataApi !== null) {
        form.setValues(dataApi);
        form.setValues((prev) => ({
          ...prev,
          tblWarrantyReceiveTicketDetailCommands:
            dataApi.tblWarrantyReceiveTicketDetailModels,
        }));
        form.resetDirty(dataApi);
        setDataRequestFormSelected(dataApi);
        setPhoneNumber(dataApi?.custPhone);
        await Promise.all([
          getDataEmployeeForDep(),
          getDataDepartment(),
          getDataRole(),
        ]);
      }
      close();
    } else {
      onClose("");
    }
  };

  const handleEditReceiptForm = async (
    dataSubmit: TblDmWarrantyReceiveTicket
  ) => {
    open();
    const req = await repositoryMdm.post<
      MessageResponse<TblDmWarrantyReceiveTicket>
    >("/api/v1/TblWarrantyReceiveTicket/update", dataSubmit);
    if (req?.success) {
      onSuccess((prev: any) => !prev);
      onClose("");
      NotificationExtension.Success("Phân công thành công !");
    }
    close();
  };

  const fetchDataRequestForm = async () => {
    try {
      const response = await repositoryMdm.get<
        MessageResponse<TblWarrantyServiceRequestForm[]>
      >(
        `api/v1/TblWarrantyServiceRequestForm/get-list?isReceive=false&KeySearch=${phoneNumber}&Take=100`
      );
      if (response && response.success) {
        let result = response.data;
        setDataRequestForm(result);
      }
    } catch (error) {
      console.error(error);
      return;
    }
  };

  const getInventorySelect = async () => {
    if (dataRequestFormSelected?.branchId) {
      const res = await repositoryPos.get<MessageResponse<any>>(
        `/api/v1/TblDmInventory/get-select-by-branch?branchId=${dataRequestFormSelected?.branchId}`
      );

      if (res && res?.success) {
        const dataApi = res.data;
        setDataInventorySelect(
          dataApi
            ?.filter(
              (item: any) =>
                item.inventoryId != null &&
                item.inventoryName != null &&
                (item.inventoryName.toLowerCase().includes("lỗi") ||
                  item.inventoryName.toLowerCase().includes("bảo hành"))
            )
            ?.map((item: any) => ({
              value: item.inventoryId?.toString(),
              label: `${item.inventoryName} - ${item.inventoryCode}`,
            }))
        );
      }
    } else {
      setDataInventorySelect([]);
    }
  };

  const fetchDataEmployeeSelect = async () => {
    const getData = await getTblDmEmployeeSelect();
    setDataEmployeeSelect(
      getData
        ?.filter(
          (item: any) =>
            item.value != null && item.text != null && item.value !== "-1"
        )
        ?.map((item: any) => ({
          value: item.value,
          label: item.text,
        }))
    );
  };

  const getDataEmployeeForDep = async () => {
    const res = await repositoryPos.get<MessageResponse<any>>(
      `/api/v1/TblDmEmployee/get-list?Take=500`
    );

    if (res && res?.success) {
      setDataEmployeeSelect(
        res.data
          ?.filter(
            (item: any) =>
              item.id != null &&
              item.fullname != null &&
              item.roleName.toLowerCase().includes("bảo hành")
          )
          ?.map((item: any) => ({
            value: item.id?.toString(),
            label: item.fullname,
          }))
      );
    }
  };

  const getDataDepartment = async () => {
    const res = await repositoryPos.get<MessageResponse<any>>(
      "/api/v1/TblDepartment/get-all"
    );

    if (res && res.success) {
      const result = res.data;
      setDataDepartment(
        result
          ?.filter((item: any) => item.name != null)
          ?.map((item: any) => ({
            id: item.departmentId,
            departmentName: item.name,
          }))
      );
    }
  };

  const getDataRole = async () => {
    const res = await repositoryPos.get<MessageResponse<any>>(
      "/api/v1/TblRole/get-all"
    );

    if (res && res.success) {
      const result = res.data;
      setDataRole(
        result
          ?.filter((item: any) => item.name != null)
          ?.map((item: any) => ({
            id: item.id,
            roleName: item.name,
          }))
      );
    }
  };

  const getEmployeeDetail = async () => {
    if (dataRequestFormSelected?.empId) {
      const res = await repositoryPos.get<MessageResponse<any>>(
        `/api/v1/TblDmEmployee/detail?id=${dataRequestFormSelected?.empId}`
      );

      if (res && res.success) {
        const result = res.data;
        setDataEmployeeDetail(result);
      }
    } else {
      setDataEmployeeDetail(undefined);
    }
  };

  useEffect(() => {
    if (id) {
      callApiGetData();
    }
  }, [id]);

  useEffect(() => {
    if (phoneNumber && phoneNumber.length >= 8 && phoneNumber.length <= 10) {
      fetchDataRequestForm();
    } else {
      setDataRequestForm([]);
    }
  }, [phoneNumber]);

  useEffect(() => {
    if (
      dataRequestFormSelected?.empId !== null &&
      dataRequestFormSelected?.empId !== 0
    ) {
      getEmployeeDetail();
    } else {
      setDataEmployeeDetail(undefined);
    }
  }, [dataRequestFormSelected?.empId]);

  useEffect(() => {
    if (dataRequestFormSelected && dataRequestFormSelected.length > 0) {
      form.setValues((prev) => ({
        ...prev,
        requestId: dataRequestFormSelected?.id,
      }));
    }
  }, [dataRequestFormSelected]);

  useEffect(() => {
    getInventorySelect();
  }, [dataRequestFormSelected?.branchId]);

  return (
    <>
      <Box
        component="form"
        mx="auto"
        p={"0px 10px"}
        onSubmit={form.onSubmit((e: TblDmWarrantyReceiveTicket) => {
          handleEditReceiptForm(e);
        })}
        onMouseDown={() => {
          setIsEdit({
            uomId: false,
            acessory: false,
            testResolution: false,
            serviceType: false,
            scheduleTime: false,
            daysReturn: false,
            note: false,
          });
          setEditAndSave(false);
        }}
        style={{ position: "relative" }}
      >
        <LoadingOverlay
          visible={visible}
          zIndex={1000}
          overlayProps={{ radius: "sm", blur: 2 }}
        />
        <Flex justify={"space-between"} align={"center"}>
          <Badge
            variant="gradient"
            gradient={{ from: "blue", to: "cyan", deg: 90 }}
            w={225}
          >
            Lập phiếu nhận hàng lỗi
          </Badge>
          <Flex w={"100%"} justify={"flex-end"} gap={10}>
            <Button
              type="button"
              color="red"
              loading={visible}
              onClick={() => {
                onClose("");
              }}
              leftSection={!visible ? <IconArrowBack size={18} /> : undefined}
            >
              Thoát
            </Button>
            <Button
              type="submit"
              color={"teal"}
              loading={visible}
              leftSection={!visible ? <IconCheck size={18} /> : undefined}
            >
              Lưu
            </Button>
          </Flex>
        </Flex>
        <Grid>
          <Grid.Col span={{ base: 12, md: 6, lg: 4 }}>
            <Fieldset h={"100%"} legend="Thông tin khách hàng hẹn gặp">
              <Grid>
                <Grid.Col span={12}>
                  <Menu
                    trigger="hover"
                    openDelay={100}
                    closeDelay={400}
                    width={1000}
                  >
                    <Menu.Target>
                      <TextInput
                        label="Số điện thoại khách hàng hẹn gặp"
                        placeholder="Nhập SĐT khách hàng (8 đến 10 số)"
                        defaultValue={phoneNumber ?? ""}
                        onKeyDown={handleKeyDownPhone}
                        onChange={(e) => setPhoneNumber(e.currentTarget.value)}
                      />
                    </Menu.Target>
                    <Menu.Dropdown>
                      <ReceiveWarrantyList
                        phoneNumber={phoneNumber}
                        dataRequestForm={dataRequestForm}
                        setDataRequestFormSelected={setDataRequestFormSelected}
                      />
                    </Menu.Dropdown>
                  </Menu>
                </Grid.Col>
              </Grid>
              <Divider
                label="Nhập số điện thoại khách hàng hẹn gặp để tìm kiếm thông tin khách hàng và phiếu"
                labelPosition="center"
                mt={5}
              />
              <Grid>
                <Grid.Col span={{ base: 12, md: 12, lg: 6 }}>
                  <TextInput
                    label="Kênh mua hàng"
                    placeholder="Chọn kênh mua hàng"
                    value={
                      dataRequestFormSelected !== undefined
                        ? dataRequestFormSelected.channelName
                        : null
                    }
                    {...form.getInputProps("channel")}
                    variant="filled"
                    readOnly
                  />
                </Grid.Col>
                <Grid.Col span={{ base: 12, md: 12, lg: 6 }}>
                  <TextInput
                    label="Tên khách hàng"
                    placeholder="Tìm kiếm thông qua SĐT"
                    value={
                      dataRequestFormSelected !== undefined
                        ? dataRequestFormSelected.custMeeting
                        : null
                    }
                    readOnly
                    variant="filled"
                  />
                </Grid.Col>
              </Grid>
              <Grid>
                <Grid.Col span={12}>
                  <TextInput
                    label="Địa chỉ"
                    placeholder="Tìm kiếm thông qua SĐT"
                    value={
                      dataRequestFormSelected !== undefined
                        ? dataRequestFormSelected.meetingAddress
                        : null
                    }
                    readOnly
                    variant="filled"
                  />
                </Grid.Col>
              </Grid>
            </Fieldset>
          </Grid.Col>
          <Grid.Col span={{ base: 12, md: 6, lg: 8 }}>
            <Fieldset legend="Thông tin phiếu tiếp nhận hàng lỗi" h={"100%"}>
              <Grid>
                <Grid.Col span={{ base: 12, md: 6, lg: 3 }}>
                  <TextInput
                    label="Số phiếu tiếp nhận hàng lỗi"
                    value={form.getValues().errorNumber ?? ""}
                    variant="filled"
                    readOnly
                  />
                </Grid.Col>
                <Grid.Col span={{ base: 12, md: 6, lg: 3 }}>
                  <TextInput
                    label="Số phiếu tiếp nhận yêu cầu"
                    placeholder="Số yêu cầu"
                    value={
                      dataRequestFormSelected !== undefined
                        ? dataRequestFormSelected.requestNum
                        : null
                    }
                    variant="filled"
                    readOnly
                  />
                </Grid.Col>
                <Grid.Col span={{ base: 12, md: 6, lg: 3 }}>
                  <DateTimePicker
                    label="Ngày tạo phiếu"
                    valueFormat="DD-MM-YYYY hh:mm A"
                    value={new Date()}
                    variant="filled"
                    readOnly
                  />
                </Grid.Col>
                <Grid.Col span={{ base: 12, md: 6, lg: 3 }}>
                  <TextInput
                    label="Hình thức xử lý"
                    placeholder="Hình thức xử lý"
                    value={
                      dataRequestFormSelected !== undefined
                        ? dataRequestFormSelected.processingMethodName
                        : null
                    }
                    variant="filled"
                    readOnly
                  />
                </Grid.Col>
              </Grid>
              <Grid>
                <Grid.Col span={{ base: 12, md: 6, lg: 4 }}>
                  <TextInput
                    label="Nhân viên tiếp nhận"
                    placeholder="Nhập tên nhân viên tiếp nhận"
                    value={dataRequestFormSelected?.empnameincharge}
                    variant="filled"
                    readOnly
                  />
                </Grid.Col>
                <Grid.Col span={{ base: 12, md: 6, lg: 4 }}>
                  <TextInput
                    label="Chức danh"
                    placeholder="Chức danh của nhân viên tiếp nhận"
                    value={
                      dataEmployeeDetail !== undefined
                        ? getValueById(
                            dataEmployeeDetail?.roleId,
                            dataRole,
                            "roleName"
                          )
                        : ""
                    }
                    variant="filled"
                    readOnly
                  />
                </Grid.Col>
                <Grid.Col span={{ base: 12, md: 6, lg: 4 }}>
                  <TextInput
                    label="Bộ phận/Phòng bạn"
                    placeholder="Bộ phận/Phòng ban của nhân viên tiếp nhận"
                    value={
                      dataEmployeeDetail !== undefined
                        ? getValueById(
                            dataEmployeeDetail?.departmentId,
                            dataDepartment,
                            "departmentName"
                          )
                        : ""
                    }
                    variant="filled"
                    readOnly
                  />
                </Grid.Col>
              </Grid>
              <Grid>
                <Grid.Col span={{ base: 12, md: 6, lg: 4 }}>
                  <Select
                    label="Kho"
                    placeholder="Nhập mã hoặc tên kho"
                    data={dataInventorySelect}
                    value={
                      form.getValues().inventoryId
                        ? form.getValues().inventoryId?.toString()
                        : null
                    }
                    searchable
                    clearable
                    comboboxProps={{ width: 325 }}
                    nothingFoundMessage="Không tìm thấy dữ liệu !"
                    {...form.getInputProps("inventoryId")}
                    onChange={(e) =>
                      form.setValues((prev) => ({
                        ...prev,
                        inventoryId: e ? Number(e) : null,
                      }))
                    }
                    withAsterisk
                  />
                </Grid.Col>
                <Grid.Col span={{ base: 12, md: 6, lg: 4 }}>
                  <Select
                    label="Vị trí kho"
                    placeholder="Nhập vị trí kho"
                    nothingFoundMessage="Không tìm thấy dữ liệu !"
                    withAsterisk
                  />
                </Grid.Col>
                <Grid.Col span={{ base: 12, md: 6, lg: 4 }}>
                  <TextInput
                    label="Ghi chú"
                    placeholder="Nhập ghi chú"
                    {...form.getInputProps("note")}
                  />
                </Grid.Col>
              </Grid>
            </Fieldset>
          </Grid.Col>
          <Grid.Col span={12}>
            <Fieldset legend="Thông tin sản phẩm">
              <ProductWarrantyList
                dataRequestFormSelectedDetail={
                  dataRequestFormSelected?.tblWarrantyReceiveTicketDetailModels
                }
                warrantyReceiveTicketDetail={warrantyReceiveTicketDetail}
                editAndSave={editAndSave}
                setEditAndSave={setEditAndSave}
                isEdit={isEdit}
                setIsEdit={setIsEdit}
                handleChangeValue={handleChangeValue}
              />
            </Fieldset>
          </Grid.Col>
        </Grid>
      </Box>
    </>
  );
};

type EditReceiptFormProps = {
  id?: any;
  onClose?: any;
  onSuccess?: any;
};

export default EditReceiptForm;
