import {
  ActionIcon,
  Badge,
  Box,
  Button,
  Flex,
  Grid,
  GridCol,
  Group,
  Input,
  Menu,
  Select,
  SelectProps,
  Text,
  Tooltip,
  rem,
} from "@mantine/core";
import {
  IconCaretDown,
  IconCheck,
  IconFileExport,
  IconFileUpload,
  IconSearch,
  IconStatusChange,
  IconTimeDuration15,
} from "@tabler/icons-react";
import {
  MRT_ColumnDef,
  MRT_RowSelectionState,
  MRT_SortingState,
  MantineReactTable,
  useMantineReactTable,
} from "mantine-react-table";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import * as xlsx from "xlsx";

import { DatePickerInput } from "@mantine/dates";
import { IconFilePlus } from "@tabler/icons-react";
import { useNavigate } from "react-router";
import {
  _timerDelayClick,
  repositoryMdm,
} from "../../../_base/_const/_constVar";
import _breadcrumb from "../../../_base/component/_layout/_breadcrumb";
import { paginationBase } from "../../../_base/model/_base/BaseTable";
import { formatDateTransfer } from "../../../common/FormatDate/FormatDate";
import { _localization } from "../../../config/location";
import { TblMfgWip } from "../../../model/TblMfgWip";
import OrderPartDetail from "./OrderPartDetail/OrderPartDetail";
import ProductionManagement from "./ProductionManagement";
import { green } from "../../../const/variables";

function getColorStatus(text: any) {
  switch (text) {
    case "Hoàn thành":
      return "green";
    case "Thực hiện":
      return "blue";
    default:
      return "gray";
  }
}
const dataStatusOption = [
  { value: "Thực hiện", label: "Thực hiện" },
  { value: "Hoàn thành", label: "Hoàn thành" },
];
const optionColorsAccount: Record<"Đang thực hiện" | "Hoàn thành", string> = {
  "Đang thực hiện": "lime",
  "Hoàn thành": "green",
};

const renderSelectOptionStatus: SelectProps["renderOption"] = ({
  option,
  checked,
}) => (
  <Group flex="1" gap="xs">
    <Badge
      color={
        optionColorsAccount[option.label as "Đang thực hiện" | "Hoàn thành"]
      }
      radius="sm"
      variant="filled"
    >
      {option.label}
    </Badge>
    {checked && (
      <IconCheck size={"14px"} style={{ marginInlineStart: "auto" }} />
    )}
  </Group>
);
const MfgWip = () => {
  const navigate = useNavigate();
  // Reference data from another table

  //data and fetching state
  const headerRef = React.useRef<HTMLDivElement>(null);
  const [data, setData] = useState<TblMfgWip[]>([]);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);
  const [rowCount, setRowCount] = useState(0);
  const [height, setHeight] = useState(0);
  const [dataProductionOrderDetail, setDataProductionOrderDetail] = useState<
    TblMfgWip | undefined | []
  >();
  //table state
  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});

  const [selectIds, setSelectIds] = useState<string[]>([]);
  const [pagination, setPagination] = useState(paginationBase);
  const [sorting, setSorting] = useState<MRT_SortingState>([]);
  //count
  const [timeLeft, setTimeLeft] = useState(60);
  const [isCounting, setIsCounting] = useState(false);

  const [search, setSearch] = useState({
    keySearch: "",
    startTime: "",
    endTime: "",
    status: "",
  });

  const columns = React.useMemo<MRT_ColumnDef<TblMfgWip>[]>(
    () => [
      {
        accessorKey: "id",
        header: "Mã lệnh sản xuất",
        width: 20,
        Cell: ({ renderedCellValue }: any) => (
          // <Text fw={700} size="sm">
          //   {renderedCellValue}
          // </Text>
          <Badge
            radius="sm"
            variant="dot"
            size="lg"
            color={renderedCellValue === null ? "red" : green.base}
          >
            {renderedCellValue === null ? null : renderedCellValue}
          </Badge>
        ),
      },

      {
        accessorKey: "maCty",
        header: "Mã công ty",
        size: 30,
        Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
      },
      {
        accessorKey: "maCt",
        header: "Mã chứng từ",
        size: 30,
        Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
      },
      {
        accessorKey: "soCt",
        header: "Số Chứng Từ",
        size: 30,
        Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
      },
      {
        accessorKey: "ngayDksx",
        header: "Ngày Đăng Ký Sản Xuất",
        Cell: ({ renderedCellValue }) => (
          <>
            {renderedCellValue &&
              formatDateTransfer(renderedCellValue as string)}
          </>
        ),
        width: 100,
      },
      {
        accessorKey: "tenTrangThai",
        header: "Trạng Thái",
        size: 30,
        Cell: ({ renderedCellValue }) => (
          <>
            {" "}
            <Badge radius="sm" color={getColorStatus(renderedCellValue)}>
              {renderedCellValue}
            </Badge>
          </>
        ),
      },
      {
        accessorKey: "maKh",
        header: "Mã khách hàng",
        size: 30,
        Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
      },
      {
        accessorKey: "tenKh",
        header: "Tên Khách Hàng",
        width: 100,
        Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
      },
      {
        accessorKey: "nguoiGd",
        header: "Người Giao Dịch",
        size: 30,
        Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
      },
      {
        accessorKey: "maDm",
        header: "Mã Danh Mục",
        size: 30,
        Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
      },
      {
        accessorKey: "dienGiai",
        header: "Mô tả",
        size: 30,
        Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
      },

      {
        accessorKey: "tSoLuong",
        header: "Tổng Số Lượng",
        size: 30,
        Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
      },
      {
        accessorKey: "cdate",
        header: "Ngày Tạo lệnh",
        size: 30,
        Cell: ({ renderedCellValue }) => (
          <>
            {renderedCellValue &&
              formatDateTransfer(renderedCellValue as string)}
          </>
        ),
      },
      {
        accessorKey: "cuser",
        header: "Người tạo lệnh",
        size: 30,
        Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
      },
      {
        accessorKey: "ldate",
        header: "Ngày Cập Nhật lệnh",
        size: 30,
        Cell: ({ renderedCellValue }) => (
          <>
            {renderedCellValue &&
              formatDateTransfer(renderedCellValue as string)}
          </>
        ),
      },
      {
        accessorKey: "luser",
        header: "Người Cập Nhật",
        size: 30,
        Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
      },
      {
        accessorKey: "action",
        header: "Thao tác",
        size: 50,
        Cell: ({ renderedCellValue, row, table }) => (
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              gap: "10px",
            }}
          >
            <Tooltip label="Xuất linh kiện sản xuất">
              <ActionIcon
                variant="light"
                aria-label="Export"
                color="blue"
                onClick={() => {
                  if (row.original.tenTrangThai !== "Hoàn thành") {
                    editData(row.original.id);
                  } else {
                    console.log("lệnh đã hoàn thành");
                  }
                }}
                disabled={row.original.tenTrangThai === "Hoàn thành"}
              >
                <IconFilePlus size={20} stroke={1.5} />
              </ActionIcon>
            </Tooltip>
          </Box>
        ),
      },
    ],
    []
  );

  const startCountdown = () => {
    if (!isCounting) {
      setTimeLeft(_timerDelayClick);
      setIsCounting(true);
    }
  };

  const handleExport = () => {
    try {
      const worksheet = xlsx.utils.json_to_sheet(data);
      const workbook = xlsx.utils.book_new();
      xlsx.utils.book_append_sheet(workbook, worksheet, "Data");
      xlsx.writeFile(workbook, "danh-sach-lenh-san-xuat.xlsx");
      toast.success("Export excel thành công", { autoClose: 1500 });
    } catch (error) {
      toast.error("Export excel thất bại", { autoClose: 1500 });
    }
  };

  const handleChangeSearchValue = (value: string, key: string) => {
    setSearch((prevData) => ({ ...prevData, [key]: value }));
  };

  const editData = (id: string | null) => {
    // navigate("/warehouse-config/edit-request-transfer", { state: { id: id } });
    navigate("/warehouse-config/edit-wip", {
      state: { id: id },
    });
  };

  const fetchData = async () => {
    setIsLoading(true);
    setIsRefetching(true);
    setIsError(false);

    let url = `?Skip=${pagination?.pageIndex * pagination?.pageSize}&Take=${
      pagination.pageSize
    }`;

    if (search.keySearch) {
      url += `&KeySearch=${search.keySearch}`;
    }
    if (search.startTime) {
      url += `&startTime=${search.startTime}`;
    }

    if (search.endTime) {
      url += `&endTime=${search.endTime}`;
    }
    if (search.status) {
      url += `&Active=${search.status}`;
    }
    let _softing = "";
    let isOrder = false;
    if (sorting && sorting.length > 0) {
      let _q = sorting[0];
      if (_q) {
        _softing = _q.id;
        isOrder = _q.desc;
      }
      url = url + "&sort=" + _softing + "&isOrder=" + isOrder;
    }
    try {
      const response = await repositoryMdm.get(
        `/api/v1/TblProductionOrder/get-list${url}`
      );
      if (response && response.success) {
        let result = response.data;
        setIsLoading(false);
        setData(result || []);

        setRowCount(response.totalCount);
        setSelectIds([]);
        table.resetRowSelection();
      }
    } catch (error) {
      setData([]);
      setIsError(true);
      console.error(error);
      return;
    } finally {
      setIsRefetching(false);
    }
    startCountdown();
  };

  useEffect(() => {
    const headerHeight = headerRef.current?.offsetHeight || 0;
    const handleResize = () => {
      // 190 là chiều cao của phần phân trang
      // headerHeight là chiều cao của phần header
      setHeight(window.innerHeight - (200 + headerHeight));
    };

    handleResize(); // Set initial height
    window.addEventListener("resize", handleResize); // Update height on window resize

    return () => {
      window.removeEventListener("resize", handleResize); // Clean up event listener
    };
  }, []);

  useEffect(() => {
    const valuesList = Object.keys(rowSelection);
    setSelectIds(valuesList);
    if (valuesList.length < 1) setSelectIds([]);
    else {
      const valuesList = Object.keys(rowSelection);
      setSelectIds(valuesList);
    }
  }, [rowSelection]);

  useEffect(() => {
    fetchData();
  }, []);

  const table = useMantineReactTable({
    columns,
    data,
    renderDetailPanel: ({ row }) => (
      <Flex justify={"start"} w={"100%"}>
        <ProductionManagement idDetail={row.original.id} />
      </Flex>
    ),
    positionToolbarAlertBanner: "bottom",
    enableTopToolbar: false,
    mantineTopToolbarProps: {
      style: {
        borderBottom: "3px solid rgba(128, 128, 128, 0.5)",
        marginBottom: 5,
      },
    },
    localization: _localization,
    getRowId: (row) => row.id?.toString(),

    initialState: {
      showColumnFilters: false,
      columnPinning: {
        left: ["id"],
        right: ["action"],
      },
      columnVisibility: { id: true },
      density: "xs",
    },
    enableRowSelection: false,
    mantineTableContainerProps: {
      style: { maxHeight: height, minHeight: height },
    },
    enableStickyHeader: true,
    onRowSelectionChange: setRowSelection,
    manualFiltering: false,
    manualPagination: true,
    manualSorting: true,
    onSortingChange: setSorting,
    rowCount,
    onPaginationChange: setPagination,
    mantineTableBodyCellProps: ({ row }) => ({
      style: {
        fontSize: "11.5px",
        padding: "4px 12px",
      },
    }),
    state: {
      isLoading,
      pagination,
      showAlertBanner: isError,
      showProgressBars: isRefetching,
      showSkeletons: isLoading,
      rowSelection,
      sorting,
    },
    mantineToolbarAlertBannerProps: false
      ? { color: "red", children: "Lỗi tải dữ liệu !" }
      : undefined,
    mantinePaginationProps: {
      showRowsPerPage: true,
      withEdges: true,
      rowsPerPageOptions: ["20", "50", "100"],
    },
    paginationDisplayMode: "pages",
    enableColumnPinning: true,
    mantineTableProps: {
      striped: false,
    },
  });

  return (
    <>
      <div
        style={{ border: "1px solid #dee2e6", padding: "5px" }}
        ref={headerRef}
      >
        <Flex
          gap="md"
          justify={"space-between"}
          align="center"
          direction="row"
          wrap="wrap"
          w={"100%"}
        >
          <_breadcrumb></_breadcrumb>
          <Flex gap="md" align="center" direction="row" wrap="wrap">
            <Menu shadow="md" width={200}>
              <Menu.Target>
                <Button
                  // size="xs"
                  rightSection={
                    <IconCaretDown
                      style={{ width: rem(14), height: rem(14) }}
                    />
                  }
                >
                  Chức năng
                </Button>
              </Menu.Target>

              <Menu.Dropdown>
                <Menu.Label>Application</Menu.Label>
                <Menu.Item
                  leftSection={
                    <IconFileExport
                      style={{ width: rem(14), height: rem(14) }}
                    />
                  }
                  onClick={handleExport}
                >
                  Export Excel
                </Menu.Item>
                <Tooltip label={"Chức năng đang update"}>
                  <Menu.Item
                    leftSection={
                      <IconFileUpload
                        style={{ width: rem(14), height: rem(14) }}
                      />
                    }
                  >
                    Import Excel
                  </Menu.Item>
                </Tooltip>
              </Menu.Dropdown>
            </Menu>
          </Flex>
        </Flex>

        <Grid mt={10}>
          <GridCol span={{ base: 6, sm: 4, md: 1.5 }}>
            <Input
              w={"100%"}
              placeholder="Từ khoá"
              type="text"
              leftSection={<IconSearch color="#15aabf" />}
              onChange={(e) => {
                handleChangeSearchValue(e.target.value ?? "", "keySearch");
              }}
            />
          </GridCol>

          <GridCol span={{ base: 6, sm: 4, md: 2, lg: 1.25 }}>
            <Select
              placeholder={"Trạng thái"}
              searchable
              clearable
              data={dataStatusOption}
              leftSection={<IconStatusChange color="#15aabf" />}
              comboboxProps={{
                transitionProps: { transition: "pop", duration: 200 },
              }}
              renderOption={renderSelectOptionStatus}
              nothingFoundMessage={"Không có dữ liệu"}
              onChange={(value) =>
                handleChangeSearchValue(value || "", "status")
              }
            />
          </GridCol>
          <GridCol span={{ base: 8, sm: 6, md: 3.35, lg: 2.5 }}>
            <DatePickerInput
              // type="multiple"
              type="range"
              size="sm"
              allowSingleDateInRange
              placeholder="Chọn khoảng ngày"
              leftSection={<IconTimeDuration15 color="#15aabf" />}
              locale="vi"
              // value={value}
              valueFormat="DD/MM/YYYY"
              onChange={(e) => {
                handleChangeSearchValue(
                  e[0] ? e[0].toISOString() : "",
                  "startTime"
                );
                handleChangeSearchValue(
                  e[1] ? e[1].toISOString() : "",
                  "endTime"
                );
              }}
              clearable
            />
          </GridCol>
          <GridCol span={{ base: 4, sm: 2, md: 1 }}>
            <Button
              leftSection={<IconSearch size={14} />}
              color="blue"
              variant="outline"
              onClick={async () => {
                await fetchData();
              }}
            >
              Tìm kiếm
            </Button>
          </GridCol>
        </Grid>
      </div>

      <div className="mt-5" style={{ marginTop: "10px" }}>
        <MantineReactTable table={table} />
      </div>
    </>
  );
};

export default MfgWip;
