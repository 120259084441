import { Badge, Box, Button, Input, Select } from "@mantine/core";
import { DatePickerInput } from "@mantine/dates";
import {
  IconCalculator,
  IconCalendar,
  IconSearch,
  IconSwitch,
  IconTruckDelivery,
} from "@tabler/icons-react";
import {
  MRT_ColumnDef,
  MRT_RowSelectionState,
  MantineReactTable,
  useMantineReactTable,
} from "mantine-react-table";
import React, { useEffect, useState } from "react";
import { repositoryMdm } from "../../../_base/_const/_constVar";
import _breadcrumb from "../../../_base/component/_layout/_breadcrumb";
import { paginationBase } from "../../../_base/model/_base/BaseTable";
import { formatDateNotTimeZone } from "../../../common/FormatDate/FormatDate";
import { _localization } from "../../../config/location";
import { AvatarUtils } from "../../../common/ColorByName/AvatarUtils";
const DeliveryPay = () => {
  const headerRef = React.useRef<HTMLDivElement>(null);
  const [isRefetching, setIsRefetching] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [pagination, setPagination] = useState(paginationBase);
  const [height, setHeight] = useState(0);
  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});
  const [data, setData] = useState<Department[]>([]);
  const [rowCount, setRowCount] = useState(0);
  const [selectIds, setSelectIds] = useState<string[]>([]);
  const [search, setSearch] = useState({
    keySearch: "",
    startDate: "",
    endDate: "",
    deliveryParoll: "",
    supportParoll: "",
    statusName: "",
  });
  const handleChangeSearchValue = (value: string | null, key: string) => {
    setSearch((prevData) => ({
      ...prevData,
      [key]: value ? value : 0,
    }));
  };
  function getColorStatus(text: any) {
    switch (text) {
      case "Đã phân công":
        return "teal";
      case "Đơn hàng giao chưa thành công do Khách hàng":
        return "red";
      default:
        return "yellow";
    }
  }
  const columns = React.useMemo<MRT_ColumnDef<any>[]>(
    () => [
      {
        accessorKey: "transactionNumber",
        header: "Mã giao dịch",
        Cell: ({ renderedCellValue, cell }) => (
          <Badge
            radius="sm"
            variant="dot"
            size="lg"
            color={renderedCellValue === null ? "red" : "#21d01b"}
          >
            {renderedCellValue === null ? null : renderedCellValue}
          </Badge>
        ),
      },
      {
        accessorKey: "assignmentCode",
        header: "Mã phân công",
        Cell: ({ renderedCellValue, cell }) => (
          <Badge
            radius="sm"
            variant="dot"
            size="lg"
            color={renderedCellValue === null ? "red" : "#21d01b"}
          >
            {renderedCellValue === null ? null : renderedCellValue}
          </Badge>
        ),
      },
      {
        accessorKey: "sourceCode",
        header: "Chứng từ gốc",
        Cell: ({ renderedCellValue, cell }) => (
          <Badge
            radius="sm"
            variant="dot"
            size="lg"
            color={renderedCellValue === null ? "red" : "#21d01b"}
          >
            {renderedCellValue === null ? null : renderedCellValue}
          </Badge>
        ),
      },

      {
        accessorKey: "employeeDeliveryCode",
        header: "Mã nhân viên giao vận",
        size: 200,
      },
      {
        accessorKey: "employeeDeliveryName",
        header: "Tên nhân viên giao vận",
        Cell: ({ renderedCellValue, row }) => (
          <>
            <AvatarUtils
              value={
                row.original.employeeDeliveryName?.toString().split("-")[0]
              }
            />
          </>
        ),
        size: 200,
      },
      {
        accessorKey: "employeeSupportCode",
        header: "Mã nhân viên hỗ trợ",
        size: 10,
      },
      {
        accessorKey: "employeeSupportName",
        header: "Tên nhân viên hỗ trợ",
        Cell: ({ renderedCellValue, row }) => (
          <>
            <AvatarUtils
              value={row.original.employeeSupportName?.toString().split("-")[0]}
            />
          </>
        ),
        size: 200,
      },
      {
        accessorKey: "customerCode",
        header: "Mã khách hàng",
        size: 30,
      },
      {
        accessorKey: "customerName",
        header: "Tên khách hàng",
        Cell: ({ renderedCellValue, row }) => (
          <>
            <AvatarUtils
              value={row.original.employeeSupportName?.toString().split("-")[0]}
            />
          </>
        ),
        size: 200,
      },
      {
        accessorKey: "streetName",
        header: "Tên dường",
        size: 30,
      },
      {
        accessorKey: "communes",
        header: "Tên xã",
        size: 30,
      },
      {
        accessorKey: "districts",
        header: "Tên quận",
        size: 30,
      },
      {
        accessorKey: "deliveryWork",
        header: "Công việc giao hàng",
        size: 30,
      },
      {
        accessorKey: "deliveryDepartmentWorking",
        header: "Bộ phận giao hàng",
        size: 30,
      },
      {
        accessorKey: "fromAddress",
        header: "Địa điểm nhận đơn",
        size: 30,
      },
      {
        accessorKey: "toAddress",
        header: "Địa điểm giao đến",
        size: 30,
      },
      {
        accessorKey: "payrollForDelivery",
        header: "Bảng lương giao vận",
        size: 30,

        Cell: ({ cell }) => (
          <div
            style={{
              backgroundColor: cell.getValue() ? "#12B886" : "#FA5252",
              color: "white",
              borderRadius: "50px",
              padding: "5px",
              textAlign: "center",
              width: "100px",
            }}
          >
            {cell.getValue() ? "Có tính lương" : "Không tính lương"}
          </div>
        ),
      },
      {
        accessorKey: "payrollForSupport",
        header: "Bảng lương hỗ trợ",
        size: 30,
        Cell: ({ cell }) => (
          <div
            style={{
              backgroundColor: cell.getValue() ? "#12B886" : "#FA5252",
              color: "white",
              borderRadius: "50px",
              padding: "5px",
              textAlign: "center",
              width: "100px",
            }}
          >
            {cell.getValue() ? "Có tính lương" : "Không tính lương"}
          </div>
        ),
      },
      {
        accessorKey: "assignmentDate",
        header: "Ngày phân công",
      },
      {
        accessorKey: "statusName",
        header: "Trạng thái",
        size: 30,
        Cell: ({ renderedCellValue, row }) => (
          <>
            <Badge color={getColorStatus(renderedCellValue || 0)}>
              {renderedCellValue === "Đã phân công"
                ? "Đã phân công"
                : renderedCellValue ===
                  "Đơn hàng giao chưa thành công do Khách hàng"
                ? "Đơn hàng giao chưa thành công do Khách hàng"
                : "Hàng đã giao"}
            </Badge>
          </>
        ),
      },
      {
        accessorKey: "description",
        header: "Miêu tả",
        size: 30,
      },
    ],
    []
  );

  const fetchData = async () => {
    setIsLoading(true);
    setIsRefetching(true);
    setIsError(false);

    let url = `?Skip=${pagination?.pageIndex * pagination?.pageSize}&Take=${
      pagination.pageSize
    }`;

    if (search.keySearch) {
      url += `&KeySearch=${search.keySearch.trim().toLowerCase()}`;
    }
    if (search.startDate) {
      url += `&StartTime=${search.startDate}`;
    }
    if (search.deliveryParoll) {
      url += `&DeliveryParoll=${search.deliveryParoll}`;
    }
    if (search.supportParoll) {
      url += `&SupportParoll=${search.supportParoll}`;
    }
    if (search.statusName) {
      url += `&StatusName=${search.statusName}`;
    }
    if (search.endDate) {
      url += `&EndTime=${search.endDate}`;
    }
    try {
      const response = await repositoryMdm.get(
        `/api/v1/View/employee-delivery-payroll${url}`
      );
      if (response && response.success) {
        let result = response.data;
        setData(result);
        setRowCount(response.totalCount);
        setSelectIds([]);
        table.resetRowSelection();
      }
    } catch (error) {
      setData([]);
      setIsError(true);
      console.error(error);
      return;
    } finally {
      setIsLoading(false);
      setIsRefetching(false);
    }
  };

  const table = useMantineReactTable({
    columns,
    data,
    enableColumnFilters: true,
    enableSorting: true,
    enableColumnActions: true,
    // manualPagination: true,
    rowCount,
    state: {
      // columnFilters,
      // globalFilter,
      isLoading,
      pagination,
      showAlertBanner: isError,
      showProgressBars: isRefetching,
      showSkeletons: isLoading,
      rowSelection,
    },
    manualFiltering: false,
    manualPagination: true,
    manualSorting: false,
    onPaginationChange: setPagination,
    positionToolbarAlertBanner: "bottom",
    initialState: {
      showColumnFilters: false,
      columnPinning: {
        left: ["transactionNumber", "sourceCode"],
        right: ["statusName"],
      },
      columnVisibility: { id: false },
      density: "xs",
    },
    renderTopToolbarCustomActions: ({ table }) => (
      <div ref={headerRef}>
        <_breadcrumb></_breadcrumb>
        <Box style={{ display: "flex", alignItems: "center", gap: "10px" }}>
          <Input
            style={{ flex: 1, maxWidth: "180px" }}
            placeholder="Nhập tìm kiếm"
            type="text"
            leftSection={<IconSearch size={"20"} color="#15aabf" />}
            onChange={(e) => {
              handleChangeSearchValue(e.target.value ?? "", "keySearch");
            }}
          />
          <DatePickerInput
            type="range"
            size="sm"
            placeholder="Từ ngày - Đến ngày"
            locale="vi"
            valueFormat="DD/MM/YYYY"
            onChange={(e) => {
              handleChangeSearchValue(
                formatDateNotTimeZone(e[0]) ?? "",
                "startDate"
              );
              handleChangeSearchValue(
                formatDateNotTimeZone(e[1]) ?? "",
                "endDate"
              );
            }}
            leftSection={<IconCalendar color="#15aabf" />}
            w={250}
            clearable
          />
          <Select
            size="sm"
            placeholder="Bảng lương giao hàng"
            searchable
            clearable
            data={[
              { value: "true", label: "Có tính lương" },
              { value: "false", label: "Không tính lương" },
            ]}
            style={{ flex: 1, maxWidth: "180px" }}
            leftSection={<IconTruckDelivery size={20} color="#15aabf" />}
            onChange={(value) =>
              handleChangeSearchValue(value, "deliveryParoll")
            }
          />
          <Select
            size="sm"
            placeholder="Hỗ trợ tính lương"
            searchable
            clearable
            data={[
              { value: "true", label: "Có tính lương" },
              { value: "false", label: "Không tính lương" },
            ]}
            style={{ flex: 1, maxWidth: "180px" }}
            leftSection={<IconCalculator size={20} color="#15aabf" />}
            onChange={(value) =>
              handleChangeSearchValue(value, "supportParoll")
            }
          />
          <Select
            size="sm"
            placeholder="Tên trạng thái"
            searchable
            clearable
            data={[
              { value: "Đã phân công", label: "Đã phân công" },
              { value: "Hàng đã giao", label: "Hàng đã giao" },
              {
                value: "Đơn hàng giao chưa thành công do Khách hàng",
                label: "Đơn hàng giao chưa thành công do Khách hàng",
              },
            ]}
            style={{ flex: 1, maxWidth: "180px" }}
            leftSection={<IconSwitch size={20} color="#15aabf" />}
            onChange={(value) => handleChangeSearchValue(value, "statusName")}
          />
          <Button
            color="blue"
            variant="outline"
            onClick={async () => {
              await fetchData();
            }}
          >
            Tìm kiếm
          </Button>
        </Box>
      </div>
    ),
    renderToolbarInternalActions: ({ table }) => <></>,

    mantineTopToolbarProps: {
      style: {
        borderBottom: "3px solid rgba(128, 128, 128, 0.5)",
        marginBottom: 5,
      },
    },
    localization: _localization,
    getRowId: (row) => row.departmentId,
    mantineTableContainerProps: {
      style: { maxHeight: height, minHeight: height },
    },
    enableStickyHeader: true,
    onRowSelectionChange: setRowSelection,
    mantineTableBodyCellProps: ({ row }) => ({
      style: {
        fontSize: "11.5px",
        padding: "4px 12px",
      },
    }),
    mantineToolbarAlertBannerProps: false
      ? { color: "red", children: "Lỗi tải dữ liệu !" }
      : undefined,
    mantinePaginationProps: {
      showRowsPerPage: true,
      withEdges: true,
      rowsPerPageOptions: ["10", "50", "100"],
    },
    paginationDisplayMode: "pages",
    enableColumnPinning: true,
    mantineTableProps: {
      striped: false,
    },
    mantineTableBodyRowProps: ({ row }) => ({
      onClick: row.getToggleSelectedHandler(),
      sx: { cursor: "pointer" },
    }),
  });
  useEffect(() => {
    const valuesList = Object.keys(rowSelection);
    setSelectIds(valuesList);
    if (valuesList.length < 1) setSelectIds([]);
    else {
      const valuesList = Object.keys(rowSelection);
      setSelectIds(valuesList);
    }
  }, [rowSelection]);
  useEffect(() => {
    const headerHeight = headerRef.current?.offsetHeight || 0;
    const handleResize = () => {
      setHeight(window.innerHeight - (200 + headerHeight));
    };

    handleResize(); // Set initial height
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  useEffect(() => {
    fetchData();
  }, [pagination]);

  return (
    <>
      <div className="mt-5">
        <MantineReactTable table={table} />
      </div>
    </>
  );
};

export default DeliveryPay;
type Department = {
  departmentId: number;
  name: string;
  description: string | null;
  active: boolean;
  deparmentCode: string;
  creationDate: string | null;
  createdBy: string | null;
  lastUpdateDate: string | null;
  lastUpdatedBy: string | null;
  address: string;
  branchId: number;
};
