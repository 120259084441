import {
  ActionIcon,
  Box,
  Button,
  Checkbox,
  Fieldset,
  Flex,
  Grid,
  Input,
  LoadingOverlay,
  Overlay,
  Pagination,
  ScrollArea,
  Select,
  Table,
  TextInput,
} from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { modals } from "@mantine/modals";
import {
  IconCheck,
  IconMenu2,
  IconSearch,
  IconWindow,
} from "@tabler/icons-react";
import cx from "clsx";
import React, { useEffect, useState } from "react";
import { repositoryPos } from "../../../../../_base/_const/_constVar";
import { NotificationExtension } from "../../../../../_base/extension/NotificationExtension";
import { SelectListItemBase } from "../../../../../_base/model/_base/SelectListItemBase";
import { sky_blue } from "../../../../../const/variables";
import { MessageResponse } from "../../../../../model/MessageResponse";
import { tblItemByCat } from "../../../../../model/TblItem";
import classes from "../Styles/ScrollTable.module.css";
import {
  MantineReactTable,
  MRT_ColumnDef,
  MRT_RowSelectionState,
  useMantineReactTable,
} from "mantine-react-table";
import { _localization } from "../../../../../config/location";
import { tblPromotionItemGiftCommand } from "../../../../../model/TblPromotion";

const ModalSearchItems = ({
  handleAddItem,
  handleAddSearchItem,
  dataDelete,
}: {
  handleAddItem: (
    selectedCatSegment: { code: string; type: number }[],
    selectedManuFacTurer: string[],
    selectCat: string | null
  ) => void;
  handleAddSearchItem: (selectItem: tblItemByCat[]) => void;
  dataDelete?: tblPromotionItemGiftCommand[];
}) => {
  const [visibleCat, { close: closeCat, open: openCat }] = useDisclosure(false);
  const [visibleItem, { close: closeItem, open: openItem }] =
    useDisclosure(false);
  const [selectCat, setSelectedCat] = useState<string | null>("1a");
  const [scrolledv1, setScrolledv1] = useState(false);
  const [selectedManuFacTurer, setSelectedManuFacTurer] = useState<string[]>(
    []
  );
  const [dataManuFacTurer, setDataManuFacTurer] = useState<any[]>([]);
  const [dataManuFacTurerTemp, setDataManuFacTurerTemp] = useState<any[]>([]);
  const [keySearch, setKeySearch] = useState<string>("");
  const [keySearchItem, setKeySearchItem] = useState<string>("");
  const [selectedCatSegment, setSelectedCatSegment] = useState<
    { code: string; type: number; checked: boolean }[]
  >([]);
  const [dataItem, setDataItem] = useState<tblItemByCat[]>([]);
  const [dataItemTemp, setDataItemTemp] = useState<tblItemByCat[]>([]);
  const [selectItem, setSelectItem] = useState<tblItemByCat[]>([]);
  const [activePage, setPage] = useState(1);
  const [activePageGroup, setPageGroup] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [totalCountGroup, setTotalCountGroup] = useState(0);
  const [search, setSearch] = useState({
    type: "",
    keySearch: "",
  });

  // Table delete
  const [data, setData] = useState<any[]>(dataDelete ?? []);
  const [dataTemp, setDataTemp] = useState<any[]>(dataDelete ?? []);
  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});
  const [rowCount, setRowCount] = useState(0);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);
  const [selectIds, setSelectIds] = useState<string[]>([]);

  const columns = React.useMemo<MRT_ColumnDef<any>[]>(
    () => [
      {
        accessorKey: "itemPrimaryCode",
        header: "Mã",
      },

      {
        accessorKey: "itemPrimaryName",
        header: "Tên",
      },
    ],
    [data]
  );

  const handleChangeSearch = (value: string, key: string) => {
    setSearch((prev) => ({ ...prev, [key]: value }));
  };

  const renderTabelPanel = (dataPanel: any[], index: number) => {
    return (
      <Box mt={10} w={"95%"}>
        <Grid align="center">
          <Grid.Col span={{ base: 12, md: 4, lg: 4 }}>
            <Select
              leftSection={<IconMenu2 size={14} />}
              w={"100%"}
              placeholder="Lọc theo"
              data={[
                { label: "Lĩnh vực", value: "1" },
                {
                  label: "Ngành",
                  value: "2",
                },
                {
                  label: "Loại",
                  value: "3",
                },
                {
                  label: "Chủng",
                  value: "4",
                },
                {
                  label: "Nhóm",
                  value: "5",
                },
                {
                  label: "Model",
                  value: "6",
                },
              ]}
              onChange={(e) => handleChangeSearch(e ?? "", "type")}
            ></Select>
          </Grid.Col>
          <Grid.Col span={{ base: 12, md: 8, lg: 8 }}>
            <TextInput
              value={search.keySearch}
              placeholder="Nhập tên"
              onChange={(e) =>
                handleChangeSearch(e.currentTarget.value, "keySearch")
              }
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  fetchDataManuFacTurer();
                }
              }}
              rightSection={
                <ActionIcon variant="light" onClick={fetchDataManuFacTurer}>
                  <IconSearch size={14}></IconSearch>
                </ActionIcon>
              }
            />
          </Grid.Col>
        </Grid>
        <Box p={"10px 0px"}>
          <ScrollArea
            h={350}
            m={"10px 0px"}
            onScrollPositionChange={({ y }) => setScrolledv1(y !== 0)}
          >
            <Table
              highlightOnHover
              withColumnBorders
              withTableBorder
              striped
              style={{ overflowY: "auto", height: "30px" }}
            >
              {selectCat !== "branch" && (
                <LoadingOverlay
                  visible={visibleCat}
                  zIndex={1000}
                  overlayProps={{ radius: "sm", blur: 2 }}
                />
              )}

              <Table.Thead
                className={cx(classes.header, {
                  [classes.scrolled]: scrolledv1,
                })}
              >
                <Table.Tr>
                  <Table.Th w={40}></Table.Th>
                  <Table.Th>Mã</Table.Th>
                  <Table.Th>Tên</Table.Th>
                </Table.Tr>
              </Table.Thead>
              <Table.Tbody mih={200}>
                {dataPanel?.map((item: any) => (
                  <Table.Tr
                    key={item.code}
                    style={{
                      backgroundColor: selectedCatSegment[
                        index
                      ]?.code?.includes(item.code)
                        ? "var(--mantine-color-blue-light)"
                        : "",
                    }}
                  >
                    <Table.Td>
                      <Checkbox
                        checked={item?.checked}
                        onChange={(e) => {
                          handleCheckboxChange(item?.code, item?.type, e);
                        }}
                      />
                    </Table.Td>
                    <Table.Td>{item.code}</Table.Td>
                    <Table.Td>{item.name}</Table.Td>
                  </Table.Tr>
                ))}
              </Table.Tbody>
              {dataPanel.length < 1 && (
                <Table.Caption>Không có dữ liệu</Table.Caption>
              )}
            </Table>
          </ScrollArea>
          <Pagination
            mt={"30px"}
            value={activePageGroup}
            onChange={setPageGroup}
            total={totalCountGroup}
          />
        </Box>
      </Box>
    );
  };

  const renderTableItems = () => (
    <>
      <Flex gap={20} mt={10}>
        <TextInput
          w={"50%"}
          placeholder="Tìm kiếm mã, tên sản phẩm"
          value={keySearchItem}
          onChange={(e) => setKeySearchItem(e.currentTarget.value)}
          onKeyDown={handleKeyDown}
        />
        <Button
          leftSection={<IconSearch size={14} />}
          onClick={() =>
            activePage === 1 ? fetchDataSearchItem() : setPage(1)
          }
        >
          Tìm kiếm
        </Button>
      </Flex>
      <ScrollArea
        h={400}
        m={"10px 0px"}
        onScrollPositionChange={({ y }) => setScrolledv1(y !== 0)}
      >
        <Table
          highlightOnHover
          withColumnBorders
          withTableBorder
          striped
          style={{ overflowY: "auto", height: "30px", widows: "100%" }}
        >
          <LoadingOverlay
            visible={visibleItem}
            zIndex={1000}
            overlayProps={{ radius: "sm", blur: 2 }}
          />

          <Table.Thead
            className={cx(classes.header, {
              [classes.scrolled]: scrolledv1,
            })}
          >
            <Table.Tr>
              <Table.Th w={40}>
                <Checkbox
                  checked={
                    dataItem.length > 0 && selectItem.length === dataItem.length
                  }
                  onChange={handleSelectAll}
                />
              </Table.Th>
              <Table.Th>Mã</Table.Th>
              <Table.Th>Tên</Table.Th>
              {/* <Table.Th>Phân khúc</Table.Th> */}
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody mih={200}>
            {dataItem?.map((item) => (
              <Table.Tr
                key={item.itemId}
                style={{
                  backgroundColor: selectItem?.includes(item)
                    ? "var(--mantine-color-blue-light)"
                    : "",
                }}
              >
                <Table.Td>
                  <Checkbox
                    checked={selectItem?.includes(item)}
                    onChange={(e) => handleItemCheckboxChange(item)}
                  />
                </Table.Td>
                <Table.Td>{item.itemCode}</Table.Td>
                <Table.Td>{item.itemName}</Table.Td>
                {/* <Table.Td>{item.segmentName}</Table.Td> */}
              </Table.Tr>
            ))}
          </Table.Tbody>
          {dataItem.length < 1 && (
            <Table.Caption>Không có dữ liệu</Table.Caption>
          )}
        </Table>
      </ScrollArea>
      <Pagination value={activePage} onChange={setPage} total={totalCount} />
    </>
  );

  const handleSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    const checked = event.target.checked;
    setSelectItem(checked ? dataItem.map((item) => item) : []);
  };

  const handleCheckboxChange = (
    code: string | null,
    type: number,
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const checked = event.target.checked;

    setSelectedCatSegment((prevSelectedCatSegment) => {
      let updatedSelectedRows;

      if (checked && code) {
        updatedSelectedRows = [
          ...prevSelectedCatSegment.filter((row) => row.code !== code),
          { code, type, checked },
        ];
      } else {
        updatedSelectedRows = prevSelectedCatSegment.filter(
          (row) => row.code !== code
        );
      }

      return updatedSelectedRows;
    });
  };

  const handleItemCheckboxChange = (item: tblItemByCat) => {
    const newItemIds = selectItem.includes(item)
      ? selectItem.filter((selectItem) => selectItem !== item)
      : [...selectItem, item];

    setSelectItem(newItemIds);
  };

  const handleConfirmAddItem = () => {
    if (dataDelete && dataDelete.length > 0) {
      if (table.getSelectedRowModel().rows.length > 0) {
        const rowSelected = table.getSelectedRowModel().rows?.map((row) => ({
          itemId: Number(row.original?.idItemPrimary),
          itemCode: row.original?.itemPrimaryCode?.toString() ?? "",
          itemName: row.original?.itemPrimaryName?.toString() ?? "",
          brandId: row.original?.brandId ?? 0,
          isOnhand: row.original?.isOnhand ?? false,
          itemShortName: row.original?.itemShortName?.toString() ?? "",
          segmentId: row.original?.segmentId ?? 0,
          segmentName: row.original?.segmentName?.toString() ?? "",
        }));
        handleAddSearchItem(rowSelected);
      }
    }
    if (selectedCatSegment?.length > 0) {
      handleAddItem(selectedCatSegment, selectedManuFacTurer, selectCat);
    } else {
      handleAddSearchItem(selectItem);
    }
  };

  const fetchDataManuFacTurer = async () => {
    let url = `/api/v1/Category/get-list?Skip=${
      50 * (activePageGroup - 1)
    }&Take=50`;

    if (search.keySearch) {
      url += `&KeySearch=${search.keySearch}`;
    }

    if (search.type) {
      url += `&Type=${search.type}`;
    }

    const dataApi = await repositoryPos.get<
      MessageResponse<SelectListItemBase[]>
    >(url);

    if (dataApi && dataApi.success) {
      setDataManuFacTurerTemp(dataApi.data);
      setDataManuFacTurer(dataApi.data);
      setTotalCountGroup(Math.ceil(dataApi.totalCount / 50));
    } else {
      setDataManuFacTurer([]);
      setDataManuFacTurerTemp([]);
      setTotalCountGroup(0);
    }
  };

  const handleKeyDown = (e: any) => {
    if (e.key === "Enter") {
      activePage === 1 ? fetchDataSearchItem() : setPage(1);
    }
  };

  const handleOnChange = (e: any) => {
    const value = e.target.value.toLowerCase() ?? "";
    setKeySearch(value);
    if (value) {
      const searchResult = dataTemp.filter(
        (product) =>
          product.itemPrimaryCode?.toLowerCase().includes(value) ||
          product.itemPrimaryName?.toLowerCase().includes(value)
      );
      setRowCount(searchResult.length);
      setSelectIds([]);
      table.resetRowSelection();
      setData(searchResult);
    } else {
      setRowCount(dataTemp.length);
      setSelectIds([]);
      table.resetRowSelection();
      setData(dataTemp);
    }
  };

  const fetchDataSearchItem = async () => {
    openItem();
    let url = `/api/v1/TblItem/get-list-by-cat?Skip=${
      50 * (activePage - 1)
    }&Take=50`;

    if (keySearchItem) {
      url += `&KeySearch=${keySearchItem}`;
    }
    const dataApi = await repositoryPos.get<MessageResponse<tblItemByCat[]>>(
      url
    );

    if (dataApi?.success && dataApi.data) {
      setDataItem(dataApi.data);
      setDataItemTemp(dataApi.data);
      setTotalCount(Math.ceil(dataApi.totalCount / 50));
    } else {
      setDataItem([]);
      setDataItemTemp([]);
      setSelectItem([]);
      setTotalCount(0);
    }
    closeItem();
  };

  const table = useMantineReactTable({
    columns,
    data,
    positionToolbarAlertBanner: "bottom",
    //add custom action buttons to top-left of top toolbar
    renderTopToolbarCustomActions: ({ table }) => (
      <div>
        <Flex
          gap="md"
          justify="flex-start"
          align="center"
          direction="row"
          wrap="wrap"
        >
          <Input placeholder="Nhập từ khóa ..." onChange={handleOnChange} />
        </Flex>
      </div>
    ),

    mantineTopToolbarProps: {
      style: {
        borderBottom: "3px solid rgba(128, 128, 128, 0.5)",
        marginBottom: 5,
      },
    },
    localization: _localization,
    enableRowSelection: true,
    // getRowId: (row: any) => row?.original?.idItemPrimary?.toString(),
    initialState: {
      showColumnFilters: false,
      columnPinning: {
        left: ["mrt-row-select", "code"],
        right: ["action"],
      },
      columnVisibility: { id: true, phone: false, fax: false },
      density: "xs",
    },
    mantineTableContainerProps: {
      style: { maxHeight: 400, minHeight: 400 },
    },
    enableStickyHeader: true,
    onRowSelectionChange: setRowSelection,
    // manualFiltering: true,
    // manualPagination: true,
    // manualSorting: true,
    rowCount,
    // onColumnFiltersChange: setColumnFilters,
    // onGlobalFilterChange: setGlobalFilter,
    // onPaginationChange: setPagination,
    // onSortingChange: setSorting,
    mantineTableBodyCellProps: ({ row }) => ({
      style: {
        //  fontWeight: row.getIsSelected() ? "bold" : "normal", //conditional styling
        fontWeight: "normal", //conditional styling
        fontSize: "12.5px",
        padding: "5px 15px",
      },
    }),
    state: {
      // columnFilters,
      // globalFilter,
      isLoading,
      //  pagination,
      showAlertBanner: isError,
      showProgressBars: isRefetching,
      showSkeletons: isLoading, //loading for the first time with no data
      //sorting,
      rowSelection,
    },
    mantineToolbarAlertBannerProps: isError
      ? { color: "red", children: "Lỗi tải dữ liệu !" }
      : undefined,
    mantinePaginationProps: {
      showRowsPerPage: true,
      withEdges: true,
      rowsPerPageOptions: ["10", "50", "100"],
    },
    paginationDisplayMode: "pages",
    enableColumnPinning: true,
    mantineTableProps: {
      striped: false,
    },
    columnFilterDisplayMode: "popover",
    mantineTableBodyRowProps: ({ row }) => ({
      onClick: row.getToggleSelectedHandler(),
      sx: { cursor: "pointer" },
    }),
  });

  useEffect(() => {
    if (!dataDelete) fetchDataManuFacTurer();
  }, [activePageGroup]);

  useEffect(() => {
    if (!dataDelete) fetchDataSearchItem();
  }, [activePage]);

  return (
    <>
      {dataDelete && dataDelete?.length > 0 ? (
        <MantineReactTable table={table} />
      ) : (
        <Grid>
          <Grid.Col span={{ base: 12, md: 5, xs: 5 }}>
            <Fieldset
              legend="Tìm nhóm hàng"
              mt={10}
              h={"98%"}
              style={{ position: "relative" }}
            >
              {selectItem.length > 0 && (
                <Overlay color="#000" backgroundOpacity={0.55} />
              )}
              {renderTabelPanel(dataManuFacTurer, 6)}
            </Fieldset>
          </Grid.Col>

          <Grid.Col span={{ base: 12, md: 7, xs: 7 }}>
            <Fieldset
              legend="Tìm mã hàng"
              mt={10}
              style={{ position: "relative" }}
            >
              {selectedCatSegment?.length > 0 && (
                <Overlay color="#000" backgroundOpacity={0.5} />
              )}
              {renderTableItems()}
            </Fieldset>
          </Grid.Col>
        </Grid>
      )}

      <Flex align={"center"} justify={"end"} gap={10} mt={10}>
        <Button
          color="gray"
          onClick={() => {
            modals.closeAll();
          }}
          leftSection={<IconWindow size={18} />}
        >
          Đóng
        </Button>
        <Button
          color={sky_blue.base}
          leftSection={<IconCheck size={18} />}
          onClick={() => {
            handleConfirmAddItem();
          }}
        >
          Xác nhận
        </Button>
      </Flex>
    </>
  );
};

export default ModalSearchItems;
