import {
  Box,
  Button,
  Grid,
  Group,
  TextInput,
  Text,
  Flex,
  Tooltip,
} from "@mantine/core";
import {
  MRT_ColumnDef,
  MRT_RowSelectionState,
  MantineReactTable,
  useMantineReactTable,
} from "mantine-react-table";
import * as React from "react";
import { useEffect, useState } from "react";

import {
  _timerDelayClick,
  repositoryPos,
} from "../../../_base/_const/_constVar";
import { _localization } from "../../../config/location";

import { useDisclosure } from "@mantine/hooks";
import { modals } from "@mantine/modals";
import { IconCheck, IconSearch, IconWindow } from "@tabler/icons-react";
import { NotificationExtension } from "../../../_base/extension/NotificationExtension";
import { paginationBase } from "../../../_base/model/_base/BaseTable";
import { sky_blue } from "../../../const/variables";
import { tblItem } from "../../../model/TblItem";
import { TblWarrantyItem } from "../../../model/TblWarrantyItem";
import { TblWarrantyServiceRequestForm } from "../../../model/TblWarrantyServiceRequestForm";
import { formatDateTransfer } from "../../../common/FormatDate/FormatDate";

const WarrantyItem = ({
  custId,
  selectWarrantyItem,
  setFormValues,
  phoneNumber,
}: WarrantyItemProps) => {
  //#region  table
  const columns = React.useMemo<MRT_ColumnDef<TblWarrantyItem>[]>(
    () => [
      {
        accessorKey: "serial",
        header: "Serial",
      },
      {
        accessorKey: "itemCode",
        header: "Mã sản phẩm",
        size: 30,
      },
      {
        accessorKey: "itemName",
        header: "Tên sản phẩm",
        Cell: ({ row }) => (
          <Box w={450}>
            <Tooltip label={row.original.itemName}>
              <Text size="12.5px" fw={500} truncate="end" h={15}>
                {row.original.itemName}
              </Text>
            </Tooltip>
          </Box>
        ),
      },
      {
        accessorKey: "orderCode",
        header: "Mã hóa đơn",
        size: 30,
      },
      {
        accessorKey: "buyingDate",
        header: "Ngày mua",
        size: 30,
        Cell: ({ renderedCellValue }: any) => (
          <>{renderedCellValue && formatDateTransfer(renderedCellValue)}</>
        ),
      },
    ],
    []
  );

  //data and fetching state
  const headerRef = React.useRef<HTMLDivElement>(null);
  const [data, setData] = useState<TblWarrantyItem[]>([]);
  const [dataTemp, setDataTemp] = useState<TblWarrantyItem[]>([]);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);
  const [rowCount, setRowCount] = useState(0);
  const [height, setHeight] = useState(0);

  //table state
  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});

  const [keySearch, setKeySearch] = useState<string>();
  const [selectIds, setSelectIds] = useState<string[]>([]);
  const [pagination, setPagination] = useState(paginationBase);
  const [selectItem, setSelectItem] = useState<tblItem>();
  //count
  const [timeLeft, setTimeLeft] = useState(60);
  const [isCounting, setIsCounting] = useState(false);
  const [visible, { toggle, close, open }] = useDisclosure(false);

  //search
  const [search, setSearch] = useState("");

  const startCountdown = () => {
    if (!isCounting) {
      setTimeLeft(_timerDelayClick);
      setIsCounting(true);
    }
  };

  const handleSelectReceipt = () => {
    if (data.length === 0 || selectIds.length === 0) {
      NotificationExtension.Warn("Vui lòng chọn đơn hàng!");
      return;
    }

    const selectedItems = data.filter((e: TblWarrantyItem) =>
      selectIds.includes(e.serial?.toString() ?? "")
    );

    console.log(selectedItems);

    const uniqueCustomerIds = [
      ...new Set(selectedItems.map((e) => e.customerId)),
    ];

    if (custId && custId.toString() !== uniqueCustomerIds[0]?.toString()) {
      NotificationExtension.Warn(
        "Vui lòng chọn các đơn hàng cùng một khách hàng!"
      );
      return;
    }

    if (uniqueCustomerIds.length > 1) {
      NotificationExtension.Warn(
        "Vui lòng chọn các đơn hàng cùng một khách hàng!"
      );
      return;
    }

    if (selectedItems.length > 0) {
      selectWarrantyItem((prev: TblWarrantyItem[]) => [
        ...prev,
        ...selectedItems,
      ]);

      setFormValues((prev: TblWarrantyServiceRequestForm) => ({
        ...prev,
        custId: selectedItems[0].customerId,
        channelId: selectedItems[0].saleChanelId,
      }));

      modals.closeAll();
    } else {
      NotificationExtension.Warn(
        "Không tìm thấy đơn hàng phù hợp với ID đã chọn!"
      );
    }
  };

  const fetchData = async () => {
    setIsLoading(true);
    setIsRefetching(true);
    setIsError(false);

    let url = `?Skip=${pagination?.pageIndex * pagination?.pageSize}&Take=${
      pagination.pageSize
    }`;

    if (phoneNumber) {
      url += `&KeySearch=${phoneNumber}`;
    }
    try {
      const response = await repositoryPos.get(
        `/api/v1/TblWarrantyItem/search-order${url}`
      );
      if (response && response.success) {
        let result = response.data;
        setData(result);
        setDataTemp(result);
        setRowCount(response.length);
        setSelectIds([]);
        table.resetRowSelection();
      }
    } catch (error) {
      setData([]);
      setIsError(true);
      console.error(error);
      return;
    } finally {
      setIsLoading(false);
      setIsRefetching(false);
    }
    startCountdown();
  };

  useEffect(() => {
    let timer: ReturnType<typeof setInterval>;
    if (isCounting && timeLeft > 0) {
      timer = setInterval(() => {
        setTimeLeft((prevTime) => prevTime - 1);
      }, 1000);
    } else if (timeLeft === 0) {
      setIsCounting(false);
    }
    return () => clearInterval(timer);
  }, [isCounting, timeLeft]);

  useEffect(() => {
    const valuesList = Object.keys(rowSelection);
    setSelectIds(valuesList);
    if (valuesList.length < 1) setSelectIds([]);
    else {
      const valuesList = Object.keys(rowSelection);
      setSelectIds(valuesList);
    }
  }, [rowSelection]);

  useEffect(() => {
    fetchData();
  }, []);

  const table = useMantineReactTable({
    columns,
    data,
    positionToolbarAlertBanner: "bottom",
    //add custom action buttons to top-left of top toolbar
    renderTopToolbarCustomActions: ({ table }) => (
      <div ref={headerRef} style={{ width: "100%" }}>
        <Grid>
          <Grid.Col span={{ base: 12, md: 6, lg: 2 }}>
            <TextInput
              placeholder="Nhập từ khóa ..."
              value={keySearch}
              onChange={(e) => setKeySearch(e.currentTarget.value)}
            />
          </Grid.Col>
          <Grid.Col span={{ base: 12, md: 6, lg: 2 }}>
            <Flex w={"100%"} h={"100%"} align={"center"}>
              <Button
                leftSection={<IconSearch size={14} />}
                color="blue"
                variant="outline"
                onClick={async () => {
                  await fetchData();
                }}
              >
                Tìm kiếm
              </Button>
            </Flex>
          </Grid.Col>
        </Grid>
      </div>
    ),
    renderToolbarInternalActions: () => <></>,

    mantineTopToolbarProps: {
      style: {
        borderBottom: "3px solid rgba(128, 128, 128, 0.5)",
        marginBottom: 5,
      },
    },
    localization: _localization,
    enableRowSelection: true,
    getRowId: (row) => row.serial?.toString() ?? "",
    initialState: {
      showColumnFilters: false,
      columnVisibility: { id: false },
      density: "xs",
    },
    mantineTableContainerProps: {
      style: { maxHeight: "375px", minHeight: "375px" },
    },
    enableStickyHeader: true,
    enableMultiRowSelection: true,
    onRowSelectionChange: setRowSelection,
    rowCount,
    mantineTableBodyCellProps: ({ row }) => ({
      style: {
        //  fontWeight: row.getIsSelected() ? "bold" : "normal", //conditional styling
        fontWeight: "500", //conditional styling
        fontSize: "12.5px",
        padding: "5px 15px",
      },
    }),
    state: {
      isLoading,
      showAlertBanner: isError,
      showProgressBars: isRefetching,
      showSkeletons: isLoading,
      rowSelection,
    },
    mantineToolbarAlertBannerProps: isError
      ? { color: "red", children: "Lỗi tải dữ liệu !" }
      : undefined,
    mantinePaginationProps: {
      showRowsPerPage: true,
      withEdges: true,
      rowsPerPageOptions: ["10", "50", "100"],
    },
    paginationDisplayMode: "pages",
    enableColumnPinning: true,
    mantineTableProps: {
      striped: false,
    },
    mantineTableBodyRowProps: ({ row }) => ({
      onClick: row.getToggleSelectedHandler(),
      sx: { cursor: "pointer" },
    }),
  });
  //#endregion
  return (
    <Box>
      <MantineReactTable table={table} />
      <Group
        justify="end"
        mt="xs"
        style={{
          position: "sticky",
          bottom: 0,
          backgroundColor: "white",
        }}
      >
        <Button
          type="button"
          color="gray"
          loading={visible}
          onClick={() => {
            modals.closeAll();
          }}
          leftSection={!visible ? <IconWindow size={18} /> : undefined}
        >
          Đóng
        </Button>
        <Button
          color={sky_blue.base}
          loading={visible}
          leftSection={!visible ? <IconCheck size={18} /> : undefined}
          onClick={handleSelectReceipt}
        >
          Lưu
        </Button>
        <></>
      </Group>
    </Box>
  );
};

type WarrantyItemProps = {
  custId?: string | number;
  selectWarrantyItem: any;
  setFormValues: any;
  phoneNumber?: string;
};
export default WarrantyItem;
