import {
  IconBrandAuth0,
  IconBuildingWarehouse,
  IconFileDollar,
  IconReceipt,
  IconReport,
  IconScale,
  IconSettings,
  IconSettingsQuestion,
  IconShoppingCart,
  IconTool,
  IconTransfer,
} from "@tabler/icons-react";
import { LinksGroupProps } from "../../_base/model/_base/LinksGroupProps";

export const _sideNavData: LinksGroupProps[] = [
  //   {
  //     label: "Demo",
  //     icon: Icon2fa,
  //     initiallyOpened: false,
  //     links: [
  //       {
  //         label: "Công việc đang xử lý",
  //         link: "/system/process-task",
  //       },
  //       {
  //         label: "Quá trình duyệt",
  //         link: "/system/process-instances",
  //       },
  //       {
  //         label: "Loại tài liệu",
  //         link: "/system/document-type",
  //       },
  //       {
  //         label: "Số hồ sơ",
  //         link: "/system/file-number",
  //       },
  //     ],
  //   },
  {
    label: "Danh mục dữ liệu",
    icon: IconScale,
    initiallyOpened: false,
    links: [
      {
        label: "Dữ liệu chung",
        link: "/category-data/general-data",
      },
      {
        label: "Thanh toán",
        link: "/category-data/payment",
      },
      {
        label: "Hàng hóa",
        link: "/category-data/commodities",
      },
      {
        label: "Giao vận",
        link: "/category-data/transport",
      },
      // {
      //   label: "Danh mục đồng bộ",
      //   link: "/category-data/synchronous",
      // },
      {
        label: "Phòng ban",
        link: "/organization/department",
      },
      {
        label: "Chức vụ",
        link: "/organization/position",
      },
      {
        label: "Nhân viên",
        link: "/organization/staff",
      },
      {
        label: "Danh sách khách hàng",
        link: "/customer-management/customer-list",
      },
      {
        label: "Nhóm công nợ",
        link: "/debtgroup/debtgroup-list",
      },
      {
        label: "Danh mục Bộ Phận",
        link: "/department/department-list",
      },
    ],
  },
  {
    label: "Danh Mục Hóa Đơn",
    icon: IconFileDollar,
    initiallyOpened: false,
    links: [
      {
        label: "Hóa đơn",
        link: "/invoice/list",
      },
    ],
  },
  {
    label: "Thiết Lập Hệ thống",
    icon: IconSettings,
    initiallyOpened: false,
    links: [
      {
        label: "Thêm mới bảng giá bán",
        link: "/config-system/list-details-price",
        hidden: true,
      },
      {
        label: "Thiết lập bảng giá bán",
        link: "/config-system/config-price",
      },
      {
        label: "Thiết lập chính sách KM",
        link: "/config-system/config-promotion-policy",
      },
      {
        label: "Thiết lập định mức nguyên vật liệu",
        link: "/config-system/config-bom",
      },
      {
        label: "Tạo mới chính sách khuyến mãi",
        link: "/config-system/create-promotion-policy",
        hidden: true,
      },
      {
        label: "Chi tiết chính sách khuyến mãi",
        link: "/config-system/detail-promotion",
        hidden: true,
      },
      {
        label: "Chỉnh sửa chính sách khuyến mãi",
        link: "/config-system/edit-promotion",
        hidden: true,
      },
      {
        label: "Thiết lập chung",
        link: "/config-system/general-settings",
        hidden: true,
      },
      {
        label: "Tạo mới định mức nguyên vật liệu",
        link: "/config-system/create-config-bom",
        hidden: true,
      },
      {
        label: "Chỉnh sửa định mức nguyên vật liệu",
        link: "/config-system/edit-config-bom",
        hidden: true,
      },
      {
        label: "Chi tiết định mức nguyên vật liệu",
        link: "/config-system/detail-config-bom",
        hidden: true,
      },
    ],
  },
  {
    label: "Bán hàng",
    icon: IconShoppingCart,
    initiallyOpened: false,
    links: [
      {
        label: "Hóa đơn bán lẻ",
        link: "/sell/retail-order",
      },
      // {
      //   label: "Tạo đơn bán hàng",
      //   link: "/sell/sales-order",
      // },
      // {
      //   label: "Đơn đổi trả",
      //   link: "/sell/return-order",
      // },
      {
        label: "Danh sách đơn",
        link: "/sell/sales-order-list",
      },
      // { label: "Duyệt giảm", link: "/reduced-approval/discount-approval" },
      {
        label: "Sửa đơn hàng bán",
        link: "/sell/revise-order",
        hidden: true,
      },
      // {
      //   label: "Xuất bù khuyến mãi",
      //   link: "/sell/sales-order-promotions",
      // },
      // {
      //   label: "Phiếu thu tiền hàng",
      //   link: "/sell/payment-receipt",
      // },
      // {
      //   label: "Danh sách phiếu thu",
      //   link: "/sell/list-receipts",
      // },
      // {
      //   label: "Phiếu thu nội bộ",
      //   link: "/sell/internal-receipt",
      // },
      {
        label: "Khách hàng",
        link: "/sell/customer",
        hidden: true,
      },
      {
        label: "Thông tin thanh toán",
        link: "/sell/screen-payment",
        hidden: true,
      },
    ],
  },
  {
    label: "Kho hàng",
    icon: IconBuildingWarehouse,
    initiallyOpened: false,
    links: [
      {
        label: "Danh sách đơn mua hàng",
        link: "/warehouse-config/purchase-order",
        hidden: true,
      },
      {
        label: "Đơn mua hàng",
        link: "/warehouse-config/import-purchases",
        hidden: true,
      },
      {
        label: "Kiểm kê kho",
        link: "/warehouse-config/warehouse-management",
      },
      {
        label: "Lập yêu cầu điều chuyển kho",
        link: "/warehouse-config/request-transfer",
      },
      // {
      //   label: "Xuất kho điều chỉnh",
      //   link: "warehouse-config/export-adjustment",
      // },
      // {
      //   label: "Lập yêu cầu điều chuyển nội bộ",
      //   link: "warehouse-config/request-internal-transfer",
      // },
      // {
      //   label: "Quản lý sổ kho",
      //   link: "warehouse-config/warehouse-transaction",
      // },
      {
        label: "Danh sách đơn cần xuất",
        link: "/warehouse-config/request-inventory-transaction",
      },
      // {
      //   label: "Danh sách đơn cần nhập",
      //   link: "/warehouse-config/request-inventory-transaction?Type=1",
      // },
      {
        label: "Danh sách phiếu nhập hàng NCC",
        link: "/warehouse-config/supplier-slip",
      },
      {
        label: "Danh sách kho serial",
        link: "/warehouse-config/list-serial",
        hidden: true,
      },
      {
        label: "Danh sách phiếu nhập thành phẩm",
        link: "/warehouse-config/create-finished-product-receipt",
        hidden: true,
      },
      {
        label: "Lập phiếu xuất kho",
        link: "/warehouse-config/warehouse-out-ward",
      },

      {
        label: "Lập phiếu nhập kho",
        link: "/warehouse-config/warehouse-in-ward",
      },

      // {
      //   label: "Lập lệnh sản xuất",
      //   link: "warehouse-config/wip",
      // },
      {
        label: "Danh mục vị trí kho",
        link: "warehouse-config/warehouse-location-list",
      },
      {
        label: "Lập lệnh sản xuất",
        link: "/warehouse-config/wip",
      },
      {
        label: "Danh sách chi tiết đơn mua hàng",
        link: "/warehouse-config/purchase-order-detail",
        hidden: true,
      },
      {
        label: "Báo cáo tồn kho",
        link: "/warehouse-config/inventoryReport",
      },
      {
        label: "Báo cáo giao dịch điều chuyển kho",
        link: "/warehouse-config/inventoryTransfer",
      },
      {
        label: "Chu kỳ kiểm kê kho",
        link: "/warehouse-config/inventory-cycle-count",
        hidden: true,
      },
      {
        label: "Tạo yêu cầu điều chuyển kho",
        link: "/warehouse-config/create-request-transfer",
        hidden: true,
      },
      {
        label: "Chỉnh sửa yêu cầu điều chuyển kho",
        link: "/warehouse-config/edit-request-transfer",
        hidden: true,
      },
      {
        label: "Chi tiết yêu cầu điều chuyển kho",
        link: "/warehouse-config/detail-request-transfer",
        hidden: true,
      },
      {
        label: "Quản lý sổ kho",
        link: "/warehouse-config/warehouse-transaction",
        hidden: true,
      },
      {
        label: "Tạo phiếu xuất kho",
        link: "/warehouse-config/create-warehouse-out-ward",
        hidden: true,
      },
      {
        label: "Chỉnh sửa phiếu xuất kho",
        link: "/warehouse-config/edit-warehouse-out-ward",
        hidden: true,
      },
      {
        label: "Lập phiếu nhập kho",
        link: "/warehouse-config/create-warehouse-in-ward",
        hidden: true,
      },
      {
        label: "Chỉnh sửa phiếu nhập kho",
        link: "/warehouse-config/edit-warehouse-in-ward",
        hidden: true,
      },
      {
        label: "Chỉnh sửa phiếu nhập kho cộng thêm",
        link: "/warehouse-config/edit-warehouse-in-ward-plus",
        hidden: true,
      },
      {
        label: "Tạo phiếu nhập hàng mua",
        link: "/warehouse-config/create-warehouse-purchase-in-ward",
        hidden: true,
      },
      {
        label: "Phiếu nhập hàng nhà cung cấp",
        link: "/warehouse-config/supplier-request-form",
        hidden: true,
      },
      {
        label: "Chỉnh sửa lệnh sản xuất",
        link: "/warehouse-config/edit-wip",
        hidden: true,
      },
      {
        label: "Xuất kho hủy",
        link: "/warehouse-config/export-adjustment",
        hidden: true,
      },
      {
        label: "Danh sách phiếu nhập thành phẩm",
        link: "/warehouse-config/finished-goods-receipt-list",
      },
    ],
  },
  {
    label: "Phiếu thu / chi",
    icon: IconReceipt,
    initiallyOpened: false,
    links: [
      {
        label: "Danh sách phiếu thu",
        link: "/bill/list-receipts",
      },
      {
        label: "Phiếu điều chỉnh",
        link: "/bill/adjustment-slip",
      },
      {
        label: "Danh sách phiếu chi",
        link: "/bill/list-payment-slip",
        hidden: true,
      },
      {
        label: "Lập phiếu thu đơn hàng",
        link: "/bill/payment-receipt",
      },
      {
        label: "Lập phiếu thu khác",
        link: "/bill/internal-receipt",
      },
      {
        label: "Lập phiếu báo có",
        link: "/bill/credit-advice",
      },
      {
        label: "Lập phiếu chi",
        link: "/bill/payment-slip",
      },
      {
        label: "Xem chi tiết phiếu",
        link: "/bill/detail-payment-receipt",
        hidden: true,
      },
      {
        label: "Lập phiếu báo nợ",
        link: "/bill/debt-note",
      },

      {
        label: "Tỉ giá vàng",
        link: "/bill/exchange-rate",
        hidden: true,
      },
      {
        label: "Tạo mới phiếu điều chỉnh",
        link: "/bill/add-adjustment-slip",
        hidden: true,
      },
    ],
  },
  {
    label: "Giao vận",
    icon: IconTransfer,
    initiallyOpened: false,
    links: [
      {
        label: "Đơn giao hàng",
        link: "/delivery-config/delivery",
      },
      {
        label: "Phân đơn hàng giao vận",
        link: "/delivery-config/delivery-assignment",
      },
      {
        label: "Thực hiện vận đơn",
        link: "/delivery-config/delivery-dispatch",
      },
      {
        label: "Báo cáo tính lương cho giao vận",
        link: "/delivery-config/delivery-pay",
      },
      {
        label: "Tạo mới đơn giao hàng",
        link: "/delivery-config/delivery-create",
        hidden: true,
      },
    ],
  },

  {
    label: "Kỹ thuật kiểm tra",
    icon: IconSettingsQuestion,
    initiallyOpened: false,
    links: [
      {
        label: "Kỹ thuật kiểm tra",
        link: "/testing/testing-technique",
      },
    ],
  },

  // {
  //   label: "Duyệt giảm",
  //   icon: IconDiscount,
  //   initiallyOpened: false,
  //   links: [
  //     { label: "Duyệt giảm", link: "/reduced-approval/discount-approval" },
  //   ],
  // },

  {
    label: "Bảo hành",
    icon: IconTool,
    initiallyOpened: false,
    links: [
      {
        label: "Lập phiếu tiếp nhận yêu cầu",
        link: "/warranty/receive-warranty",
      },
      {
        label: "Xử lý yêu cầu",
        link: "/warranty/request-processing",
      },
      {
        label: "Theo dõi hãng xử lý tại nhà",
        link: "/warranty/warranty-company",
      },
      {
        label: "Thực hiện xử lý tại nhà",
        link: "/warranty/perform-home-warranty",
      },
      {
        label: "Phiếu tiếp nhận hàng lỗi",
        link: "/warranty/receive-ticket-list",
      },
      {
        label: "Phiếu nhập kho hàng lỗi",
        link: "/warranty/warranty-receive-item",
        hidden: true,
      },
      {
        label: "Xuất bảo hành NCC",
        link: "/warranty/supplier-warranty-export",
      },
      {
        label: "Thông tin phân công",
        link: "/warranty/warranty-information",
      },
      {
        label: "Danh sách gia hạn bảo hành",
        link: "/warranty/warranty-list",
      },
    ],
  },
  {
    label: "Phân quyền",
    icon: IconBrandAuth0,
    initiallyOpened: false,
    links: [
      {
        label: "Nhóm menu",
        link: "/authozire/menu-group",
      },
      {
        label: "Danh mục chức năng",
        link: "/authozire/roles",
      },
      {
        label: "Nhóm người dùng",
        link: "/authozire/groups",
      },
      {
        label: "Nhóm phòng ban và kho",
        link: "/authozire/groups-brand-inventory",
      },
      {
        label: "Tài khoản người dùng",
        link: "/authozire/users",
      },
      {
        label: "Danh mục App",
        link: "/authozire/app",
      },
      {
        label: "Lịch sử và phê duyệt",
        link: "/authozire/history-and-approve",
      },
      {
        label: "Nhật ký hệ thống",
        link: "/authozire/logging",
      },
      // {
      //   label: "Cấu hình hệ thống",
      //   link: "/authozire/topic-error",
      // },
      // {
      //   label: "Danh sách và phê duyệt đăng nhập",
      //   link: "/authozire/topic-error",
      // },
      // {
      //   label: "Danh sách online",
      //   link: "/authozire/online",
      // },
      {
        label: "Topic error",
        link: "/authozire/topic-error",
      },
    ],
  },
  // {
  //   label: "Tổ chức",
  //   icon: IconUsersGroup,
  //   initiallyOpened: false,
  //   links: [
  //     {
  //       label: "Phòng ban",
  //       link: "/organization/department",
  //     },
  //     {
  //       label: "Chức vụ",
  //       link: "/organization/position",
  //     },
  //     {
  //       label: "Nhân viên",
  //       link: "/organization/staff",
  //     },
  //   ],
  // },
  {
    label: "Báo cáo",
    icon: IconReport,
    initiallyOpened: false,
    links: [
      {
        label: "Mẫu báo cáo",
        link: "/report/example",
      },
      {
        label: "Thêm template cho mẫu báo cáo",
        link: "/report/add-or-update-file",
      },
      {
        label: "Tồn kho theo serial",
        link: "/report/inventory-serial-onhand",
      },
      {
        label: "Báo cáo sổ quỹ",
        link: "/report/payment-receipt",
      },
      {
        label: "Bảng kê bán hàng",
        link: "/report/sales-list",
      },
    ],
  },
];
